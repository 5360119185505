import { FC } from "react";
import { AppEventProvider } from "../app/providers/app-event-provider/AppEventProvider";
import { AppSettingsProvider } from "../app/providers/app-settings-provider/AppSettingsProvider";
import { SelfProvider } from "../app/providers/self-provider/SelfProvider";
import { TypesProvider } from "../app/providers/types-provider/TypesProvider";

type AuthenticatedProvidersProps = {
    children: React.ReactNode
}

export const AuthenticatedProviders: FC<AuthenticatedProvidersProps> = ({
    children,
}) => {
    return (
        <SelfProvider>
            <TypesProvider>
                <AppEventProvider>
                    <AppSettingsProvider>
                        {children}
                    </AppSettingsProvider>
                </AppEventProvider>
            </TypesProvider>
        </SelfProvider>
    )
}