import styled from "@emotion/styled";
import { Colors } from "../../../../../common/colors/colors";
import { TABLET_BREAKPOINT } from "../../../constants/breakpoints/breakpoints";

export const DialogFooter = styled.div<{
    columns?: 'one' | 'two'
}>(({
    columns = 'two',
}) => ({
    /* layout */
    display: 'grid',
    justifyContent: 'end',
    gridAutoFlow: 'column',
    gap: 8,

    gridColumnStart: columns === 'two' ? 2 : 'unset',
    gridColumnEnd: columns === 'two' ? 3 : 'unset',
    gridRowStart: 2,
    gridRowEnd: 3,

    /* padding */
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 18,
    paddingBottom: 18,

    /* border */
    borderBottomRightRadius: 16,
    borderLeft: columns === 'two' ? `1.5px solid rgb(55, 55, 55)` : 'none',
    borderTop: `0.5px solid ${Colors.border}`,

    /* shadow */
    boxShadow: 'rgb(0 0 0 / 50%) 0px 5px 20px 0px !important',

    /* background */
    backgroundColor: Colors.background,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        /* layout */
        gridColumnStart: 'unset',
        gridColumnEnd: 'unset',
        gridRowStart: 'unset',
        gridRowEnd: 'unset',

        /* border */
        borderLeft: 'none',

        /* shadow */
        boxShadow: 'rgb(0 0 0 / 50%) 0px 5px 20px 0px !important',
    }
}))