import { Query, QueryKey } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";

export const queryInvalidationHelper = (queryKeysToInvalidate: QueryKey) => ({
    predicate: (query: Query): boolean => (
        !!query.queryKey.map((key) => (
            !!queryKeysToInvalidate.find((invalidate) => (
                isEqual(key, invalidate)
            ))
        )).length
    )
})