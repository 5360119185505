import { FC, useCallback } from "react";
import { AdminToolGridItem } from "../admin-tool-grid-item/admin-tool-grid-item";
import { faChartColumn } from "@fortawesome/pro-regular-svg-icons/faChartColumn";
import { volcano } from "@ant-design/colors";
import { useRedashUrl } from "./redash-grid-item.hooks";

export const RedashGridItem: FC = () => {

    // data hooks
    const { data } = useRedashUrl()

    // constants
    const redashUrl = data?.value;

    // event handlers
    const handleGridItemClick = useCallback(() => {
        window.open(redashUrl, '_blank')
    }, [redashUrl])

    return (
        <AdminToolGridItem
            title="Open Redash"
            subtitle="Manage admin reports and dashboards"
            icon={faChartColumn}
            iconColor={volcano.primary}
            onClick={handleGridItemClick}
        />
    )
}