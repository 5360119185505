import { red } from "@ant-design/colors";
import styled from "@emotion/styled";

export const FormItemContainer = styled.div<{
    hasFooter: boolean
    disabled?: boolean
}>(({
    hasFooter,
    disabled,
}) => ({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingBottom: hasFooter ? 0 : 8,
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : undefined,
}))

export const Label = styled.span({
    fontSize: 13,
    paddingLeft: 6,
})

export const FooterTextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    marginBottom: 6,
})

export const HelpText = styled.span({
    fontStyle: 'italic',
    fontSize: 12,
    paddingLeft: 6,
    opacity: 0.5,
})

export const ErrorText = styled.span({
    fontStyle: 'italic',
    fontSize: 12,
    paddingLeft: 6,
    color: red.primary,
})