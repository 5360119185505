import styled from "@emotion/styled";

export const AppMenuContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridAutoColumns: 'max-content',
    alignItems: 'center',
    gap: 6,

    // padding
    padding: 16,

    // text
    fontSize: 18,
    fontWeight: 500,
    whiteSpace: 'nowrap',

    // cursor
    cursor: 'pointer',
    userSelect: 'none',

    // animation
    transition: 'opacity 200ms ease',

    // active
    '&:active': {
        opacity: 0.7,
    }
})

export const ConstellationLogoIcon = styled.img({
    // size
    width: 32,
    height: 32,
    
    // image
    objectFit: 'contain',
    objectPosition: 'center',
})