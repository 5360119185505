import { TabBarLink } from "../../tab-bar.types";
import { useUnsafeRouterContext } from "../../../../../common/hooks/use-unsafe-router-context/use-unsafe-router-context";
import { routerIsLinkActive } from "../../../../../common/utils/router-is-link-active/router-is-link-active";

export const useCurrentLinkIndex = (filteredLinks: TabBarLink[]): number | undefined => {
    
    // router hooks
    const unsafeRouterContext = useUnsafeRouterContext()

    // return the found index
    const index = filteredLinks
        .findIndex(link => routerIsLinkActive(
            link.to,
            unsafeRouterContext,
        ))
    
    return index < 0 ? undefined : index;
}