/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { ChangePrimaryEmailForm_UserFragmentDoc } from '../../../../../users/change-primary-email-form/change-primary-email-form.generated';
export type UserAccountView_UserFragment = { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null };

export const UserAccountView_UserFragmentDoc = `
    fragment UserAccountView_User on Users {
  id
  firstName
  lastName
  emailAddress
  ...ChangePrimaryEmailForm_User
}
    ${ChangePrimaryEmailForm_UserFragmentDoc}`;