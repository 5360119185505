import Spin from 'antd/es/spin'
import React, { FC } from 'react'
import { ClassNames } from '@emotion/react'
import { DialogBodyContainer, DialogBodyInner, OneColumn, SpinWrapperStyles } from './dialog-body.styled'

type DialogBodyProps = {
	loading?: boolean
	children: React.ReactNode
	columns?: 'one' | 'two'
}

export const DialogBody: FC<DialogBodyProps> = ({
	children,
	loading,
	columns = 'two',
}) => {
	return (
		<ClassNames>
			{({ css }) => (
				<Spin wrapperClassName={`${css(SpinWrapperStyles)} ${columns === 'one' && css(OneColumn)}`} spinning={!!loading}>
					<DialogBodyContainer>
						<DialogBodyInner>
							{children}
						</DialogBodyInner>
					</DialogBodyContainer>
				</Spin>
			)}
		</ClassNames>
	)
}