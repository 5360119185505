/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { UserProfileView_UserFragmentDoc } from './subviews/user-profile-view/UserProfileView.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type UserProfilePreferencesView_UserQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UserProfilePreferencesView_UserQuery = { userProfilePreferencesView_user_usersCollection: { edges: Array<{ node: { id: any, firstName: string | null, lastName: string | null } }> } | null };


export const UserProfilePreferencesView_UserDocument = `
    query UserProfilePreferencesView_User($userId: UUID!) {
  userProfilePreferencesView_user_usersCollection: usersCollection(
    filter: {id: {eq: $userId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...UserProfileView_User
      }
    }
  }
}
    ${UserProfileView_UserFragmentDoc}`;
export const useUserProfilePreferencesView_UserQuery = <
      TData = UserProfilePreferencesView_UserQuery,
      TError = unknown
    >(
      variables: UserProfilePreferencesView_UserQueryVariables,
      options?: UseQueryOptions<UserProfilePreferencesView_UserQuery, TError, TData>
    ) =>
    useQuery<UserProfilePreferencesView_UserQuery, TError, TData>(
      ['UserProfilePreferencesView_User', variables],
      fetcher<UserProfilePreferencesView_UserQuery, UserProfilePreferencesView_UserQueryVariables>(UserProfilePreferencesView_UserDocument, variables),
      options
    );

useUserProfilePreferencesView_UserQuery.getKey = (variables: UserProfilePreferencesView_UserQueryVariables) => ['UserProfilePreferencesView_User', variables];
;

export const useInfiniteUserProfilePreferencesView_UserQuery = <
      TData = UserProfilePreferencesView_UserQuery,
      TError = unknown
    >(
      pageParamKey: keyof UserProfilePreferencesView_UserQueryVariables,
      variables: UserProfilePreferencesView_UserQueryVariables,
      options?: UseInfiniteQueryOptions<UserProfilePreferencesView_UserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<UserProfilePreferencesView_UserQuery, TError, TData>(
      ['UserProfilePreferencesView_User.infinite', variables],
      (metaData) => fetcher<UserProfilePreferencesView_UserQuery, UserProfilePreferencesView_UserQueryVariables>(UserProfilePreferencesView_UserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteUserProfilePreferencesView_UserQuery.getKey = (variables: UserProfilePreferencesView_UserQueryVariables) => ['UserProfilePreferencesView_User.infinite', variables];
;

useUserProfilePreferencesView_UserQuery.fetcher = (variables: UserProfilePreferencesView_UserQueryVariables, options?: RequestInit['headers']) => fetcher<UserProfilePreferencesView_UserQuery, UserProfilePreferencesView_UserQueryVariables>(UserProfilePreferencesView_UserDocument, variables, options);