import { FC, ReactNode } from "react";
import { OrganizationProvider } from "../../../organizations/common/organization-provider/organization-provider";

type OrganizationPreferencesViewProps = {
    organizationId: string
    children: ReactNode
}

export const OrganizationPreferencesView: FC<OrganizationPreferencesViewProps> = ({
    organizationId,
    children,
}) => {
    return (
        <OrganizationProvider organizationId={organizationId}>
            {children}
        </OrganizationProvider>
    )
}