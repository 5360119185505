import styled from "@emotion/styled";

export const AdminToolsGridContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gridAutoRows: 'max-content',
    gap: 12,

    /* size */
    height: '100%',
})