/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

export type CreateConstellationMenuItem_GroupFragment = { id: any, organizationId: any | null };

export const CreateConstellationMenuItem_GroupFragmentDoc = `
    fragment CreateConstellationMenuItem_Group on Groups {
  id
  organizationId
}
    `;