import { faCircleUp } from "@fortawesome/pro-regular-svg-icons/faCircleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'antd/es/button';
import { FC, useCallback, useContext } from "react";
import { OrganizationContext } from "../../../../../../../../../organizations/common/organization-provider/organization-provider";
import { BillingPortalSessionFlowType } from "constellation-sdk";

export const UpgradeToPremiumButton: FC = () => {

    // context
    const { activeEntitlementCodes, openCustomerBillingPortal } = useContext(OrganizationContext)

    // constants
    const showButton = activeEntitlementCodes && !activeEntitlementCodes.includes('qr_codes.manage');

    // event handlers
    const handleButtonClick = useCallback(() => {
        openCustomerBillingPortal(BillingPortalSessionFlowType.subscription_update)
    }, [openCustomerBillingPortal])

    return (
        <>
            {showButton && (
                <Button
                    type="primary"
                    icon={<FontAwesomeIcon icon={faCircleUp} />}
                    onClick={handleButtonClick}
                >
                    Upgrade
                </Button>
            )}
        </>
    )
}