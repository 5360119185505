/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type RootRedirect_OrganizationQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type RootRedirect_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { id: any } }> } | null };


export const RootRedirect_OrganizationDocument = `
    query RootRedirect_Organization($userId: UUID!) {
  organizationsCollection(
    filter: {ownerUserId: {eq: $userId}, isDeleted: {eq: false}}
    orderBy: {createdAt: AscNullsLast}
    first: 1
  ) {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const useRootRedirect_OrganizationQuery = <
      TData = RootRedirect_OrganizationQuery,
      TError = unknown
    >(
      variables: RootRedirect_OrganizationQueryVariables,
      options?: UseQueryOptions<RootRedirect_OrganizationQuery, TError, TData>
    ) =>
    useQuery<RootRedirect_OrganizationQuery, TError, TData>(
      ['RootRedirect_Organization', variables],
      fetcher<RootRedirect_OrganizationQuery, RootRedirect_OrganizationQueryVariables>(RootRedirect_OrganizationDocument, variables),
      options
    );

useRootRedirect_OrganizationQuery.getKey = (variables: RootRedirect_OrganizationQueryVariables) => ['RootRedirect_Organization', variables];
;

export const useInfiniteRootRedirect_OrganizationQuery = <
      TData = RootRedirect_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof RootRedirect_OrganizationQueryVariables,
      variables: RootRedirect_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<RootRedirect_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<RootRedirect_OrganizationQuery, TError, TData>(
      ['RootRedirect_Organization.infinite', variables],
      (metaData) => fetcher<RootRedirect_OrganizationQuery, RootRedirect_OrganizationQueryVariables>(RootRedirect_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteRootRedirect_OrganizationQuery.getKey = (variables: RootRedirect_OrganizationQueryVariables) => ['RootRedirect_Organization.infinite', variables];
;

useRootRedirect_OrganizationQuery.fetcher = (variables: RootRedirect_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<RootRedirect_OrganizationQuery, RootRedirect_OrganizationQueryVariables>(RootRedirect_OrganizationDocument, variables, options);