/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { AddUserToOrganizationDialog_OrganizationFragmentDoc } from '../add-user-to-organization-dialog/add-user-to-organization-dialog.generated';
export type AddOrganizationMemberButton_OrganizationFragment = { id: any, name: string, addUserToOrganizationDialog_organization_organizationMembersCollection: { edges: Array<{ node: { user: { emailAddress: string | null } | null } }> } | null, addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection: { edges: Array<{ node: { invitedUserEmailAddress: string | null } }> } | null, AddUserToOrganizationDialog_Organization_UserGroupsCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };

export const AddOrganizationMemberButton_OrganizationFragmentDoc = `
    fragment AddOrganizationMemberButton_Organization on Organizations {
  id
  ...AddUserToOrganizationDialog_Organization
}
    ${AddUserToOrganizationDialog_OrganizationFragmentDoc}`;