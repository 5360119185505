import styled from "@emotion/styled";
import { TABLET_BREAKPOINT } from "../../../../common/constants/breakpoints/breakpoints";

export const BillboardContainer = styled.div({
    // layout
    display: 'grid',
    justifyItems: 'center',
    alignContent: 'center',

    // padding
    padding: 32,

    // background
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    // breakpoints
    [TABLET_BREAKPOINT]: {
        display: 'none',
    }
})

export const BillboardContentContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    alignItems: 'center',

    // size
    width: '100%',
    maxWidth: 500,
})

export const IconImage = styled.img({
    // size
    width: 88,
    height: 88,

    // image properties
    objectFit: 'contain',
    objectPosition: 'center',

    // opacity
    opacity: 0.87,
})

export const BillboardText = styled.span({
    // text properties
    fontSize: 28,
    color: 'white',
    opacity: 0.87,
    textAlign: 'center',

    lineHeight: '2.5rem',
})