"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulesModule = void 0;
const common_1 = require("../../../common");
class SchedulesModule extends common_1.APIModule {
    constructor(client) {
        super(client, '/schedules');
    }
    createSchedule(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([]);
            const response = yield this.client.post(endpoint, payload);
            return response.data;
        });
    }
    updateSchedule(scheduleId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([scheduleId]);
            const response = yield this.client.put(endpoint, payload);
            return response.data;
        });
    }
    deleteSchedule(scheduleId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([scheduleId]);
            const response = yield this.client.delete(endpoint);
            return response.data;
        });
    }
    enableSchedule(scheduleId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([scheduleId, 'enable']);
            const response = yield this.client.post(endpoint, {});
            return response.data;
        });
    }
    disableSchedule(scheduleId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([scheduleId, 'disable']);
            const response = yield this.client.post(endpoint, {});
            return response.data;
        });
    }
}
exports.SchedulesModule = SchedulesModule;
