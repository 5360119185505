import React, { FC, ReactElement } from 'react'
import { createUseStyles } from 'react-jss'
import { Padding, Gap } from '../../../../common/constants/constants'
import { MOBILE_BREAKPOINT } from '../../../common/constants/breakpoints/breakpoints'
import { Colors } from '../../../../common/colors/colors'

type PreferencesGeneralLayoutProps = {
	title: string
	subtitle: string
	icon: ReactElement,
	children: React.ReactNode
}

export const PreferencesGeneralLayout: FC<PreferencesGeneralLayoutProps> = ({
	title,
	subtitle,
	icon,
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.preferencesGeneralLayout}>
			<div className={styles.column}>
				<div className={styles.header}>
					<div className={styles.headerIcon}>
						{icon}
					</div>
					<h1>{title}</h1>
					<span>{subtitle}</span>
				</div>
				<div className={styles.panelContent}>
					{children}
				</div>
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	preferencesGeneralLayout: {
		display: 'grid',
		padding: Padding.most,
	},
	column: {
		width: 'auto',
		display: 'grid',
		gap: Gap.most,
	},
	header: {
		borderBottom: `1px solid ${Colors.border}`,
		paddingTop: Padding.default * 2,
		paddingBottom: Padding.most * 2,
		display: 'grid',
		justifyItems: 'center',
		gap: Gap.default,
	},
	headerIcon: {
		display: 'grid',
		marginBottom: Padding.default,
	},
	panelContent: {
		padding: Padding.most,
		display: 'grid',
		justifyContent: 'center',
		gridTemplateColumns: '650px',
	},
	[MOBILE_BREAKPOINT]: {
		column: {
			width: 'auto',
		},
		panelContent: {
			gridTemplateColumns: 'auto',
		},
	},
})