/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type GroupUtils_GroupFragment = { id: any, parentGroupId: any | null, organizationId: any | null };

export const GroupUtils_GroupFragmentDoc = `
    fragment GroupUtils_Group on Groups {
  id
  parentGroupId
  organizationId
}
    `;