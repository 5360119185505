import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Dialog } from "../../common/dialog/dialog";
import { DialogBody } from "../../common/dialog/subcomponents/dialog-body/dialog-body";
import { DialogFooter } from "../../common/dialog/subcomponents/dialog-footer/dialog-footer";
import { DialogTitle } from "../../common/dialog/subcomponents/dialog-title/dialog-title";
import { FormItemsContainer } from "../../common/forms/form-items-container/form-items-container";
import { FormItem } from "../../common/forms/form-item/form-item";
import { Controller, useForm } from "react-hook-form";
import Alert from "antd/es/alert";
import Button from "antd/es/button";
import Select from "antd/es/select";
import message from "antd/es/message";
import { useInfiniteMergeAccountsDialog_UsersQuery } from "./merge-accounts-dialog.generated";
import { matchSorter } from "match-sorter";
import { useFetchAllPages } from "../../app/providers/types-provider/TypesProvider.hooks";
import { SelfContext } from "../../app/providers/self-provider/SelfProvider";
import { service } from "./merge-accounts-dialog.service";

type MergeAccountsDialogProps = {
    isOpen: boolean
    onDismiss?: () => void
}

export const MergeAccountsDialog: FC<MergeAccountsDialogProps> = ({
    isOpen,
    onDismiss,
}) => {

    // context
    const { selfUser } = useContext(SelfContext)

    // state
    const [isMergingAccounts, setIsMergingAccounts] = useState<boolean>(false)

    // form state
    const { control, watch, reset, getValues, formState: { isValid: formValid }, handleSubmit } = useForm({
        defaultValues: {
            primaryAccountId: null as string | null,
            mergeAccountId: null as string | null,
        }
    })

    // data hooks
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteMergeAccountsDialog_UsersQuery('cursor', {
        cursor: null,
    }, {
        getNextPageParam: (lastPage) => {
			if (!lastPage.usersCollection?.pageInfo.hasNextPage) return undefined;
			return {
				cursor: lastPage.usersCollection.pageInfo.endCursor,
			}
		}
    })

	useFetchAllPages({
		fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
	})

    // constants
	const users = useMemo(() => {
        const allUsers = data?.pages
			.flatMap(page => page.usersCollection?.edges)
			.map(edge => edge as NonNullable<typeof edge>)
			.map(edge => edge?.node)

        if (!allUsers) return;

        // TODO: figure out why there are duplicates
        const deduplicatedUsers: typeof allUsers = []

        for (const user of allUsers) {
            if (!deduplicatedUsers.find(findUser => findUser.id === user.id)) {
                deduplicatedUsers.push(user)
            }
        }

        return deduplicatedUsers
    }, [data?.pages])

    const primaryAccountId = watch('primaryAccountId')
    const mergeAccountId = watch('mergeAccountId')

    const primaryAccount = users?.find(user => user.id === primaryAccountId)
    const mergeAccount = users?.find(user => user.id === mergeAccountId)

    const mergeAccountErrorText = useMemo(() => (
        selfUser?.id === mergeAccountId ? "You can't merge this account because you're signed into it.\n\nTo merge, sign in to the primary account first."
        : primaryAccountId && primaryAccountId === mergeAccountId ? "You can't merge an account with itself."
        : undefined
    ), [mergeAccountId, primaryAccountId, selfUser?.id])

    // event handlers
    const handleClose = useCallback(() => {
        reset()
        setIsMergingAccounts(false)
        onDismiss && onDismiss()
    }, [onDismiss, reset])

    const handleMergeAccounts = useCallback(async () => {
        // start the loading state
        setIsMergingAccounts(true)

        // extract form values
        const { primaryAccountId, mergeAccountId } = getValues()

        // attempt to merge the accounts
        const result = await service.mergeAccounts({
            primaryUserId: primaryAccountId!,
            mergeUserId: mergeAccountId!,
        })
        
        // handle any errors that occurred
        if (!result.success) {
            message.error(`Something went wrong: ${result.errorMessage ?? 'An unknown error occurred.'}`)
            setIsMergingAccounts(false)
            return;
        }

        setIsMergingAccounts(false)
        handleClose()
    }, [getValues, handleClose])

    return (
        <Dialog columns="one" open={isOpen} onClose={handleClose}>
            <DialogBody columns="one" loading={isMergingAccounts}>
                <DialogTitle>
                    Merge Two Accounts into One
                </DialogTitle>
                <form onSubmit={handleSubmit(handleMergeAccounts)}>
                    <FormItemsContainer>
                        <FormItem label="Primary Account" helpText="This is the account we will keep and merge all of the data into">
                            <Controller
                                control={control}
                                name="primaryAccountId"
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        placeholder="Choose a primary account..."
                                        optionFilterProp='children'
                                        showSearch
                                        filterOption={(input, option) => {
                                            return matchSorter(users ?? [], input, { keys: ['firstName', 'lastName', 'emailAddress'] }).map(user => user.id)?.includes(option.value) ?? true
                                        }}
                                    >
                                        {!hasNextPage && users?.map(user => (
                                            <Select.Option
                                                key={user.id}
                                                value={user.id}
                                            >
                                                {user.firstName} {user.lastName} ({user.emailAddress})
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormItem>
                        <FormItem
                            label="Remove and Merge Account"
                            helpText="This is the account we'll remove and merge the data into the primary account"
                            errorText={mergeAccountErrorText}
                        >
                            <Controller
                                control={control}
                                name="mergeAccountId"
                                rules={{ required: true, validate: () => !mergeAccountErrorText }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        placeholder="Choose a merge account..."
                                        optionFilterProp='children'
                                        showSearch
                                        status={mergeAccountErrorText ? 'error' : undefined}
                                        filterOption={(input, option) => {
                                            return matchSorter(users ?? [], input, { keys: ['firstName', 'lastName', 'emailAddress'] }).map(user => user.id)?.includes(option.value) ?? true
                                        }}
                                    >
                                        {!hasNextPage && users?.map(user => (
                                            <Select.Option
                                                key={user.id}
                                                value={user.id}
                                            >
                                                {user.firstName} {user.lastName} ({user.emailAddress})
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormItem>
                        {!mergeAccountErrorText && primaryAccount && mergeAccount && (
                            <Alert
                                message="Please Proceed with Caution"
                                description={<>This action is not reversible<br /><br />If you continue, we will delete the account:<br /><b>{mergeAccount.firstName} {mergeAccount.lastName} ({mergeAccount.emailAddress})</b><br /><br />The data will be merged into this account:<br /><b>{primaryAccount.firstName} {primaryAccount.lastName} ({primaryAccount.emailAddress})</b></>}
                                type="error"
                            />
                        )}
                    </FormItemsContainer>
                </form>
            </DialogBody>
            <DialogFooter columns="one">
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    disabled={!formValid}
                    onClick={handleMergeAccounts}
                    loading={isMergingAccounts}
                >
                    Merge Accounts
                </Button>
            </DialogFooter>
        </Dialog>
    )
}