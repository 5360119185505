import React, { FC, useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Measure, { ContentRect } from 'react-measure'
import { Colors } from '../../../../../common/colors/colors'
import { Gap, Padding } from '../../../../../common/constants/constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormFieldAccordionPanelConfig } from '../../FormFieldsAccordion'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'

type FormFieldsAccordionPanelProps = {
	fieldPanelConfig: FormFieldAccordionPanelConfig<any>
}

export const FormFieldsAccordionPanel: FC<FormFieldsAccordionPanelProps> = ({
	fieldPanelConfig,
}) => {

	// hooks
	const styles = useStyles()
	const [panelCollapsed, setPanelCollapsed] = useState<boolean>(true)
	const [contentIntrinsicHeight, setContentIntrinsicHeight] = useState<number>()
	const Component = fieldPanelConfig.component
	const showPanel = fieldPanelConfig.disabled ? false : !panelCollapsed
	const panelContentsHeight = !showPanel ? 0 : contentIntrinsicHeight

	// event handlers
	const handlePanelHeaderClick = useCallback(() => {
		setPanelCollapsed(oldPanelCollapsed => !oldPanelCollapsed)
	}, [])

	const handleContentsInnerResize = useCallback((contentRect: ContentRect) => {
		setContentIntrinsicHeight(contentRect.bounds?.height)
	}, [])

	return (
		<div className={styles.accordionPanel}>
			<div className={`${styles.panelHeader} ${fieldPanelConfig.disabled && styles.disabled}`} onClick={handlePanelHeaderClick}>
				<div className={styles.panelIcon}>
					{fieldPanelConfig.icon}
				</div>
				<div>
					<strong>{fieldPanelConfig.label}</strong>
				</div>
				<div>
					<span className={styles.description}>{fieldPanelConfig.description}</span>
				</div>
				<div>
					<div className={styles.collapsedIcon} style={{ transform: `rotate(${showPanel ? 90 : 0}deg)` }}><FontAwesomeIcon icon={faChevronRight} /></div>
				</div>
			</div>
			<div className={styles.panelContents} style={{ height: panelContentsHeight }}>
				<Measure bounds onResize={handleContentsInnerResize}>
					{({ measureRef }) => (
						<div ref={measureRef} className={styles.panelContentsInner}>
							{/* @ts-ignore */}
							<Component {...fieldPanelConfig.props} />
						</div>
					)}
				</Measure>
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	accordionPanel: {
		display: 'grid',
		borderBottom: `0.5px solid ${Colors.cardBorder}`,
		// borderBottom: `1px solid ${Colors.border}`,
		'&:last-child': {
			borderBottom: 'none',
		}
	},
	panelHeader: {
		padding: Padding.most,
		cursor: 'pointer',
		transition: 'background-color 100ms ease',
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: 'minmax(25px, max-content) 25% 1fr max-content',
		paddingLeft: Padding.most * 1.5,
		paddingRight: Padding.most * 1.5,
		gap: Gap.most,
		userSelect: 'none',
		'&:hover': {
			backgroundColor: Colors.cardBackgroundHover,
		},
		'&:active': {
			backgroundColor: Colors.cardBackgroundActive,
		}
	},
	panelContents: {
		position: 'relative',
		overflow: 'hidden',
		transition: 'height 200ms ease',
	},
	panelContentsInner: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		padding: Padding.most,
		paddingLeft: Padding.most * 2,
		paddingRight: Padding.most * 2,
		display: 'grid',
	},
	collapsedIcon: {
		fontSize: 16,
		transition: 'transform 200ms ease',
	},
	panelIcon: {
		fontSize: 18,
	},
	description: {
		// fontSize: FontSize.default,
	},
	disabled: {
		opacity: 0.5,
		cursor: 'not-allowed',
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:active': {
			backgroundColor: 'transparent',
		}
	},
})