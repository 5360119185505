import styled from "@emotion/styled";
import { TABLET_BREAKPOINT } from "../../common/constants/breakpoints/breakpoints";

export const AuthenticateShellContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '50vw 50vw',

    // size
    width: '100vw',
    height: '100vh',

    // breakpoints
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '100vw',
    },
})

export const ContentContainer = styled.div({
    // position
    position: 'relative',

    // layout
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',

    // padding
    padding: 32,
})