import { FC } from "react";
import { Breadcrumb } from "../../../breadcrumb/breadcrumb";
import { RouterMatch } from "../../../route-name/route-name.types";

type MatchRendererProps = {
	match: RouterMatch
	isLast: boolean
}

export const MatchRenderer: FC<MatchRendererProps> = ({
	match,
	isLast,
}) => {

	if (!match.handle?.RouteNameComponent) return null;
	const { RouteNameComponent } = match.handle;

	return (
		<>
			<Breadcrumb data={match}>
				<RouteNameComponent data={match as RouterMatch} />
			</Breadcrumb>
			{!isLast && (
				<span style={{ cursor: 'default', userSelect: 'none', lineHeight: '1rem', }}>/</span>
			)}
		</>
	)
}