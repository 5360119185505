import Dropdown, { type DropDownProps } from 'antd/es/dropdown'
import React, { FC, useCallback, useContext, useState } from 'react'
import { SelfContext } from '../../app/providers/self-provider/SelfProvider'
import { OverflowMenu } from '../../common/overflow-menu/OverflowMenu'
import { RemoveMemberMenuItem } from './contents/remove-member-menu-item/remove-member-menu-item'
import { OrganizationMemberContextMenu_OrganizationMemberFragment } from './OrganizationMemberContextMenu.generated'

type OrganizationMemberContextMenuProps = {
	organizationMember: OrganizationMemberContextMenu_OrganizationMemberFragment
} & Partial<DropDownProps>

export const OrganizationMemberContextMenu: FC<OrganizationMemberContextMenuProps> = ({
	organizationMember,
	children,
	...rest
}) => {

	// context
	const { selfUser } = useContext(SelfContext)

	// state
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

	// constants
	const isSelf = selfUser?.id === organizationMember.userId
	const showRemove = !isSelf
	const itemsShowing = [showRemove].includes(true)

	// event handlers
	const handleMenuClose = useCallback(() => {
		setMenuIsOpen(false)
	}, [])

	// TODO: I don't like this "itemsShowing" pattern. Let's replace it.
	if (!itemsShowing) return null

	const menu = (
		<OverflowMenu>
			{showRemove && (
				<RemoveMemberMenuItem
					organizationMemberId={organizationMember.id}
					onClose={handleMenuClose}
				/>
			)}
		</OverflowMenu>
	)

	return (
		<Dropdown
			{...rest}
			overlay={menu}
			trigger={itemsShowing ? ['click'] : []}
			open={menuIsOpen}
			onOpenChange={setMenuIsOpen}
		>
			{children}
		</Dropdown>
	)
}