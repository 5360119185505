import React, { FC, useMemo } from 'react'
import { TabBar } from '../../../common/tab-bar/tab-bar'
import { Content } from 'antd/lib/layout/layout'
import { StyledUsersIcon } from '../../../common/icons/styled-icons/styled-users-icon/StyledUsersIcon'
import { ViewBar } from '../../../common/view-bar/view-bar'
import { ViewContainer } from '../../../common/view-container/ViewContainer'

type OrganizationMembersAndTeamsViewProps = {
	children: React.ReactNode
}

export const OrganizationMembersAndTeamsView: FC<OrganizationMembersAndTeamsViewProps> = ({
	children,
}) => {

	// constants
	const tabBar = useMemo(() => ((
		<TabBar links={[
			{ label: 'Users', to: `users` },
			// { label: 'Teams', to: `teams` },
		]} />
	)), [])

	return (
		<ViewContainer>
			<ViewBar
				icon={<StyledUsersIcon />}
				viewName="Users & Teams"
				tabBar={tabBar}
			/>
			<Content>
				{children}
			</Content>
		</ViewContainer>
	)
}