import { FC } from "react";
import { LogoContainer, LogoImage } from "./logo.styled";

export const Logo: FC = () => {
    return (
        <LogoContainer>
            <LogoImage
                src="/images/logo/cqr23_logos_Horizontal Dark BG.svg"
            />
        </LogoContainer>
    )
}