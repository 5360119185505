/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { GroupMemberGridItem_GroupMemberFragmentDoc } from '../../../group-members/group-member-grid-item/GroupMemberGridItem.generated';
import { GroupUserView_GroupMemberFragmentDoc } from './subcomponents/group-user-view/GroupUserView.generated';
import { AddGroupMemberModal_GroupFragmentDoc } from '../add-group-member-modal/add-group-member-modal.generated';
export type EditGroupAccessDialog_GroupFragment = { id: any, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const EditGroupAccessDialog_GroupFragmentDoc = `
    fragment EditGroupAccessDialog_Group on Groups {
  id
  editGroupAccessDialog_group_groupMembersCollection: groupMembersCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        user {
          id
        }
        ...GroupMemberGridItem_GroupMember
        ...GroupUserView_GroupMember
      }
    }
  }
  ...AddGroupMemberModal_Group
}
    ${GroupMemberGridItem_GroupMemberFragmentDoc}
${GroupUserView_GroupMemberFragmentDoc}
${AddGroupMemberModal_GroupFragmentDoc}`;