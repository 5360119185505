import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputIconContainer } from "./input-icon.styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type InputIconProps = {
    icon: IconProp
}

export const InputIcon: FC<InputIconProps> = ({
    icon,
}) => {
    return (
        <InputIconContainer>
            <FontAwesomeIcon icon={icon} />
        </InputIconContainer>
    )
}