import { FC, useCallback, useState } from "react";
import { useValidateResetPasswordToken } from "./reset-password.hooks";
import Button from "antd/es/button";
import { Link, useNavigate } from "react-router-dom";
import { AuthenticationFormHint } from "../common/authentication-form-hint/authentication-form-hint";
import { AuthenticationFormFooter } from "../common/authentication-form-footer/authentication-form-footer";
import { FormItemsContainer } from "../../common/forms/form-items-container/form-items-container";
import { AuthenticationForm } from "../common/authentication-form/authentication-form";
import { AuthenticationFormHeader } from "../common/authentication-form-header/authentication-form-header";
import { Loading } from "../../common/loading/Loading";
import { FormItem } from "../../common/forms/form-item/form-item";
import { Input } from "../../common/forms/input/input";
import { useForm } from "react-hook-form";
import { service } from "./reset-password.service";
import message from "antd/es/message";

type ResetPasswordProps = {
    token: string
}

export const ResetPassword: FC<ResetPasswordProps> = ({
    token,
}) => {

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { getValues, handleSubmit, register, formState } = useForm({
        defaultValues: {
            newPassword: '',
        }
    })

    // data hooks
    const { data: validationResult } = useValidateResetPasswordToken(token)

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        const { newPassword } = getValues()

        const result = await service.resetPassword({
            token,
            newPassword,
        })

        if (!result.success) {
            message.error(result.authError?.message ?? result.errorMessage ?? `Something went wrong. Please try again.`)
            setIsLoading(false)
            return;
        }

        if (!!result.success) {
            message.success("Successfully reset password. Sign in to continue")
            navigate('/sign-in')
        }

        setIsLoading(false)
    }, [getValues, navigate, token])

    if (!validationResult) {
        return (
            <Loading />
        )
    }

    if (validationResult?.errorCode === 'invalid-code' || validationResult.errorCode === 'unknown') {
        return (
            <AuthenticationForm>
                <AuthenticationFormHeader
                    title="Invalid Reset Link"
                    subtitle="It looks like that link isn't valid."
                />
                <FormItemsContainer>
                    <AuthenticationFormFooter>
                        <Button onClick={() => navigate('/sign-in')}>
                            Return to Sign In
                        </Button>
                        <AuthenticationFormHint>
                            Still need to reset your password? <Link to="/forgot-password">Request another link here</Link>
                        </AuthenticationFormHint>
                    </AuthenticationFormFooter>
                </FormItemsContainer>
            </AuthenticationForm>
        )
    }

    if (validationResult.errorCode === 'already-used') {
        return (
            <AuthenticationForm>
                <AuthenticationFormHeader
                    title="Password Already Reset"
                    subtitle="It looks like that link was already used."
                />
                <FormItemsContainer>
                    <AuthenticationFormFooter>
                        <Button onClick={() => navigate('/sign-in')}>
                            Return to Sign In
                        </Button>
                        <AuthenticationFormHint>
                            Still need to reset your password? <Link to="/forgot-password">Request another link here</Link>
                        </AuthenticationFormHint>
                    </AuthenticationFormFooter>
                </FormItemsContainer>
            </AuthenticationForm>
        )
    }

    if (validationResult.errorCode === 'token-expired') {
        return (
            <AuthenticationForm>
                <AuthenticationFormHeader
                    title="Link Expired"
                    subtitle="It looks like that link expired."
                />
                <FormItemsContainer>
                    <AuthenticationFormFooter>
                        <Button onClick={() => navigate('/sign-in')}>
                            Return to Sign In
                        </Button>
                        <AuthenticationFormHint>
                            Still need to reset your password? <Link to="/forgot-password">Request another link here</Link>
                        </AuthenticationFormHint>
                    </AuthenticationFormFooter>
                </FormItemsContainer>
            </AuthenticationForm>
        )
    }

    return (
        <AuthenticationForm onSubmit={handleSubmit(handleFormSubmit)}>
            <AuthenticationFormHeader
                title="Reset Password"
                subtitle="Let's get you back into your account."
            />
            <FormItemsContainer>
                <FormItem label="Email Address">
                    <Input
                        placeholder="Email Address"
                        disabled
                        value={validationResult.emailAddress}
                    />
                </FormItem>
                <FormItem label="New Password">
                    <Input
                        {...register('newPassword', { required: true })}
                        placeholder="New Password"
                        type="password"
                        autoComplete="current-password"
                        disabled={isLoading}
                    />
                </FormItem>
                <AuthenticationFormFooter>
                <Button
                        type="primary"
                        disabled={!formState.isValid}
                        htmlType="submit"
                        loading={isLoading}
                        autoFocus
                    >
                        Reset Password
                    </Button>
                    <AuthenticationFormHint>
                        Want to try to sign in again? <Link to="/sign-in">Click here to sign in</Link>
                    </AuthenticationFormHint>
                </AuthenticationFormFooter>
            </FormItemsContainer>
        </AuthenticationForm>
    )
}