import { FC, useCallback, useState } from "react";
import { AuthenticationForm } from "../common/authentication-form/authentication-form";
import { AuthenticationFormHeader } from "../common/authentication-form-header/authentication-form-header";
import { FormItemsContainer } from "../../common/forms/form-items-container/form-items-container";
import { AuthenticationFormFooter } from "../common/authentication-form-footer/authentication-form-footer";
import Button from "antd/es/button";
import { useForm } from "react-hook-form";
import { Input } from "../../common/forms/input/input";
import { AuthenticationFormHint } from "../common/authentication-form-hint/authentication-form-hint";
import { Link } from "react-router-dom";
import { FormItem } from "../../common/forms/form-item/form-item";
import { api } from "../../../common/api/api";
import message from "antd/es/message";

export const ForgotPassword: FC = () => {

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSent, setIsSent] = useState<boolean>(false)

    // form state
    const { getValues, handleSubmit, register, formState } = useForm({
        defaultValues: {
            emailAddress: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        const { emailAddress } = getValues()

        const result = await api.auth.requestResetPasswordLink({
            emailAddress,
        })

        if (!result.success) {
            message.error(result.errorMessage ?? `Something went wrong. Please try again.`)
            setIsLoading(false)
            return;
        }

        if (!!result.success) {
            message.success("Sent password reset email. Please check your email to continue.")
            setIsSent(true)
        }

        setIsLoading(false)
    }, [getValues])

    return (
        <AuthenticationForm onSubmit={handleSubmit(handleFormSubmit)}>
            <AuthenticationFormHeader
                title="Forgot your password?"
                subtitle="Enter your email address and we'll help you get back in."
            />
            <FormItemsContainer>
                <FormItem label="Email Address">
                    <Input
                        {...register('emailAddress', { required: true })}
                        placeholder="Email Address"
                        type="email"
                        autoComplete="email"
                        disabled={isLoading || isSent}
                    />
                </FormItem>
                <AuthenticationFormFooter>
                    <Button
                        type="primary"
                        disabled={!formState.isValid || isSent}
                        htmlType="submit"
                        loading={isLoading}
                    >
                        {isSent ? "Email Sent!" : "Send Password Reset Email"}
                    </Button>
                    <AuthenticationFormHint>
                        Want to try to sign in again? <Link to="/sign-in">Click here to sign in</Link>
                    </AuthenticationFormHint>
                </AuthenticationFormFooter>
            </FormItemsContainer>
        </AuthenticationForm>
    )
}