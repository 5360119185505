import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type EmptyStateContainerProps = {
	children: React.ReactNode
}

export const EmptyStateContainer: FC<EmptyStateContainerProps> = ({
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.emptyStateContainer}>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	emptyStateContainer: {
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 400,
	}
})