import { RemoveOrganizationMemberResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class OrganizationMemberRemoveUserFormService {
    async removeMember(organizationMemberId: string): Promise<RemoveOrganizationMemberResult> {
        let result: RemoveOrganizationMemberResult | undefined = undefined;

        try {
            result = await api.organizationMembers.removeMember(organizationMemberId)
        } catch {}

        if (!result) {
            result = new RemoveOrganizationMemberResult()
            result.success = false
        }

        return result;
    }
}

export const service = new OrganizationMemberRemoveUserFormService()