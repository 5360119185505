import { AuthChangeEvent, Session } from '@supabase/supabase-js'
import React, { createContext, FC, useCallback, useEffect, useState } from 'react'
import { supabase } from '../../../../common/supabase/supabase'

type AuthenticationProviderProps = {
    children: React.ReactNode
}

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({ children }) => {

    // state
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>('authenticating')
    const [session, setSession] = useState<Session | null>(null)
    const [capturedUrl, setCapturedUrl] = useState<string | null>(null)

    // imperative logic
    const loadInitialSession = useCallback(async () => {
        const sessionResult = await supabase.auth.getSession()
        const session = sessionResult.data.session;

        setAuthenticationState(session ? 'authenticated' : 'notAuthenticated')
        setSession(session)
    }, [])

    // event handlers
    const handleSupabaseAuthStateChanged = useCallback((event: AuthChangeEvent, newSession: Session | null) => {
        setSession(newSession)
        switch (event) {
            case 'SIGNED_IN':
                setAuthenticationState('authenticated')
                break
            case 'SIGNED_OUT':
                setAuthenticationState('notAuthenticated')
                break
            case 'USER_DELETED':
                setAuthenticationState('notAuthenticated')
                break
        }
    }, [])

    // side effects
    useEffect(() => {
        loadInitialSession()
        const { data } = supabase.auth.onAuthStateChange(handleSupabaseAuthStateChanged)
        return () => {
            data.subscription.unsubscribe()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AuthenticationContext.Provider value={{
            authenticationState,
            session,
            capturedUrl,
            onCapturedUrlChange: setCapturedUrl,
        }}>
            {children}
        </AuthenticationContext.Provider>
    )
}

export type AuthenticationContextType = {
    authenticationState: AuthenticationState
    session: Session | null
    capturedUrl: string | null
    onCapturedUrlChange: (capturedUrl: string) => void
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
    authenticationState: 'authenticated',
    session: null,
    capturedUrl: null,
    onCapturedUrlChange: () => {},
})

export type AuthenticationState = 'authenticating' | 'authenticated' | 'notAuthenticated'