import { Navigate, Route } from "react-router-dom";
import { ConstellationRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/constellation-route/ConstellationRoute";
import { ConstellationAnalyticsRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/constellation-route/subroutes/constellation-analytics-route/ConstellationAnalyticsRoute";
import { ConstellationDashboardRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/constellation-route/subroutes/constellation-dashboard-route/ConstellationDashboardRoute";
import { SchedulesRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/constellation-route/subroutes/schedules-route/SchedulesRoute";
import { ScheduleEmptyState, ScheduleRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/constellation-route/subroutes/schedules-route/subroutes/schedule-route/ScheduleRoute";
import { ConstellationRouteName } from "../../../../../constellations/common/constellation-route-name/constellation-route-name";
import { ScheduleRouteName } from "../../../../../schedules/schedule-route-name/schedule-route-name";

export const ConstellationRoutes = (
    <Route path=":constellation_id" element={<ConstellationRoute />} handle={{ RouteNameComponent: ConstellationRouteName }}>
        <Route index element={<Navigate to="dashboard" replace />} />

        <Route path="dashboard" element={<ConstellationDashboardRoute />} />
        <Route path="analytics" element={<ConstellationAnalyticsRoute />} />
        <Route path="schedules" element={<SchedulesRoute />}>
            <Route index element={<ScheduleEmptyState />} />
            <Route path=":schedule_id" element={<ScheduleRoute />} handle={{ RouteNameComponent: ScheduleRouteName }} />
        </Route>
    </Route>
)