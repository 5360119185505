import Dropdown, { DropDownProps } from 'antd/es/dropdown'
import React, { FC, useCallback, useState } from 'react'
import { OverflowMenu } from '../../common/overflow-menu/OverflowMenu'
import { CancelOrganizationMemberInvitationMenuItem } from './contents/cancel-organization-member-invitation-menu-item/cancel-organization-member-invitation-menu-item'
import { OrganizationMemberInvitationContextMenu_OrganizationMemberInvitationFragment } from './OrganizationMemberInvitationContextMenu.generated'

type OrganizationMemberInvitationContextMenuProps = {
	organizationMemberInvitation: OrganizationMemberInvitationContextMenu_OrganizationMemberInvitationFragment
} & Partial<DropDownProps>

export const OrganizationMemberInvitationContextMenu: FC<OrganizationMemberInvitationContextMenuProps> = ({
	organizationMemberInvitation,
	children,
	...rest
}) => {

	// hooks
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

	const showCancelInvitation = true

	const itemsShowing = [showCancelInvitation].includes(true)

	// event handlers
	const handleMenuClose = useCallback(() => {
		setMenuIsOpen(false)
	}, [])

	if (
		!itemsShowing
	) return null

	const menu = (
		<OverflowMenu>
			{showCancelInvitation && (
				<CancelOrganizationMemberInvitationMenuItem
					organizationMemberInvitationId={organizationMemberInvitation.id}
					onClose={handleMenuClose}
				/>
			)}
		</OverflowMenu>
	)

	return (
		<Dropdown
			{...rest}
			overlay={menu}
			trigger={itemsShowing ? ['click'] : []}
			open={menuIsOpen}
			onOpenChange={setMenuIsOpen}
		>
			{children}
		</Dropdown>
	)
}