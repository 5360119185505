import { Route, Navigate } from "react-router-dom";
import { AuthenticationRoute } from "../../../../../../routes/authentication-route/authentication-route";
import { ForgotPasswordRoute } from "../../../../../../routes/authentication-route/subroutes/forgot-password-route/forgot-password-route";
import { SignInRoute } from "../../../../../../routes/authentication-route/subroutes/sign-in-route/sign-in-route";
import { SignUpRoute } from "../../../../../../routes/authentication-route/subroutes/sign-up-route/sign-up-route";
import { CaptureUrlRoute } from "../../../../../../routes/authentication-route/subroutes/capture-url-route/capture-url-route";
import { ResetPasswordRoute } from "../../../../../../routes/authentication-route/subroutes/reset-password-route/reset-password-route";

export const AuthenticatorRoutes = (
    <Route path="*" element={<AuthenticationRoute />}>
        <Route path="sign-in" element={<SignInRoute />} />
        <Route path="sign-up" element={<SignUpRoute />} />
        <Route path="forgot-password" element={<ForgotPasswordRoute />} />
        <Route path="reset-password" element={<ResetPasswordRoute />} />
        <Route path="*" element={<CaptureUrlRoute />} />
        <Route index element={<Navigate to="sign-in" />} />
    </Route>
)