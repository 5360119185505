import '../../../app/components/app-shell/app-shell.less'
import React, { FC, useCallback, useState } from 'react'
import { PreferencesSideBar } from './subcomponents/preferences-side-bar/preferences-side-bar'
import { PreferencesHeader } from './subcomponents/preferences-header/preferences-header'
import { AppShellContext } from '../../../app/components/app-shell/app-shell-context'
import { useIsMobile } from '../../../common/constants/breakpoints/breakpoints'
import { MainContentContainer, PreferencesShellContainer } from './preferences-shell.styled'
import { usePreferencesShell_OrganizationQuery } from './preferences-shell.generated'

type PreferencesShellProps = {
    organizationId: string | undefined
    children: React.ReactNode
}

export const PreferencesShell: FC<PreferencesShellProps> = ({
    organizationId,
    children,
}) => {

    // data
    const { data } = usePreferencesShell_OrganizationQuery({ organizationId }, { enabled: !!organizationId })

    // constants
    const organization = data?.organizationsCollection?.edges[0]?.node;

    // state
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(() => {
        const initialValue = window.localStorage.getItem('appShell_sidebarCollapsed')
        return initialValue === 'true' ? true : false
    })

    // responsive design
    const isMobile = useIsMobile()

    // event handlers
    const handleSidebarCollapsedChange = useCallback((newSidebarCollapsed: boolean) => {
        setSidebarCollapsed(newSidebarCollapsed)
        window.localStorage.setItem('appShell_sidebarCollapsed', newSidebarCollapsed ? 'true' : 'false')
    }, [])

    return (
        <AppShellContext.Provider value={{
            sidebarCollapsed: !isMobile && sidebarCollapsed,
            onSidebarCollapsedChange: handleSidebarCollapsedChange,
        }}>
            <PreferencesShellContainer
                isSidebarCollapsed={sidebarCollapsed}
            >
                <PreferencesSideBar />
                <MainContentContainer>
                    <PreferencesHeader organization={organization} />
                    <div>
                        {children}
                    </div>
                </MainContentContainer>
            </PreferencesShellContainer>
        </AppShellContext.Provider>
    )
}