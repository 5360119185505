/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { ConstellationGridItem_ConstellationFragmentDoc } from '../../../constellations/common/constellations-grid/subcomponents/constellation-grid-item/constellation-grid-item.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type WebsiteDemoWidgetViewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WebsiteDemoWidgetViewQuery = { constellationsCollection: { edges: Array<{ node: { id: any, icon: string | null, name: string, color: string | null, isArchived: boolean, organizationId: any | null, constellationGridItem_constellation_novasCollection: { edges: Array<{ node: { embeddedLink: string | null, novaType: { id: any, code: string } | null, novaDecorationImage: { imageUrl: string | null } | null } }> } | null, constellationContextMenu_constellation_novasCollection: { edges: Array<{ node: { id: any, name: string, embeddedLink: string | null, novaType: { id: any, code: string } | null } }> } | null, EditConstellationModal_Novas: { edges: Array<{ node: { id: any, embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { id: any, imageUrl: string | null } | null } }> } | null } }> } | null };


export const WebsiteDemoWidgetViewDocument = `
    query WebsiteDemoWidgetView {
  constellationsCollection(first: 1) {
    edges {
      node {
        ...ConstellationGridItem_Constellation
      }
    }
  }
}
    ${ConstellationGridItem_ConstellationFragmentDoc}`;
export const useWebsiteDemoWidgetViewQuery = <
      TData = WebsiteDemoWidgetViewQuery,
      TError = unknown
    >(
      variables?: WebsiteDemoWidgetViewQueryVariables,
      options?: UseQueryOptions<WebsiteDemoWidgetViewQuery, TError, TData>
    ) =>
    useQuery<WebsiteDemoWidgetViewQuery, TError, TData>(
      variables === undefined ? ['WebsiteDemoWidgetView'] : ['WebsiteDemoWidgetView', variables],
      fetcher<WebsiteDemoWidgetViewQuery, WebsiteDemoWidgetViewQueryVariables>(WebsiteDemoWidgetViewDocument, variables),
      options
    );

useWebsiteDemoWidgetViewQuery.getKey = (variables?: WebsiteDemoWidgetViewQueryVariables) => variables === undefined ? ['WebsiteDemoWidgetView'] : ['WebsiteDemoWidgetView', variables];
;

export const useInfiniteWebsiteDemoWidgetViewQuery = <
      TData = WebsiteDemoWidgetViewQuery,
      TError = unknown
    >(
      pageParamKey: keyof WebsiteDemoWidgetViewQueryVariables,
      variables?: WebsiteDemoWidgetViewQueryVariables,
      options?: UseInfiniteQueryOptions<WebsiteDemoWidgetViewQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<WebsiteDemoWidgetViewQuery, TError, TData>(
      variables === undefined ? ['WebsiteDemoWidgetView.infinite'] : ['WebsiteDemoWidgetView.infinite', variables],
      (metaData) => fetcher<WebsiteDemoWidgetViewQuery, WebsiteDemoWidgetViewQueryVariables>(WebsiteDemoWidgetViewDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteWebsiteDemoWidgetViewQuery.getKey = (variables?: WebsiteDemoWidgetViewQueryVariables) => variables === undefined ? ['WebsiteDemoWidgetView.infinite'] : ['WebsiteDemoWidgetView.infinite', variables];
;

useWebsiteDemoWidgetViewQuery.fetcher = (variables?: WebsiteDemoWidgetViewQueryVariables, options?: RequestInit['headers']) => fetcher<WebsiteDemoWidgetViewQuery, WebsiteDemoWidgetViewQueryVariables>(WebsiteDemoWidgetViewDocument, variables, options);