import { UseQueryResult } from "@tanstack/react-query"
import { useEffect } from "react"
import { UseNotifications_NotificationsQuery, useUseNotifications_NotificationsQuery } from "./UseNotifications.generated"

export const useNotifications = (userId: string | null): UseQueryResult<UseNotifications_NotificationsQuery, unknown> => {

    // data
    const { data, refetch, ...rest } = useUseNotifications_NotificationsQuery({ userId }, { enabled: !!userId })

    // side effects
    useEffect(() => {
        if (!userId) return

        /* subscribe to updates */
        // const subscription = supabase
        //     .from(`notifications:user_id=eq.${userId}`)
        //     .on('*', () => refetch())
        //     .subscribe()

        /* unsubscribe on cleanup */
        return () => {
            // subscription.unsubscribe()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    return { data, refetch, ...rest } as UseQueryResult<UseNotifications_NotificationsQuery, unknown>
}