import styled from "@emotion/styled";
import { TABLET_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";
import { FontSize, Gap } from "../../../../../../common/constants/constants";

export const SelectorContainer = styled.div({
    /* layout */
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: Gap.default,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        flexDirection: 'column',
        alignItems: 'unset',
    }
})

export const SelectorLabel = styled.span({
    /* text */
    color: `rgba(255, 255, 255, 0.5)`,
    fontSize: FontSize.default,
})

export const SelectorContainerInner = styled.div({
    /* layout */
    display: 'grid',

    /* size */
    width: '100%',
    maxWidth: 300,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        maxWidth: 'unset',
    },
})