import React, { FC, ReactElement, ReactNode } from 'react'
import { DialogHeroPanelCard, DialogHeroPanelContainer, HeroDescription, HeroTextContainer, IconContainer, InnerContainer, PlatformLogoImage } from './dialog-hero-panel.styled'

type DialogHeroPanelProps = {
	title: string
	description: ReactNode
	icon?: ReactElement
}

export const DialogHeroPanel: FC<DialogHeroPanelProps> = ({
	title,
	description,
	icon,
}) => {
	return (
		<DialogHeroPanelContainer>
			<DialogHeroPanelCard>
				<InnerContainer>
					<IconContainer>
						{icon ?? (
							<PlatformLogoImage
								src="/images/logo/cqr23_icons_Dark BG.svg"
								alt='QR Code Icon'
							/>
						)}
					</IconContainer>
					<HeroTextContainer>
						<h1>{title}</h1>
						<HeroDescription>{description}</HeroDescription>
					</HeroTextContainer>
				</InnerContainer>
			</DialogHeroPanelCard>
		</DialogHeroPanelContainer>
	)
}