import QuestionOutlined from '@ant-design/icons/QuestionOutlined'
import Button from 'antd/es/button'
import React, { FC } from 'react'
import { NotificationsButton } from '../../../../../app/components/app-shell/subcomponents/app-header/subcomponents/notifications-button/NotificationsButton'
import { PreferencesOrganizationSelector } from '../preferences-organization-selector/preferences-organization-selector'
import { ActionsContainer, HeaderContainer } from './preferences-header.styled'
import { useIsTablet } from '../../../../../common/constants/breakpoints/breakpoints'
import { PreferencesMobileTopBar } from './subcomponents/preferences-mobile-top-bar/preferences-mobile-top-bar'
import { SubscriptionButton } from '../../../../../app/components/app-shell/subcomponents/app-header/subcomponents/subscription-button/subscription-button'
import { PreferencesHeader_OrganizationFragment } from './preferences-header.generated'

type PreferencesHeaderProps = {
	organization: PreferencesHeader_OrganizationFragment | undefined
}

export const PreferencesHeader: FC<PreferencesHeaderProps> = ({
	organization,
}) => {

	// responsive hooks
	const isTablet = useIsTablet()

	if (isTablet) return (
		<PreferencesMobileTopBar />
	)

	return (
		<HeaderContainer>
			<PreferencesOrganizationSelector />
			<ActionsContainer>
				{organization && (
					<SubscriptionButton organization={organization} />
				)}
				<NotificationsButton />
				<Button type="default" icon={<QuestionOutlined />} />
			</ActionsContainer>
		</HeaderContainer>
	)
}