import { purple } from "@ant-design/colors";
import { Colors } from "../colors/colors";
import { Padding } from "./constants";

export const mosaicStyleOverrides = {
    '& .mosaic.mosaic-blueprint-theme': {
        background: 'transparent',
        backgroundColor: 'transparent',
        '& .mosaic-root': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        '& .mosaic-window': {
            boxShadow: 'none',
            borderRadius: 'none',
        },
        '& .mosaic-window-body': {
            padding: Padding.default,
            paddingTop: Padding.most,
            background: '#15151c',
            display: 'grid',
            overflowY: 'auto',
        },
        '& .mosaic-window-toolbar': {
            background: '#15151c',
            height: 'auto',
            padding: 8,
            paddingBottom: 0,
            '&.draggable:hover': {
                background: '#15151c',
            },
        },
        '& .mosaic-split': {
            '& .mosaic-split-line': {
                boxShadow: `0 0 0 0.5px ${Colors.border}`,
            },
            '&:hover .mosaic-split-line': {
                boxShadow: `0 0 0 1.5px ${purple.primary}`,
            },
        },
        '&.mosaic-drop-target .drop-target-container .drop-target, .mosaic.mosaic-blueprint-theme .mosaic-drop-target .drop-target-container .drop-target': {
            background: 'rgba(146, 84, 222, 0.2)',
            border: `2px solid ${purple.primary}`,
        },
        '& .mosaic-preview': {
            border: 'none',
        }
    },
}