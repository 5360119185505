import styled from "@emotion/styled";
import { Colors } from "../../../common/colors/colors";
import { blue } from "@ant-design/colors";
import { BorderRadius, Padding, Gap, FontSize } from "../../../common/constants/constants";

export const OrganizationMemberGridItemContainer = styled.div<{
    isActive: boolean
}>(({
    isActive,
}) => ({
    /* layout */
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: Gap.default,

    /* background */
    backgroundColor: Colors.cardBackground,

    /* border */
    borderRadius: BorderRadius.more,
    border: '2px solid transparent',
    borderColor: isActive ? blue.primary : undefined,

    /* padding */
    padding: Padding.default,

    /* interaction */
    cursor: 'pointer',

    /* position */
    position: 'relative',

    /* animation */
    transition: 'border 100ms ease',

    /* hover */
    '&:hover': {
        border: `2px solid ${blue.primary}`
    },
}))

export const IconContainer = styled.div({
    /* layout */
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    /* border */
    borderRadius: BorderRadius.less,

    /* padding */
    padding: 12,

    /* text */
    fontSize: FontSize.default,

    /* background */
    backgroundColor: blue.primary,
})

export const DetailsContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateRows: 'repeat(2, max-content)',
})

export const DescriptorContainer = styled.div({
    /* overflow */
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
})

export const Email = styled.span({
    /* text */
    fontStyle: 'italic',
    fontSize: FontSize.default,
})