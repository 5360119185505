import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'antd/es/button'
import { FC, useCallback, useState } from "react";
import { ClassNames } from "@emotion/react";
import { useCurrentMatch } from "./mobile-nav-menu-button-hooks";
import { MobileNavMenu } from "../mobile-nav-menu/mobile-nav-menu";
import { NavButtonStyles } from "./mobile-nav-menu-button.styled";
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars'

export const MobileNavMenuButton: FC = () => {

    // state
    const [showMenu, setShowMenu] = useState<boolean>(false)

    // custom hooks
    const currentMatch = useCurrentMatch()
    
    // constants
    const RouteNameComponent = currentMatch?.handle?.RouteNameComponent;

    // event handlers
    const handleButtonClick = useCallback(() => {
        setShowMenu(true)
    }, [])

    const handleMenuDismiss = useCallback(() => {
        setShowMenu(false)
    }, [])

    return (
        <>
            <ClassNames>
                {({ css }) => (
                    <Button
                        type="ghost"
                        icon={<FontAwesomeIcon icon={faBars} />}
                        className={css(NavButtonStyles)}
                        onClick={handleButtonClick}
                    >
                        {RouteNameComponent ? (
                            <RouteNameComponent data={currentMatch} />
                        ) : 'Constellation QR'}
                    </Button>
                )}
            </ClassNames>
            <MobileNavMenu
                open={showMenu}
                onDismiss={handleMenuDismiss}
            />
        </>
    )
}