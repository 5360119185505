import Empty from 'antd/es/empty'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { EmptyStateContainer } from '../../../../../../../../../../../../modules/common/empty-state-container/EmptyStateContainer'
import { OrganizationMemberView } from '../../../../../../../../../../../../modules/preferences/views/organization-member-view/OrganizationMemberView'

type OrganizationMemberRouteProps = {}

export const OrganizationMemberRoute: FC<OrganizationMemberRouteProps> = () => {

	// router hooks
	const {
		organization_id: organizationId,
		organization_member_id: organizationMemberId
	} = useParams() as {
		organization_id: string
		organization_member_id: string
	}

	return (
		<OrganizationMemberView
			organizationId={organizationId}
			organizationMemberId={organizationMemberId}
		/>
	)
}

export const OrganizationMemberRouteEmptyState: FC = () => {
	return (
		<EmptyStateContainer>
			<Empty
				description="Choose a user to view them here here"
			/>
		</EmptyStateContainer>
	)
}