import { ClassNames } from "@emotion/react";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { FormFieldLinkContainer, StyledLink } from "./form-field-link.styled";

type FormFieldLinkProps = Omit<LinkProps, 'className'>

export const FormFieldLink = forwardRef<HTMLAnchorElement, FormFieldLinkProps>((props, ref) => {
    return (
        <FormFieldLinkContainer>
            <ClassNames>
                {({ css }) => (
                    <Link
                        ref={ref}
                        {...props}
                        className={css(StyledLink.__emotion_styles)}
                    />
                )}
            </ClassNames>
        </FormFieldLinkContainer>
    )
})