/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { SubscriptionButton_OrganizationFragmentDoc } from '../../../../../app/components/app-shell/subcomponents/app-header/subcomponents/subscription-button/subscription-button.generated';
export type PreferencesHeader_OrganizationFragment = { id: any };

export const PreferencesHeader_OrganizationFragmentDoc = `
    fragment PreferencesHeader_Organization on Organizations {
  ...SubscriptionButton_Organization
}
    ${SubscriptionButton_OrganizationFragmentDoc}`;