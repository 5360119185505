import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../constants/breakpoints/breakpoints";
import { Gap } from "../../../../../common/constants/constants";

export const SpinWrapperStyles = css({
    overflowY: 'auto',

    /* border */
    borderLeft: `1.5px solid rgb(55, 55, 55)`,

    '&>.ant-spin-container': {
        minHeight: '100%',
    },

    [TABLET_BREAKPOINT]: {
        /* border */
        borderLeft: 'none',
    },
})

export const OneColumn = css({
    borderLeft: 'none',
})

export const DialogBodyContainer = styled.div({
    /*  */

    /* position */
    position: 'relative',

    /* layout */
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 2,

    /* size */
    height: '100%',
    maxHeight: '100%',

    /* breakpoints */
    [MOBILE_BREAKPOINT]: {
        /* layout */
        gridColumnStart: 'unset',
        gridColumnEnd: 'unset',
        gridRowStart: 'unset',
        gridRowEnd: 'unset',
    },
})

export const DialogBodyInner = styled.div({
    /* layout */
    display: 'grid',
    gridAutoRows: 'max-content',
    gap: Gap.more,
    maxHeight: '100%',

    /* padding */
    padding: 24,

    /* overflow */
    overflowY: 'auto',

    /* breakpoints */
    [MOBILE_BREAKPOINT]: {
        padding: 16,
    },
})