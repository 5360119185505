import message from 'antd/es/message'
import Spin from 'antd/es/spin'
import Form from 'antd/es/form'
import Button from 'antd/es/button'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Gap } from '../../../common/constants/constants'
import { supabase } from '../../../common/supabase/supabase'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { Input } from '../../common/forms/input/input'

type ChangePasswordFormProps = {

}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = () => {

	// styles
	const styles = useStyles()

	// form state
	const { control, formState: { isValid: formValid }, getValues, handleSubmit } = useForm({
		defaultValues: {
			newPassword: '',
		}, mode: 'all'
	})

	// state
	const [loading, setLoading] = useState<boolean>(false)

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// event handlers
	const handleFormSubmit = useCallback(async () => {
		setLoading(true)

		try {
			const { newPassword } = getValues()

			await supabase.auth.updateUser({ password: newPassword })

			await logAppEvent('changed_password', {})
	
			message.success(`Successfully changed password`)
			setLoading(false)
		} catch (err) {
			message.error(`Something went wrong. Please try again later`)
			setLoading(false)
		}
	}, [getValues, logAppEvent])

	const handleSaveButtonClick = useCallback(() => {
		handleSubmit(handleFormSubmit)()
	}, [handleSubmit, handleFormSubmit])

	return (
		<div className={styles.changePasswordForm}>
			<Spin spinning={loading}>
				<Form className={styles.form} layout='vertical' onSubmitCapture={handleSubmit(handleFormSubmit)}>
					<Form.Item label="New Password">
						<Controller
							control={control}
							name="newPassword"
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									placeholder={`New password`}
									type="password"
									autoComplete='new-password'
									{...field}
								/>
							)}
						/>
					</Form.Item>
				</Form>
				<div className={styles.formFooter}>
					<Button type='primary' disabled={!formValid} onClick={handleSaveButtonClick}>
						Change Password
					</Button>
				</div>
			</Spin>
		</div>
	)
}

const useStyles = createUseStyles({
	changePasswordForm: {
		display: 'grid'
	},
	form: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: Gap.default,
	},
	formFooter: {
		display: 'grid',
		justifyContent: 'end',
	},
})