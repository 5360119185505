import { FC, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthenticationContext } from "../../../../modules/app/providers/authentication-provider/AuthenticationProvider";

export const CaptureUrlRoute: FC = () => {

    // context
    const { onCapturedUrlChange } = useContext(AuthenticationContext)

    // router hooks
    const location = useLocation()

    // side effects
    useEffect(() => {
        onCapturedUrlChange(location.pathname)

    }, [location.pathname, onCapturedUrlChange])

    return (
        <Navigate to="/sign-in" />
    )
}