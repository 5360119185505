import styled from "@emotion/styled";
import { Padding } from "../../../../../common/constants/constants";

export const DialogTitleContainer = styled.div({
    /* layout */
    display: 'flex',
    alignItems: 'center',

    /* position */
    position: 'sticky',
    left: 0,
    top: 0,
    right: 0,

    /* padding */
    paddingBottom: 12,
})

export const IconContainer = styled.div({
    fontSize: 32,
    paddingRight: Padding.most,
})

export const TitleText = styled.span({
    fontSize: 24,
    fontWeight: 'bold',
})