/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type AppSettingsProvider_AppSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppSettingsProvider_AppSettingsQuery = { appSettingsCollection: { edges: Array<{ node: { id: any, code: string, value: string | null } }> } | null };


export const AppSettingsProvider_AppSettingsDocument = `
    query AppSettingsProvider_AppSettings {
  appSettingsCollection(filter: {isDeleted: {eq: false}}) {
    edges {
      node {
        id
        code
        value
      }
    }
  }
}
    `;
export const useAppSettingsProvider_AppSettingsQuery = <
      TData = AppSettingsProvider_AppSettingsQuery,
      TError = unknown
    >(
      variables?: AppSettingsProvider_AppSettingsQueryVariables,
      options?: UseQueryOptions<AppSettingsProvider_AppSettingsQuery, TError, TData>
    ) =>
    useQuery<AppSettingsProvider_AppSettingsQuery, TError, TData>(
      variables === undefined ? ['AppSettingsProvider_AppSettings'] : ['AppSettingsProvider_AppSettings', variables],
      fetcher<AppSettingsProvider_AppSettingsQuery, AppSettingsProvider_AppSettingsQueryVariables>(AppSettingsProvider_AppSettingsDocument, variables),
      options
    );

useAppSettingsProvider_AppSettingsQuery.getKey = (variables?: AppSettingsProvider_AppSettingsQueryVariables) => variables === undefined ? ['AppSettingsProvider_AppSettings'] : ['AppSettingsProvider_AppSettings', variables];
;

export const useInfiniteAppSettingsProvider_AppSettingsQuery = <
      TData = AppSettingsProvider_AppSettingsQuery,
      TError = unknown
    >(
      pageParamKey: keyof AppSettingsProvider_AppSettingsQueryVariables,
      variables?: AppSettingsProvider_AppSettingsQueryVariables,
      options?: UseInfiniteQueryOptions<AppSettingsProvider_AppSettingsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<AppSettingsProvider_AppSettingsQuery, TError, TData>(
      variables === undefined ? ['AppSettingsProvider_AppSettings.infinite'] : ['AppSettingsProvider_AppSettings.infinite', variables],
      (metaData) => fetcher<AppSettingsProvider_AppSettingsQuery, AppSettingsProvider_AppSettingsQueryVariables>(AppSettingsProvider_AppSettingsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteAppSettingsProvider_AppSettingsQuery.getKey = (variables?: AppSettingsProvider_AppSettingsQueryVariables) => variables === undefined ? ['AppSettingsProvider_AppSettings.infinite'] : ['AppSettingsProvider_AppSettings.infinite', variables];
;

useAppSettingsProvider_AppSettingsQuery.fetcher = (variables?: AppSettingsProvider_AppSettingsQueryVariables, options?: RequestInit['headers']) => fetcher<AppSettingsProvider_AppSettingsQuery, AppSettingsProvider_AppSettingsQueryVariables>(AppSettingsProvider_AppSettingsDocument, variables, options);