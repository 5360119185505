/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { OrganizationMemberInvitationContextMenu_OrganizationMemberInvitationFragmentDoc } from '../enterprise-member-invitation-context-menu/OrganizationMemberInvitationContextMenu.generated';
export type OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragment = { invitedUserEmailAddress: string | null, id: any };

export const OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragmentDoc = `
    fragment OrganizationMemberInvitationGridItem_OrganizationMemberInvitation on OrganizationMemberInvitations {
  invitedUserEmailAddress
  ...OrganizationMemberInvitationContextMenu_OrganizationMemberInvitation
}
    ${OrganizationMemberInvitationContextMenu_OrganizationMemberInvitationFragmentDoc}`;