import { FC } from "react";
import { AuthenticationFormContainer, AuthForm } from "./authentication-form.styled";

type AuthenticationFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export const AuthenticationForm: FC<AuthenticationFormProps> = ({
    children,
    ...formProps
}) => {
    return (
        <AuthenticationFormContainer>
            <AuthForm {...formProps}>
                {children}
            </AuthForm>
        </AuthenticationFormContainer>
    )
}