/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type ConstellationRouteName_ConstellationQueryVariables = Types.Exact<{
  constellationId: Types.Scalars['UUID'];
}>;


export type ConstellationRouteName_ConstellationQuery = { constellationsCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };


export const ConstellationRouteName_ConstellationDocument = `
    query ConstellationRouteName_Constellation($constellationId: UUID!) {
  constellationsCollection(filter: {id: {eq: $constellationId}}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const useConstellationRouteName_ConstellationQuery = <
      TData = ConstellationRouteName_ConstellationQuery,
      TError = unknown
    >(
      variables: ConstellationRouteName_ConstellationQueryVariables,
      options?: UseQueryOptions<ConstellationRouteName_ConstellationQuery, TError, TData>
    ) =>
    useQuery<ConstellationRouteName_ConstellationQuery, TError, TData>(
      ['ConstellationRouteName_Constellation', variables],
      fetcher<ConstellationRouteName_ConstellationQuery, ConstellationRouteName_ConstellationQueryVariables>(ConstellationRouteName_ConstellationDocument, variables),
      options
    );

useConstellationRouteName_ConstellationQuery.getKey = (variables: ConstellationRouteName_ConstellationQueryVariables) => ['ConstellationRouteName_Constellation', variables];
;

export const useInfiniteConstellationRouteName_ConstellationQuery = <
      TData = ConstellationRouteName_ConstellationQuery,
      TError = unknown
    >(
      pageParamKey: keyof ConstellationRouteName_ConstellationQueryVariables,
      variables: ConstellationRouteName_ConstellationQueryVariables,
      options?: UseInfiniteQueryOptions<ConstellationRouteName_ConstellationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ConstellationRouteName_ConstellationQuery, TError, TData>(
      ['ConstellationRouteName_Constellation.infinite', variables],
      (metaData) => fetcher<ConstellationRouteName_ConstellationQuery, ConstellationRouteName_ConstellationQueryVariables>(ConstellationRouteName_ConstellationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteConstellationRouteName_ConstellationQuery.getKey = (variables: ConstellationRouteName_ConstellationQueryVariables) => ['ConstellationRouteName_Constellation.infinite', variables];
;

useConstellationRouteName_ConstellationQuery.fetcher = (variables: ConstellationRouteName_ConstellationQueryVariables, options?: RequestInit['headers']) => fetcher<ConstellationRouteName_ConstellationQuery, ConstellationRouteName_ConstellationQueryVariables>(ConstellationRouteName_ConstellationDocument, variables, options);