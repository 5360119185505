import React, { FC, ReactNode } from 'react'
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs'
import { ActionsContainer, BarInnerContainer, IconContainer, NavigationContainer, ViewBarContainer, ViewBarSection, ViewName } from './view-bar.styled'
import { useIsMobile } from '../constants/breakpoints/breakpoints'

type ViewBarProps = {
    icon?: ReactNode
    viewName: ReactNode
    actions?: ReactNode
    tabBar?: ReactNode
}

export const ViewBar: FC<ViewBarProps> = ({
    icon,
    viewName,
    actions,
    tabBar,
}) => {

    // responsive hooks
    const isMobile = useIsMobile()
    
    return (
        <ViewBarSection hasTabBar={!!tabBar}>
            <ViewBarContainer
                hasIcon={!!icon && !isMobile}
                hasTabBar={!!tabBar}
            >
                {icon && !isMobile && (
                    <IconContainer>
                        {icon}
                    </IconContainer>
                )}
                <NavigationContainer>
                    <Breadcrumbs />
                    <BarInnerContainer>
                        <ViewName>
                            {viewName}
                        </ViewName>
                        <ActionsContainer>
                            {actions}
                        </ActionsContainer>
                    </BarInnerContainer>
                </NavigationContainer>
            </ViewBarContainer>
            {tabBar}
        </ViewBarSection>
    )
}