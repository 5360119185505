import styled from "@emotion/styled";

export const FormHintText = styled.span({
    textAlign: 'center',
    fontSize: 12,

    opacity: 0.5,

    '> a': {
        color: 'inherit',
        textDecoration: 'underline',
    },
})