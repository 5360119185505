import { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { ResetPassword } from "../../../../modules/authentication/reset-password/reset-password";

export const ResetPasswordRoute: FC = () => {
    
    // router params
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    if (!token) return (
        <Navigate to="/sign-in" />
    )

    return (
        <ResetPassword token={token} />
    )
}