/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { UserAccountView_UserFragmentDoc } from './subviews/user-account-view/UserAccountView.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type UserAccountPreferencesView_UserQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UserAccountPreferencesView_UserQuery = { userAccountPreferencesView_user_usersCollection: { edges: Array<{ node: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } }> } | null };


export const UserAccountPreferencesView_UserDocument = `
    query UserAccountPreferencesView_User($userId: UUID!) {
  userAccountPreferencesView_user_usersCollection: usersCollection(
    filter: {id: {eq: $userId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...UserAccountView_User
      }
    }
  }
}
    ${UserAccountView_UserFragmentDoc}`;
export const useUserAccountPreferencesView_UserQuery = <
      TData = UserAccountPreferencesView_UserQuery,
      TError = unknown
    >(
      variables: UserAccountPreferencesView_UserQueryVariables,
      options?: UseQueryOptions<UserAccountPreferencesView_UserQuery, TError, TData>
    ) =>
    useQuery<UserAccountPreferencesView_UserQuery, TError, TData>(
      ['UserAccountPreferencesView_User', variables],
      fetcher<UserAccountPreferencesView_UserQuery, UserAccountPreferencesView_UserQueryVariables>(UserAccountPreferencesView_UserDocument, variables),
      options
    );

useUserAccountPreferencesView_UserQuery.getKey = (variables: UserAccountPreferencesView_UserQueryVariables) => ['UserAccountPreferencesView_User', variables];
;

export const useInfiniteUserAccountPreferencesView_UserQuery = <
      TData = UserAccountPreferencesView_UserQuery,
      TError = unknown
    >(
      pageParamKey: keyof UserAccountPreferencesView_UserQueryVariables,
      variables: UserAccountPreferencesView_UserQueryVariables,
      options?: UseInfiniteQueryOptions<UserAccountPreferencesView_UserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<UserAccountPreferencesView_UserQuery, TError, TData>(
      ['UserAccountPreferencesView_User.infinite', variables],
      (metaData) => fetcher<UserAccountPreferencesView_UserQuery, UserAccountPreferencesView_UserQueryVariables>(UserAccountPreferencesView_UserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteUserAccountPreferencesView_UserQuery.getKey = (variables: UserAccountPreferencesView_UserQueryVariables) => ['UserAccountPreferencesView_User.infinite', variables];
;

useUserAccountPreferencesView_UserQuery.fetcher = (variables: UserAccountPreferencesView_UserQueryVariables, options?: RequestInit['headers']) => fetcher<UserAccountPreferencesView_UserQuery, UserAccountPreferencesView_UserQueryVariables>(UserAccountPreferencesView_UserDocument, variables, options);