"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateConstellationResult = exports.CreateConstellationPayload_AliasConfiguration = exports.CreateConstellationPayload = void 0;
const class_validator_1 = require("class-validator");
const base_create_result_dto_1 = require("../../../../common/dtos/base-create-result.dto");
class CreateConstellationPayload {
}
__decorate([
    (0, class_validator_1.IsString)()
], CreateConstellationPayload.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], CreateConstellationPayload.prototype, "color", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], CreateConstellationPayload.prototype, "icon", void 0);
__decorate([
    (0, class_validator_1.IsUUID)()
], CreateConstellationPayload.prototype, "organizationId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], CreateConstellationPayload.prototype, "createQRCode", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)()
], CreateConstellationPayload.prototype, "qrCodeAliasConfig", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], CreateConstellationPayload.prototype, "createWebLink", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)()
], CreateConstellationPayload.prototype, "webLinkAliasConfig", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], CreateConstellationPayload.prototype, "linkToGroupId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], CreateConstellationPayload.prototype, "novaDecorationImageId", void 0);
exports.CreateConstellationPayload = CreateConstellationPayload;
class CreateConstellationPayload_AliasConfiguration {
}
__decorate([
    (0, class_validator_1.IsString)()
], CreateConstellationPayload_AliasConfiguration.prototype, "alias", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], CreateConstellationPayload_AliasConfiguration.prototype, "aliasTypeCode", void 0);
exports.CreateConstellationPayload_AliasConfiguration = CreateConstellationPayload_AliasConfiguration;
class CreateConstellationResult extends base_create_result_dto_1.BaseCreateResult {
}
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)()
], CreateConstellationResult.prototype, "constellation", void 0);
exports.CreateConstellationResult = CreateConstellationResult;
