import { MergeAccountsPayload, MergeAccountsResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class MergeAccountsDialogService {
    public async mergeAccounts(payload: MergeAccountsPayload): Promise<MergeAccountsResult> {
        let result: MergeAccountsResult | undefined = undefined;

        try {
            result = await api.admin.mergeAccounts(payload)
        } catch {}

        if (!result) {
            result = new MergeAccountsResult();
            result.success = false
            result.errorCode = 'unknown'
            result.errorMessage = 'An unknown error occurred'
        }

        return result;
    }
}

export const service = new MergeAccountsDialogService();