import { FC, useCallback } from "react";
import { TabBarLink } from "../../tab-bar.types";
import Select from "antd/es/select";
import { useCurrentLinkIndex } from "./tab-bar-selector.hooks";
import { useNavigate } from "react-router-dom";
import { TabBarOptionContents, TabBarOptionLabel, TabBarSelectorTip } from "./tab-bar-selector.styled";

type TabBarSelectorProps = {
    filteredLinks: TabBarLink[];
}

export const TabBarSelector: FC<TabBarSelectorProps> = ({
    filteredLinks,
}) => {

    // router hooks
    const navigate = useNavigate()

    // state
    const currentLinkIndex = useCurrentLinkIndex(filteredLinks)

    // event handlers
    const handleChange = useCallback((index: number) => {
        const link = filteredLinks[index]
        navigate(link.to)
    }, [filteredLinks, navigate])

    return (
        <Select
            value={currentLinkIndex}
            onChange={handleChange}
            placeholder="Select a view..."
        >
            {filteredLinks?.map((link, index) => (
                <Select.Option
                    key={link.to}
                    value={index}
                >
                    <TabBarOptionContents>
                        <TabBarOptionLabel>
                            {link.label}
                        </TabBarOptionLabel>
                        <TabBarSelectorTip>
                            {link.tip}
                        </TabBarSelectorTip>
                    </TabBarOptionContents>
                </Select.Option>
            ))}
        </Select>
    )
}