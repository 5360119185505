import Button from 'antd/es/button'
import message from 'antd/es/message'
import Spin from 'antd/es/spin'
import React, { FC, useCallback, useContext, useState } from 'react'
import { api } from '../../../common/api/api'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'

type LogoutEverywhereFormProps = {}

export const LogoutEverywhereForm: FC<LogoutEverywhereFormProps> = () => {

	// context
	const { logAppEvent } = useContext(AppEventContext)

	const [loading, setLoading] = useState<boolean>(false)

	// event handlers
	const handleLogOutEverywhereClick = useCallback(async () => {
		try {
			setLoading(true)

			await api.auth.signOutEverywhere()
			await logAppEvent('signed_out_everywhere', {})

			message.success(`Successfully signed out everywhere`)
			setLoading(false)
		} catch (error) {
			message.error(`Something went wrong. Please try again later.`)
			setLoading(false)
		}
	}, [logAppEvent])

	return (
		<div>
			<Spin spinning={loading}>
				<Button type='primary' onClick={handleLogOutEverywhereClick}>
					Sign Out Everywhere
				</Button>
				<div style={{ marginTop: 12 }}>If you do this, you'll be logged out now here as well.</div>
			</Spin>
		</div>
	)
}