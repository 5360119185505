import { FC, ReactNode } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type DragAndDropProviderProps = {
    children: ReactNode
}

export const DragAndDropProvider: FC<DragAndDropProviderProps> = ({
    children,
}) => {
    return (
        <DndProvider backend={HTML5Backend}>
            {children}
        </DndProvider>
    )
}