import styled from "@emotion/styled";
import { BorderRadius, FontSize, Padding } from "../../../../../../../../common/constants/constants";
import { css } from "@emotion/react";

export const IconContainer = styled.div({
    display: 'flex',
    paddingTop: 2,
})

export const LinkStyles = styled.a<{
    isActive: boolean
    isCollapsed: boolean
}>(({
    isActive,
    isCollapsed,
}) => ({
    // layout
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isCollapsed ? 'center' : undefined,
    gap: 8,

    // background
    backgroundColor: isActive ? '#20222b' : undefined,

    // text
    color: isActive ? 'white' : 'rgba(255, 255, 255, 0.65)',
    fontSize: isCollapsed ? 18 : FontSize.default,

    // animation
    transition: 'color 300ms ease',

    // spacing
    padding: isCollapsed ? 18 : `${Padding.default}px ${Padding.default}px`,

    // border
    borderRadius: BorderRadius.less,
    
    // hover
    '&:hover': {
        color: 'white',
    },
}))

export const DefaultLinkStyles = css({
        // layout
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
    
        // text
        color: 'rgba(255, 255, 255, 0.65)',
        fontSize: FontSize.default,
    
        // animation
        transition: 'color 300ms ease',
    
        // spacing
        padding: `${Padding.default}px ${Padding.default}px`,
    
        // border
        borderRadius: BorderRadius.less,
        
        // hover
        '&:hover': {
            color: 'white',
        },
})

export const CollapsedLinkStyles = css({
    // layout
    justifyContent: 'center',

    // text
    fontSize: 18,

    // spacing
    padding: 18,
})

export const ActiveLinkStyles = css({
    // background
    backgroundColor: '#20222b',

    // text
    color: 'white',
})

export const buildLinkStyles = ({
    isActive,
    isCollapsed,
}: { isActive: boolean, isCollapsed: boolean }) => {
    const styles = [DefaultLinkStyles];
    if (isActive) styles.push(ActiveLinkStyles);
    if (isCollapsed) styles.push(CollapsedLinkStyles);
    return styles;
}