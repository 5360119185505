import { ResetPasswordPayload, ResetPasswordResult, ValidateResetPasswordRequestResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class ResetPasswordService {
    public async validateToken(token: string): Promise<ValidateResetPasswordRequestResult> {
        let result: ValidateResetPasswordRequestResult | undefined = undefined;

        try {
            const decodedToken = Buffer.from(token, 'base64').toString()
            result = await api.auth.validateResetPasswordRequest({
                token: decodedToken,
            });
        } catch {}

        if (!result) {
            result = new ValidateResetPasswordRequestResult();
            result.success = false
            result.errorCode = 'unknown'
            result.errorMessage = 'An unknown error occurred. Please try again.'
        }

        return result;
    }

    public async resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResult> {
        let result: ResetPasswordResult | undefined = undefined;

        try {
            const decodedToken = Buffer.from(payload.token, 'base64').toString()
            result = await api.auth.resetPassword({
                ...payload,
                token: decodedToken,
            });
        } catch {}

        if (!result) {
            result = new ResetPasswordResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred. Please try again'
        }

        return result;
    }
}

export const service = new ResetPasswordService();