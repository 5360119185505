import styled from "@emotion/styled";
import { Colors } from "../../../../../../../../../../common/colors/colors";

export const MenuHeader = styled.div({
    /* layout */
    display: 'grid',
    justifyContent: 'center',

    /* padding */
    paddingTop: 24,
    paddingBottom: 24,

    /* border */
    borderBottom: `1px solid ${Colors.border}`,
})

export const LogoImage = styled.img({
    /* size */
    width: 200,

    /* appearance */
    opacity: 0.85,
})

export const OrganizationSectionContainer = styled.div({
    /* padding */
    padding: 12,
    paddingBottom: 8,

    /* border */
    borderTop: `1px solid ${Colors.border}`,
})