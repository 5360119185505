import { FC, useEffect, useMemo } from 'react'
import { useOrganizationMemberView_OrganizationMemberQuery } from './OrganizationMemberView.generated'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from 'react-router-dom'
import { FormFieldAccordionPanelConfig, FormFieldsAccordion } from '../../../common/form-fields-accordion/FormFieldsAccordion'
import { Loading } from '../../../common/loading/Loading'
import { ViewContainer } from '../../../common/view-container/ViewContainer'
import { OrganizationMemberRemoveMemberFormProps, OrganizationMemberRemoveMemberForm } from '../../../organization-members/organization-member-remove-user-form/organization-member-remove-user-form'
import { UserAvatar } from '../../../users/user-avatar/UserAvatar'
import { PreferencesGeneralLayout } from '../../common/preferences-general-layout/PreferencesGeneralLayout'
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

type OrganizationMemberViewProps = {
	organizationId: string
	organizationMemberId: string
}

export const OrganizationMemberView: FC<OrganizationMemberViewProps> = ({
	organizationId,
	organizationMemberId,
}) => {

	// router hooks
	const navigate = useNavigate()

	// data
	const { data: organizationMembers, isLoading } = useOrganizationMemberView_OrganizationMemberQuery({
		organizationId,
		organizationMemberId,
	})

	// data abstraction
	const organizationMember = useMemo(() => (
		organizationMembers?.organizationMembersCollection?.edges[0]?.node
	), [organizationMembers?.organizationMembersCollection?.edges])

	const user = useMemo(() => (
		organizationMember?.user
	), [organizationMember?.user])

	// panels
	const userRolePanel: FormFieldAccordionPanelConfig<{}> | undefined = useMemo(() => {
		if (!organizationMember) return;
		return {
			id: 'role',
			label: 'User Role',
			icon: <FontAwesomeIcon icon={faTag} />,
			description: 'This feature will be available soon',
			component: () => (<div>This feature will be available soon</div>),
			props: {
				organizationMember,
			},
		}
	}, [organizationMember])

	const removeUserPanel: FormFieldAccordionPanelConfig<OrganizationMemberRemoveMemberFormProps> = useMemo(() => {
		return {
			id: 'remove-user',
			label: 'Remove User',
			icon: <FontAwesomeIcon icon={faTimes} />,
			description: 'Remove user from organization',
			component: OrganizationMemberRemoveMemberForm,
			props: {
				organizationMember: organizationMember!,
			},
		}
	}, [organizationMember])

	const panels = useMemo(() => ([
		userRolePanel,
		removeUserPanel,
	].filter(panel => !!panel) as FormFieldAccordionPanelConfig<any>[]),
		[removeUserPanel, userRolePanel]
	)

	// side effects
	useEffect(() => {
		if (!isLoading && !organizationMember) {
			navigate('..')
		}
	}, [isLoading, navigate, organizationMember])

	if (!organizationMember || !user) {
		return <Loading />
	}

	return (
		<ViewContainer>
			<PreferencesGeneralLayout
				title={`${user.firstName} ${user.lastName}`}
				subtitle={`Manage settings for how this user can work in your enterprise`}
				icon={(
					<UserAvatar
						user={user}
						size={56}
					/>
				)}
			>
				<FormFieldsAccordion
					title="User Settings"
					description="Manage settings for how this user can work in your enterprise"
					fieldPanels={panels}
				/>
			</PreferencesGeneralLayout>
		</ViewContainer>
	)
}