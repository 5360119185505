import styled from "@emotion/styled";
import { Gap, Padding } from "../../../common/constants/constants";

export const TabBarContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoColumns: 'max-content',
    gridAutoFlow: 'column',

    /* padding */
    paddingTop: Padding.least,
})

export const TabBarContainerInner = styled.div({
    /* layout */
    display: 'flex',
    flexDirection: 'row',
    gap: Gap.most,
})