import { FC, useCallback, useEffect, useState } from "react";
import { TabBarLink } from "../../tab-bar.types";
import { NavLink } from "react-router-dom";
import { ClassNames } from "@emotion/react";
import { useTabBarStyles } from "./tab-bar-button.hooks";
import Tooltip from "antd/es/tooltip";
import { TooltipStyles } from "./tab-bar-button.styled";

type TabBarButtonProps = {
    link: TabBarLink
    showTips: boolean
}

export const TabBarButton: FC<TabBarButtonProps> = ({
    link,
    showTips,
}) => {

    // custom hooks
    const tabBarStyles = useTabBarStyles(link)
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

    // side effects
    useEffect(() => {
        if (showTips) return;
        setTooltipOpen(false)
    }, [showTips])

    // event handlers
    const handleClick = useCallback(() => {
        setTooltipOpen(false)
    }, [])

    return (
        <ClassNames>
            {({ css }) => (
                <Tooltip
                    title={link.tip}
                    placement="bottomLeft"
                    showArrow={false}
                    overlayClassName={css(TooltipStyles)}
                    open={tooltipOpen && showTips}
                    onOpenChange={setTooltipOpen}
                >
                    <NavLink
                        to={link.to}
                        className={css(tabBarStyles)}
                        onClick={handleClick}
                    >
                        {link.label}
                    </NavLink>
                </Tooltip>
            )}
        </ClassNames>
    )
}