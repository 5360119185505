import { FC } from "react";
import { Outlet } from "react-router-dom";
import { PlatformProviders } from "../../modules/platform/platform-providers";

export const PlatformRoute: FC = () => {
    return (
        <PlatformProviders>
            <Outlet />
        </PlatformProviders>
    )
}