/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type UpdateUserProfileForm_UserFragment = { id: any, firstName: string | null, lastName: string | null };

export const UpdateUserProfileForm_UserFragmentDoc = `
    fragment UpdateUserProfileForm_User on Users {
  id
  firstName
  lastName
}
    `;