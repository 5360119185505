import React, { FC, useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate, useParams } from 'react-router-dom'
import { OrganizationMembersList_OrganizationFragment, OrganizationMembersList_OrganizationMemberFragment, OrganizationMembersList_OrganizationMemberInvitationFragment } from './OrganizationMembersList.generated'
import { Gap } from '../../../../../../common/constants/constants'
import { EditOrganizationMemberInvitationModal } from '../../../../../organization-member-invitations/edit-organization-member-invitation-modal/edit-organization-member-invitation-modal'
import { OrganizationMemberInvitationGridItem } from '../../../../../organization-member-invitations/organization-member-invitation-grid-item/OrganizationMemberInvitationGridItem'
import { OrganizationMemberGridItem } from '../../../../../organization-members/organization-member-grid-item/organization-member-grid-item'

type OrganizationMembersListProps = {
	organization: OrganizationMembersList_OrganizationFragment
}

// TODO: remove useParams from this somehow...
export const OrganizationMembersList: FC<OrganizationMembersListProps> = ({
	organization,
}) => {

	// styles
	const styles = useStyles()

	// router hooks
	const navigate = useNavigate()
	const { organization_member_id } = useParams() as { organization_member_id: string | undefined }

	// constants
	const organizationMembers = useMemo(() => (
		organization.organizationMembersList_organization_organizationMembersCollection?.edges
			.map(edge => edge?.node as NonNullable<typeof edge.node>)
			.filter(node => !!node)
			.sort((a, b) => (b.user!.firstName!.localeCompare(a.user!.lastName!)))
	), [organization.organizationMembersList_organization_organizationMembersCollection?.edges])

	const organizationMemberInvitations = useMemo(() => (
		organization.organizationMembersList_organization_organizationMemberInvitationsCollection?.edges
			.map(edge => edge?.node as NonNullable<typeof edge.node>)
			.filter(node => !!node)
			.sort((a, b) => (b.invitedUserEmailAddress!.localeCompare(a.invitedUserEmailAddress!)))
	), [organization.organizationMembersList_organization_organizationMemberInvitationsCollection?.edges])

	// state
	const [editingInvitationDialogOpen, setEditingInvitationDialogOpen] = useState<boolean>(false)
	const [editingOrganizationMemberInvitation, setEditingOrganizationMemberInvitation] = useState<OrganizationMembersList_OrganizationMemberInvitationFragment>()

	// event handlers
	const handleOrganizationMemberGridItemClick = useCallback((userProfile: OrganizationMembersList_OrganizationMemberFragment) => {
		navigate(userProfile.id)
	}, [navigate])

	const handleInvitationGridItemClick = useCallback((organizationMemberInvitation: OrganizationMembersList_OrganizationMemberInvitationFragment) => {
		setEditingInvitationDialogOpen(true)
		setEditingOrganizationMemberInvitation(organizationMemberInvitation)
	}, [])

	const handleEditOrganizationMemberDialogDismiss = useCallback(() => {
		setEditingInvitationDialogOpen(false)
		setTimeout(() => setEditingOrganizationMemberInvitation(undefined), 500)
	}, [])

	return (
		<div className={styles.enterpriseUsersList}>
			{organizationMembers?.map(organizationMember => (
				<OrganizationMemberGridItem
					key={organizationMember.id}
					organizationMember={organizationMember}
					onClick={() => handleOrganizationMemberGridItemClick(organizationMember)}
					isActive={organization_member_id === organizationMember.id}
				/>
			))}
			{organizationMemberInvitations?.map(organizationMemberInvitation => (
				<OrganizationMemberInvitationGridItem
					key={organizationMemberInvitation.id}
					organizationMemberInvitation={organizationMemberInvitation}
					onClick={() => handleInvitationGridItemClick(organizationMemberInvitation)}
				/>
			))}
			{editingOrganizationMemberInvitation && (
				<EditOrganizationMemberInvitationModal
					open={editingInvitationDialogOpen}
					organizationMemberInvitation={editingOrganizationMemberInvitation}
					onDismiss={handleEditOrganizationMemberDialogDismiss}
				/>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	enterpriseUsersList: {
		display: 'grid',
		gap: Gap.default,
		gridTemplateRows: 'max-content',
		gridAutoRows: 'max-content',
	}
})