/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type SelfProvider_GetSelfUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type SelfProvider_GetSelfUserQuery = { usersCollection: { edges: Array<{ node: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null, selfProvider_selfUser_administratorsCollection: { edges: Array<{ node: { id: any } }> } | null } }> } | null };

export type SelfProvider_SelfUserFragment = { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null, selfProvider_selfUser_administratorsCollection: { edges: Array<{ node: { id: any } }> } | null };

export type SelfProvider_OrganizationMembersQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type SelfProvider_OrganizationMembersQuery = { organizationMembersCollection: { edges: Array<{ node: { id: any, organization: { id: any } | null } }> } | null };

export const SelfProvider_SelfUserFragmentDoc = `
    fragment SelfProvider_SelfUser on Users {
  id
  firstName
  lastName
  emailAddress
  selfProvider_selfUser_administratorsCollection: administratorsCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const SelfProvider_GetSelfUserDocument = `
    query SelfProvider_GetSelfUser($userId: UUID!) {
  usersCollection(filter: {id: {eq: $userId}, isDeleted: {eq: false}}) {
    edges {
      node {
        ...SelfProvider_SelfUser
      }
    }
  }
}
    ${SelfProvider_SelfUserFragmentDoc}`;
export const useSelfProvider_GetSelfUserQuery = <
      TData = SelfProvider_GetSelfUserQuery,
      TError = unknown
    >(
      variables: SelfProvider_GetSelfUserQueryVariables,
      options?: UseQueryOptions<SelfProvider_GetSelfUserQuery, TError, TData>
    ) =>
    useQuery<SelfProvider_GetSelfUserQuery, TError, TData>(
      ['SelfProvider_GetSelfUser', variables],
      fetcher<SelfProvider_GetSelfUserQuery, SelfProvider_GetSelfUserQueryVariables>(SelfProvider_GetSelfUserDocument, variables),
      options
    );

useSelfProvider_GetSelfUserQuery.getKey = (variables: SelfProvider_GetSelfUserQueryVariables) => ['SelfProvider_GetSelfUser', variables];
;

export const useInfiniteSelfProvider_GetSelfUserQuery = <
      TData = SelfProvider_GetSelfUserQuery,
      TError = unknown
    >(
      pageParamKey: keyof SelfProvider_GetSelfUserQueryVariables,
      variables: SelfProvider_GetSelfUserQueryVariables,
      options?: UseInfiniteQueryOptions<SelfProvider_GetSelfUserQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SelfProvider_GetSelfUserQuery, TError, TData>(
      ['SelfProvider_GetSelfUser.infinite', variables],
      (metaData) => fetcher<SelfProvider_GetSelfUserQuery, SelfProvider_GetSelfUserQueryVariables>(SelfProvider_GetSelfUserDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSelfProvider_GetSelfUserQuery.getKey = (variables: SelfProvider_GetSelfUserQueryVariables) => ['SelfProvider_GetSelfUser.infinite', variables];
;

useSelfProvider_GetSelfUserQuery.fetcher = (variables: SelfProvider_GetSelfUserQueryVariables, options?: RequestInit['headers']) => fetcher<SelfProvider_GetSelfUserQuery, SelfProvider_GetSelfUserQueryVariables>(SelfProvider_GetSelfUserDocument, variables, options);
export const SelfProvider_OrganizationMembersDocument = `
    query SelfProvider_OrganizationMembers($userId: UUID!) {
  organizationMembersCollection(
    filter: {userId: {eq: $userId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        organization {
          id
        }
      }
    }
  }
}
    `;
export const useSelfProvider_OrganizationMembersQuery = <
      TData = SelfProvider_OrganizationMembersQuery,
      TError = unknown
    >(
      variables: SelfProvider_OrganizationMembersQueryVariables,
      options?: UseQueryOptions<SelfProvider_OrganizationMembersQuery, TError, TData>
    ) =>
    useQuery<SelfProvider_OrganizationMembersQuery, TError, TData>(
      ['SelfProvider_OrganizationMembers', variables],
      fetcher<SelfProvider_OrganizationMembersQuery, SelfProvider_OrganizationMembersQueryVariables>(SelfProvider_OrganizationMembersDocument, variables),
      options
    );

useSelfProvider_OrganizationMembersQuery.getKey = (variables: SelfProvider_OrganizationMembersQueryVariables) => ['SelfProvider_OrganizationMembers', variables];
;

export const useInfiniteSelfProvider_OrganizationMembersQuery = <
      TData = SelfProvider_OrganizationMembersQuery,
      TError = unknown
    >(
      pageParamKey: keyof SelfProvider_OrganizationMembersQueryVariables,
      variables: SelfProvider_OrganizationMembersQueryVariables,
      options?: UseInfiniteQueryOptions<SelfProvider_OrganizationMembersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<SelfProvider_OrganizationMembersQuery, TError, TData>(
      ['SelfProvider_OrganizationMembers.infinite', variables],
      (metaData) => fetcher<SelfProvider_OrganizationMembersQuery, SelfProvider_OrganizationMembersQueryVariables>(SelfProvider_OrganizationMembersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteSelfProvider_OrganizationMembersQuery.getKey = (variables: SelfProvider_OrganizationMembersQueryVariables) => ['SelfProvider_OrganizationMembers.infinite', variables];
;

useSelfProvider_OrganizationMembersQuery.fetcher = (variables: SelfProvider_OrganizationMembersQueryVariables, options?: RequestInit['headers']) => fetcher<SelfProvider_OrganizationMembersQuery, SelfProvider_OrganizationMembersQueryVariables>(SelfProvider_OrganizationMembersDocument, variables, options);