import React, { FC, Suspense, lazy } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Loading } from '../../../../../../../modules/common/loading/Loading'

const SchedulesView = lazy(() => import('../../../../../../../modules/schedules/views/schedules-view/SchedulesView'))

export const SchedulesRoute: FC = () => {

	// router hooks
	const { constellation_id, schedule_id } = useParams() as { constellation_id: string, schedule_id?: string }

	return (
		<Suspense fallback={<Loading />}>
			<SchedulesView
				constellationId={constellation_id}
				scheduleId={schedule_id}
			>
				<Outlet />
			</SchedulesView>
		</Suspense>
	)
}