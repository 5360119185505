export const ALGOLIA_APP_ID = 'BXXNAJARCY'

export const Padding = {
    least: 4,
    less: 6,
    default: 8,
    more: 10,
    most: 12,
}

export const Gap = {
    least: 4,
    less: 6,
    default: 8,
    more: 10,
    most: 12,
}

export const BorderRadius = {
    least: 4,
    less: 6,
    default: 8,
    more: 10,
    most: 12,
}

export const FontSize = {
    default: 12,
    bigger: 13,
}