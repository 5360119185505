import styled from "@emotion/styled";

export const EmptyContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    paddingTop: 48,
    marginLeft: 'auto',
    marginRight: 'auto',
})

export const ButtonContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 12,
})