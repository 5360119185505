import { blue } from '@ant-design/colors'
import Avatar from 'antd/es/avatar'
import React, { FC } from 'react'
import { UserAvatar_UserFragment } from './UserAvatar.generated'

type UserAvatarProps = {
    user: UserAvatar_UserFragment
    size?: number
}

export const UserAvatar: FC<UserAvatarProps> = ({
    user,
    size,
}) => {

    // hooks
    const userInitials = (user.firstName?.charAt(0).toUpperCase() ?? '') + (user.lastName?.charAt(0).toUpperCase() ?? '')

    return (
        <Avatar style={{ backgroundColor: blue.primary }} size={size}>{userInitials}</Avatar>
    )
}