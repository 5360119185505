import { FC, ReactNode, createContext, useCallback, useContext, useState } from "react";
import { HoverIndicator, ClearGroupParentDragReceiverContainer } from "./clear-group-parent-drag-receiver.styled";
import { useQueryClient } from "@tanstack/react-query";
import { SelfContext } from "../../../app/providers/self-provider/SelfProvider";
import { service } from "./clear-group-parent-drag-receiver.service";
import Spin from "antd/es/spin";
import message from "antd/es/message";
import { queryInvalidationHelper } from "../../../../common/react-query/query-invalidation.helper";
import { useDrop } from "react-dnd";
import { useStateWithRef } from "../../../../common/utils/use-state-with-ref";

type ClearGroupParentDragReceiverProps = {
    organizationId: string
    children: ReactNode
}

export const ClearGroupParentDragReceiver: FC<ClearGroupParentDragReceiverProps> = ({
    organizationId,
    children,
}) => {

    // react query
    const queryClient = useQueryClient()

    // context
    const { selfUser } = useContext(SelfContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [activeHoverGroupId, setActiveHoverGroupId, activeHoverGroupIdRef] = useStateWithRef<string | null>(null)
    
    // event handlers
    const handleGroupDrop = useCallback(async (item: { id: string, organizationId: string }) => {
        /* start the loading state */
        setIsLoading(true)

        /* update the group parent */
        const result = await service.removeGroupParent(
            item.id,
        );

        /* handle any errors that occurred */
        if (!result.success || !result.group) {
            message.error(`Something went wrong. Please try again.`)
        } else {
            message.success(`Successfully removed group from group`)
        }

        /* invalidate relevant queries */
        queryClient.invalidateQueries(
            queryInvalidationHelper([
                { userId: selfUser?.id },
            ])
        )

        /* clear the loading state */
        setIsLoading(false)
    }, [queryClient, selfUser?.id])

    const handleConstellationDrop = useCallback(async (item: { id: string, groupId: string, organizationId: string }) => {
        /* start the loading state */
        setIsLoading(true)

        /* update the group parent */
        const result = await service.removeConstellationFromGroup(
            item.id,
            item.groupId,
        );

        /* handle any errors that occurred */
        if (!result.success) {
            message.error(`Something went wrong. Please try again.`)
        } else {
            message.success(`Successfully removed constellation from group.`)
        }

        /* invalidate relevant queries */
        queryClient.invalidateQueries(
            queryInvalidationHelper([
                { userId: selfUser?.id },
            ])
        )

        /* clear the loading state */
        setIsLoading(false)
    }, [queryClient, selfUser?.id])

    // drag and drop
    const [{ isOver }, drop] = useDrop({
        accept: ['group', 'constellation'],
        collect: monitor => ({
            isOver: monitor.isOver() && monitor.canDrop(),
            isOverNoOp: monitor.isOver() && !monitor.canDrop(),
        }),
        canDrop: (item, monitor) => {
            if ((item as { organizationId: string }).organizationId !== organizationId) {
                return false;
            }

            const itemType = monitor.getItemType();
            if (itemType === 'constellation' && !(item as { groupId?: string }).groupId) {
                return false;
            }

            return !activeHoverGroupIdRef.current
        },
        drop: (item, monitor) => {
            if (!monitor.canDrop()) return;

            const itemType = monitor.getItemType();
            if (itemType === 'group') {
                handleGroupDrop(item as { id: string, organizationId: string })
            } else if (itemType === 'constellation') {
                handleConstellationDrop(
                    item as { id: string, groupId: string, organizationId: string }
                )
            }
        },
    })

    if (isLoading) {
        return (
            <Spin>
                {children}
            </Spin>
        )
    }

    return (
        <ClearGroupParentDragReceiverContext.Provider value={{
            activeHoverGroupId,
            onActiveHoverGroupIdChange: setActiveHoverGroupId,
        }}>
            <ClearGroupParentDragReceiverContainer ref={drop}>
                {children}
                <HoverIndicator isOver={isOver} />
            </ClearGroupParentDragReceiverContainer>
        </ClearGroupParentDragReceiverContext.Provider>
    )
}

type ClearGroupParentDragReceiverContextType = {
    activeHoverGroupId: string | null
    onActiveHoverGroupIdChange: React.Dispatch<React.SetStateAction<string | null>>
}

export const ClearGroupParentDragReceiverContext = createContext<ClearGroupParentDragReceiverContextType>({
    activeHoverGroupId: null,
    onActiveHoverGroupIdChange: () => {},
})