/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { UserAvatar_UserFragmentDoc } from '../../../../../users/user-avatar/UserAvatar.generated';
import { UpdateUserProfileForm_UserFragmentDoc } from '../../../../../users/update-user-profile-form/update-user-profile-form.generated';
export type UserProfileView_UserFragment = { id: any, firstName: string | null, lastName: string | null };

export const UserProfileView_UserFragmentDoc = `
    fragment UserProfileView_User on Users {
  id
  ...UserAvatar_User
  ...UpdateUserProfileForm_User
}
    ${UserAvatar_UserFragmentDoc}
${UpdateUserProfileForm_UserFragmentDoc}`;