import Empty from 'antd/es/empty'
import { FC, Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom'
import { EmptyStateContainer } from '../../../../../../../../../modules/common/empty-state-container/EmptyStateContainer'
import { Loading } from '../../../../../../../../../modules/common/loading/Loading'

// lazy load the schedule view (for moment-timezone)
const ScheduleView = lazy(() => import('../../../../../../../../../modules/schedules/schedule-view/ScheduleView'))

type ScheduleRouteProps = {}

export const ScheduleRoute: FC<ScheduleRouteProps> = () => {

	// router hooks
	const { schedule_id: scheduleId } = useParams() as { schedule_id: string }

	return (
		<Suspense fallback={<Loading />}>
			<ScheduleView
				scheduleId={scheduleId}
			/>
		</Suspense>
	)
}

export const ScheduleEmptyState: FC = () => {
	return (
		<EmptyStateContainer>
			<Empty
				description="Choose or create an automation to edit it here"
			/>
		</EmptyStateContainer>
	)
}