import { FC } from "react";
import { AuthenticateShellContainer, ContentContainer } from "./authenticate-shell.styled";
import { Billboard } from "./subcomponents/billboard/billboard";
import { Copyright } from "./subcomponents/copyright/copyright";
import { Logo } from "./subcomponents/logo/logo";

type AuthenticateShellProps = {
    children: React.ReactNode
}

export const AuthenticateShell: FC<AuthenticateShellProps> = ({
    children,
}) => {
    return (
        <AuthenticateShellContainer>
            <ContentContainer>
                <Logo />
                {children}
                <Copyright />
            </ContentContainer>
            <Billboard />
        </AuthenticateShellContainer>
    )
}