/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

export type DownloadNovaMenuItem_NovaFragment = { id: any, name: string, embeddedLink: string | null };

export const DownloadNovaMenuItem_NovaFragmentDoc = `
    fragment DownloadNovaMenuItem_Nova on Novas {
  id
  name
  embeddedLink
}
    `;