import { createContext, Dispatch, SetStateAction } from "react"

type DialogsContextType = {
    currentDialogs: string[]
    setCurrentDialogs: Dispatch<SetStateAction<string[]>>
}

export const DialogsContext = createContext<DialogsContextType>({
    currentDialogs: [],
    setCurrentDialogs: () => {},
})