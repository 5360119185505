import Tooltip from 'antd/es/tooltip'
import React, { FC, useCallback, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { AppShellContext } from '../../../../../app/components/app-shell/app-shell-context'
import { AppMenu } from '../../../../../app/components/app-shell/subcomponents/app-menu/app-menu'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TABLET_BREAKPOINT } from '../../../../../common/constants/breakpoints/breakpoints'
import { PreferencesNavSections } from './subcomponents/preferences-nav-sections/preferences-nav-sections'
import { faCircleCaretRight } from '@fortawesome/pro-solid-svg-icons/faCircleCaretRight'
import { faCircleCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCircleCaretLeft'

type PreferencesSideBarProps = {}

export const PreferencesSideBar: FC<PreferencesSideBarProps> = () => {

	// hooks
	const styles = useStyles()
	const { sidebarCollapsed, onSidebarCollapsedChange } = useContext(AppShellContext)

	// event handlers
	const handleCollapseSidebarClick = useCallback(() => {
		onSidebarCollapsedChange(!sidebarCollapsed)
	}, [onSidebarCollapsedChange, sidebarCollapsed])

	return (
		<div className={`${styles.sider} ${sidebarCollapsed && styles.collapsed}`}>
			<AppMenu />
			<div className={styles.navSections}>
				<PreferencesNavSections />
			</div>
			{sidebarCollapsed ? (
				<Tooltip title="Expand Sidebar" placement="right">
					<div className={`${styles.collapseSidebarButtonContainer} ${sidebarCollapsed && styles.sidebarCollapsed}`} onClick={handleCollapseSidebarClick}>
						<FontAwesomeIcon icon={faCircleCaretRight} />
					</div>
				</Tooltip>
			) : (
				<div className={`${styles.collapseSidebarButtonContainer} ${sidebarCollapsed && styles.sidebarCollapsed}`} onClick={handleCollapseSidebarClick}>
					<FontAwesomeIcon icon={faCircleCaretLeft} />
					<div>
						Collapse Sidebar
					</div>
				</div>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	sider: {
		backgroundColor: '#0f0f0f',
		display: 'grid',
		paddingBottom: 12,
		gridTemplateRows: '64px 1fr max-content',
	},
    [TABLET_BREAKPOINT]: {
        sider: {
            display: 'none',
        }
    },
	collapseSidebarButtonContainer: {
		padding: 12,
		paddingLeft: 24,
		display: 'grid',
		gridTemplateColumns: 'max-content max-content',
		alignItems: 'center',
		gap: 6,
		cursor: 'pointer',
		opacity: 0.6,
		transition: 'opacity 200ms ease',
		alignSelf: 'end',
		userSelect: 'none',
		'&:hover': {
			opacity: 1,
		}
	},
	collapsed: {
		justifyItems: 'center',
	},
	sidebarCollapsed: {
		fontSize: 24,
		justifyContent: 'center',
	},
	navSections: {
		display: 'grid',
		gap: 12,
		gridAutoRows: 'max-content',
	},
})