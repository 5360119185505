import { InfiniteData } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { TypesProvider_TypesQuery } from "./TypesProvider.generated";

export const useNodes = <
    K extends keyof TypesProvider_TypesQuery,
    NodeType extends Exclude<TypesProvider_TypesQuery[K], null>['edges'][0]['node'],
>(
    data: InfiniteData<TypesProvider_TypesQuery> | undefined,
    key: K,
): NodeType[] | null => (
    useMemo(() => {
        const nodes = data?.pages
            .flatMap(page => (
                page[key]?.edges
            ))
            .filter(edge => !!edge?.node)
            .map(edge => edge as Exclude<typeof edge, undefined>)
            .map(edge => edge?.node as NodeType)

        return nodes ?? null
    }, [data?.pages, key])
)

export const useFetchAllPages = ({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
}: {
    fetchNextPage: () => void,
    hasNextPage: boolean | undefined,
    isFetchingNextPage: boolean,
}) => (
    useEffect(() => {
        if (!hasNextPage) return;
        if (isFetchingNextPage) return;
        fetchNextPage()
    }, [fetchNextPage, hasNextPage, isFetchingNextPage])
)