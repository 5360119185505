import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { OrganizationPreferencesView } from "../../../../../../modules/preferences/views/organization-preferences-view/organization-preferences-view";

export const OrganizationPreferencesRoute: FC = () => {

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string
    }

    return (
        <OrganizationPreferencesView organizationId={organizationId}>
            <Outlet />
        </OrganizationPreferencesView>
    )
}