import styled from "@emotion/styled";
import { Gap } from "../../../common/constants/constants";
import { MOBILE_BREAKPOINT } from "../constants/breakpoints/breakpoints";

export const ViewBarSection = styled.div<{
    hasTabBar: boolean
}>(({
    hasTabBar,
}) => ({
    /* layout */
    display: 'grid',
    gridAutoFlow: 'row',

    /* padding */
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 0,

    /* breakpoints */
    [MOBILE_BREAKPOINT]: {
        paddingBottom: 16,
    },
}))

export const ViewBarContainer = styled.div<{
    hasTabBar: boolean
    hasIcon: boolean
}>(({
    hasTabBar,
    hasIcon,
}) => ({
    /* layout */
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: hasIcon ? '50px 1fr' : '1fr',
    gap: 12,

    /* padding */
    paddingTop: 12,
    paddingBottom: hasTabBar ? 0 : 16,

    /* breakpoints */
    [MOBILE_BREAKPOINT]: {
        paddingTop: 0,
        paddingBottom: hasTabBar ? 16 : 0,
    },
}))

export const BarInnerContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 12,

    /* breakpoints */
    [MOBILE_BREAKPOINT]: {
        alignItems: 'center',
    },
})

export const IconContainer = styled.div({
    fontSize: 18,
    display: 'grid',
})

export const NavigationContainer = styled.div({
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
    gap: 4,
})

export const ViewName = styled.span({
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '1.6rem',
})

export const ActionsContainer = styled.div({
    /* layout */
    display: 'flex',
    flexDirection: 'row',
    gap: Gap.default,
    alignItems: 'center',
    justifySelf: 'flex-end',
})