/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { EditGroupAccessDialog_GroupFragmentDoc } from '../../../../modals/edit-group-access-dialog/EditGroupAccessDialog.generated';
export type ManageAccessToGroupMenuItem_GroupFragment = { id: any, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const ManageAccessToGroupMenuItem_GroupFragmentDoc = `
    fragment ManageAccessToGroupMenuItem_Group on Groups {
  id
  ...EditGroupAccessDialog_Group
}
    ${EditGroupAccessDialog_GroupFragmentDoc}`;