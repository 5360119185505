import uniq from "lodash/uniq";
import { GroupUtils_GroupFragment } from "./group.utils.generated";

export const buildGroupUrl = (allGroups: GroupUtils_GroupFragment[], group: GroupUtils_GroupFragment, admin?: boolean): string => {
    let basePath = `/organizations/${group.organizationId}`;

    // add parent groups path
    const groupIds = captureParentGroupIds(allGroups, group);
    for (const groupId of groupIds) {
        basePath += `/groups/${groupId}`;
    }

    if (admin) return `/admin${basePath}`;

    return basePath;
}

export const captureAllNestedChildrenGroupIds = (
    allGroups: GroupUtils_GroupFragment[],
    parentGroupId: string,
    allChildrenGroupIds: string[] = []
): string[] => {
    const childrenGroups = allGroups.filter(group => group.parentGroupId === parentGroupId);
    const newChildrenGroupIds = [...allChildrenGroupIds, ...childrenGroups.map(group => group.id)] as string[];
    if (!childrenGroups.length) return newChildrenGroupIds;
    return uniq(childrenGroups.flatMap(group => captureAllNestedChildrenGroupIds(allGroups, group.id, newChildrenGroupIds)))
}

export const captureParentGroupIds = (
    allGroups: GroupUtils_GroupFragment[],
    group: GroupUtils_GroupFragment,
    allParentIds: string[] = []
): string[] => {
    const newAllParentIds = [group.id, ...allParentIds];
    if (!group.parentGroupId) return newAllParentIds;
    const parentGroup = allGroups.find(parentGroup => parentGroup.id === group.parentGroupId);
    if (!parentGroup) return newAllParentIds;
    return captureParentGroupIds(allGroups, parentGroup, newAllParentIds);
}