import { useQuery } from "@tanstack/react-query";
import { service } from "./organization-provider.service";

export const useSubscriptionDetails = (organizationId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: [{ organizationId }],
    queryFn: () =>
      organizationId
        ? service.getSubscriptionDetails(organizationId)
        : undefined,
    enabled: !!organizationId,
  });

  // data abstraction
  const subscriptions = data?.subscriptions;
  const activeEntitlements = data?.activeEntitlements;

  return {
    isLoading,
    subscriptions,
    activeEntitlements,
  };
};
