/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { OrganizationNamePanel_OrganizationFragmentDoc } from '../../panels/organization-name-panel/organization-name-panel.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type OrganizationSettingsView_OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type OrganizationSettingsView_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { name: string, id: any, organizationType: { code: string, name: string } | null } }> } | null };

export type OrganizationSettingsView_OrganizationFragment = { name: string, id: any, organizationType: { code: string, name: string } | null };

export const OrganizationSettingsView_OrganizationFragmentDoc = `
    fragment OrganizationSettingsView_Organization on Organizations {
  name
  organizationType {
    code
    name
  }
  ...OrganizationNamePanel_Organization
}
    ${OrganizationNamePanel_OrganizationFragmentDoc}`;
export const OrganizationSettingsView_OrganizationDocument = `
    query OrganizationSettingsView_Organization($organizationId: UUID!) {
  organizationsCollection(
    filter: {id: {eq: $organizationId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        ...OrganizationSettingsView_Organization
      }
    }
  }
}
    ${OrganizationSettingsView_OrganizationFragmentDoc}`;
export const useOrganizationSettingsView_OrganizationQuery = <
      TData = OrganizationSettingsView_OrganizationQuery,
      TError = unknown
    >(
      variables: OrganizationSettingsView_OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationSettingsView_OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationSettingsView_OrganizationQuery, TError, TData>(
      ['OrganizationSettingsView_Organization', variables],
      fetcher<OrganizationSettingsView_OrganizationQuery, OrganizationSettingsView_OrganizationQueryVariables>(OrganizationSettingsView_OrganizationDocument, variables),
      options
    );

useOrganizationSettingsView_OrganizationQuery.getKey = (variables: OrganizationSettingsView_OrganizationQueryVariables) => ['OrganizationSettingsView_Organization', variables];
;

export const useInfiniteOrganizationSettingsView_OrganizationQuery = <
      TData = OrganizationSettingsView_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationSettingsView_OrganizationQueryVariables,
      variables: OrganizationSettingsView_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationSettingsView_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationSettingsView_OrganizationQuery, TError, TData>(
      ['OrganizationSettingsView_Organization.infinite', variables],
      (metaData) => fetcher<OrganizationSettingsView_OrganizationQuery, OrganizationSettingsView_OrganizationQueryVariables>(OrganizationSettingsView_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationSettingsView_OrganizationQuery.getKey = (variables: OrganizationSettingsView_OrganizationQueryVariables) => ['OrganizationSettingsView_Organization.infinite', variables];
;

useOrganizationSettingsView_OrganizationQuery.fetcher = (variables: OrganizationSettingsView_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<OrganizationSettingsView_OrganizationQuery, OrganizationSettingsView_OrganizationQueryVariables>(OrganizationSettingsView_OrganizationDocument, variables, options);