/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { GroupUtils_GroupFragmentDoc } from '../../../groups/common/utils/group.utils.generated';
import { GroupNavButton_GroupFragmentDoc } from '../../../app/components/app-shell/subcomponents/app-side-bar/subcomponents/group-nav-button/group-nav-button.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type OrganizationProvider_OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type OrganizationProvider_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { groupsCollection: { edges: Array<{ node: { id: any, parentGroupId: any | null, organizationId: any | null, name: string, icon: string | null, isArchived: boolean, color: string | null, parentGroup: { id: any, name: string } | null, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null } }> } | null } }> } | null };

export type OrganizationProvider_GroupFragment = { id: any, parentGroupId: any | null, organizationId: any | null, name: string, icon: string | null, isArchived: boolean, color: string | null, parentGroup: { id: any, name: string } | null, editGroupAccessDialog_group_groupMembersCollection: { edges: Array<{ node: { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const OrganizationProvider_GroupFragmentDoc = `
    fragment OrganizationProvider_Group on Groups {
  id
  ...GroupUtils_Group
  ...GroupNavButton_Group
}
    ${GroupUtils_GroupFragmentDoc}
${GroupNavButton_GroupFragmentDoc}`;
export const OrganizationProvider_OrganizationDocument = `
    query OrganizationProvider_Organization($organizationId: UUID!) {
  organizationsCollection(filter: {id: {eq: $organizationId}}) {
    edges {
      node {
        groupsCollection(filter: {isDeleted: {eq: false}}) {
          edges {
            node {
              ...OrganizationProvider_Group
            }
          }
        }
      }
    }
  }
}
    ${OrganizationProvider_GroupFragmentDoc}`;
export const useOrganizationProvider_OrganizationQuery = <
      TData = OrganizationProvider_OrganizationQuery,
      TError = unknown
    >(
      variables: OrganizationProvider_OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationProvider_OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationProvider_OrganizationQuery, TError, TData>(
      ['OrganizationProvider_Organization', variables],
      fetcher<OrganizationProvider_OrganizationQuery, OrganizationProvider_OrganizationQueryVariables>(OrganizationProvider_OrganizationDocument, variables),
      options
    );

useOrganizationProvider_OrganizationQuery.getKey = (variables: OrganizationProvider_OrganizationQueryVariables) => ['OrganizationProvider_Organization', variables];
;

export const useInfiniteOrganizationProvider_OrganizationQuery = <
      TData = OrganizationProvider_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationProvider_OrganizationQueryVariables,
      variables: OrganizationProvider_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationProvider_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationProvider_OrganizationQuery, TError, TData>(
      ['OrganizationProvider_Organization.infinite', variables],
      (metaData) => fetcher<OrganizationProvider_OrganizationQuery, OrganizationProvider_OrganizationQueryVariables>(OrganizationProvider_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationProvider_OrganizationQuery.getKey = (variables: OrganizationProvider_OrganizationQueryVariables) => ['OrganizationProvider_Organization.infinite', variables];
;

useOrganizationProvider_OrganizationQuery.fetcher = (variables: OrganizationProvider_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<OrganizationProvider_OrganizationQuery, OrganizationProvider_OrganizationQueryVariables>(OrganizationProvider_OrganizationDocument, variables, options);