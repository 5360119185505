import React, { forwardRef } from "react";
import { InputContainer, StyledInput } from "./input.styled";
import { InputIcon } from "./subcomponents/input-icon/input-icon";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type InputProps = {
    icon?: IconProp
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(({
    icon,
    ...inputProps
}, ref) => {
    return (
        <InputContainer>
            <StyledInput
                {...inputProps}
                ref={ref}
                hasIcon={!!icon}
            />
            {icon && (
                <InputIcon icon={icon} />
            )}
        </InputContainer>
    )
})