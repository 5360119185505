import { UpdateNotificationsResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class NotificationsMenuService {
    async markNotificationsAsRead(notificationIds: string[]): Promise<UpdateNotificationsResult> {
        let result: UpdateNotificationsResult | undefined = undefined;

        try {
            result = await api.notifications.updateNotifications({
                notificationIds,
                read: true,
            })
        } catch {}

        if (!result) {
            result = new UpdateNotificationsResult();
            result.success = false;
        }

        return result;
    }

    async dismissNotifications(notificationIds: string[]): Promise<UpdateNotificationsResult> {
        let result: UpdateNotificationsResult | undefined = undefined;

        try {
            result = await api.notifications.updateNotifications({
                notificationIds,
                read: true,
                dismissed: true,
            })
        } catch {}

        if (!result) {
            result = new UpdateNotificationsResult();
            result.success = false;
        }

        return result;
    }
}

export const service = new NotificationsMenuService();