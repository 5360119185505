import { FC } from "react";
import { FormHintText } from "./authentication-form-hint.styled";

type AuthenticationFormHintProps = {
    children: React.ReactNode
}

export const AuthenticationFormHint: FC<AuthenticationFormHintProps> = ({
    children,
}) => {
    return (
        <FormHintText>
            {children}
        </FormHintText>
    )
}