import Spin from 'antd/es/spin';
import { createContext, FC, useContext, useMemo } from "react";
import { AuthenticationContext } from "../authentication-provider/AuthenticationProvider";
import { SelfProvider_SelfUserFragment, useSelfProvider_GetSelfUserQuery, useSelfProvider_OrganizationMembersQuery } from "./SelfProvider.generated";

type SelfProviderProps = {
    children: React.ReactNode
}

export const SelfProvider: FC<SelfProviderProps> = ({ children }) => {

    // context
    const { session } = useContext(AuthenticationContext)
    const userId = session?.user?.id

    // data
    const { data: selfData } = useSelfProvider_GetSelfUserQuery({ userId }, { enabled: !!userId })
    const { data: organizationMembersData } = useSelfProvider_OrganizationMembersQuery({ userId }, { enabled: !!userId })

    // data abstraction
    const selfUser = useMemo(() => (
        selfData?.usersCollection?.edges[0]?.node ?? null
    ), [selfData])

    const organizationMembers = useMemo(() => (
        organizationMembersData?.organizationMembersCollection?.edges
            .map(edge => edge?.node as NonNullable<typeof edge.node>)
            .filter(node => !!node)
    ), [organizationMembersData?.organizationMembersCollection?.edges])

    const organizationIds = useMemo(() => (
        organizationMembers?.map(member => member.organization?.id ?? '') ?? []
    ), [organizationMembers])

    // constants
    const isAdministrator = selfUser ? !!selfUser.selfProvider_selfUser_administratorsCollection?.edges[0] : null
    const isMultiOrganizationUser = isAdministrator || (organizationMembers?.length ?? 1) > 1;

    if (!selfUser) {
        return (
            <Spin tip="Getting things ready...">
                <div style={{ width: '100vw', height: '100vh' }} />
            </Spin>
        )
    }

    return (
        <SelfContext.Provider value={{
            selfUser,
            isAdministrator,
            isMultiOrganizationUser,
            organizationIds
        }}>
            {children}
        </SelfContext.Provider>
    )
}

export type SelfContextType = {
    selfUser: SelfProvider_SelfUserFragment | null
    isAdministrator: boolean | null
    isMultiOrganizationUser: boolean
    organizationIds: string[]
}

export const SelfContext = createContext<SelfContextType>({
    selfUser: null,
    isAdministrator: null,
    isMultiOrganizationUser: false,
    organizationIds: [],
})