import { createContext } from "react";

type AppShellContextType = {
    sidebarCollapsed: boolean
    onSidebarCollapsedChange: (sidebarCollapsed: boolean) => void
}

export const AppShellContext = createContext<AppShellContextType>({
    sidebarCollapsed: false,
    onSidebarCollapsedChange : () => {},
})