import { FC } from "react";
import { ErrorText, FooterTextContainer, FormItemContainer, HelpText, Label } from "./form-item.styled";

type FormItemProps = {
    label?: string
    helpText?: string
    errorText?: string
    children: React.ReactNode
    disabled?: boolean
}

export const FormItem: FC<FormItemProps> = ({
    label,
    helpText,
    errorText,
    children,
    disabled,
}) => {

    // constants
    const hasFooter = (!!helpText || !!errorText);

    return (
        <FormItemContainer
            hasFooter={hasFooter}
            disabled={disabled}
        >
            {!!label && (
                <Label>
                    {label}
                </Label>
            )}
            {children}
            {hasFooter && (
                <FooterTextContainer>
                    {!!helpText && (
                        <HelpText>
                            {helpText}
                        </HelpText>
                    )}
                    {!!errorText && (
                        <ErrorText>
                            {errorText}
                        </ErrorText>
                    )}
                </FooterTextContainer>
            )}
        </FormItemContainer>
    )
}