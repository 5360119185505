/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type ChangePrimaryEmailForm_UserFragment = { id: any, firstName: string | null };

export const ChangePrimaryEmailForm_UserFragmentDoc = `
    fragment ChangePrimaryEmailForm_User on Users {
  id
  firstName
}
    `;