/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { OrganizationMemberGridItem_OrganizationMemberFragmentDoc } from '../../../../../organization-members/organization-member-grid-item/organization-member-grid-item.generated';
import { OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragmentDoc } from '../../../../../organization-member-invitations/organization-member-invitation-grid-item/OrganizationMemberInvitationGridItem.generated';
import { EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragmentDoc } from '../../../../../organization-member-invitations/edit-organization-member-invitation-modal/edit-organization-member-invitation-modal.service.generated';
export type OrganizationMembersList_OrganizationFragment = { organizationMembersList_organization_organizationMembersCollection: { edges: Array<{ node: { id: any, userId: any | null, user: { firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null, organizationMembersList_organization_organizationMemberInvitationsCollection: { edges: Array<{ node: { id: any, invitedUserEmailAddress: string | null, userGroupId: any, invitedToOrganizationId: any | null } }> } | null };

export type OrganizationMembersList_OrganizationMemberFragment = { id: any, userId: any | null, user: { firstName: string | null, lastName: string | null, emailAddress: string | null } | null };

export type OrganizationMembersList_OrganizationMemberInvitationFragment = { id: any, invitedUserEmailAddress: string | null, userGroupId: any, invitedToOrganizationId: any | null };

export const OrganizationMembersList_OrganizationMemberFragmentDoc = `
    fragment OrganizationMembersList_OrganizationMember on OrganizationMembers {
  id
  ...OrganizationMemberGridItem_OrganizationMember
}
    ${OrganizationMemberGridItem_OrganizationMemberFragmentDoc}`;
export const OrganizationMembersList_OrganizationMemberInvitationFragmentDoc = `
    fragment OrganizationMembersList_OrganizationMemberInvitation on OrganizationMemberInvitations {
  id
  ...OrganizationMemberInvitationGridItem_OrganizationMemberInvitation
  ...EditOrganizationMemberInvitationModal_OrganizationMemberInvitation
}
    ${OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragmentDoc}
${EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragmentDoc}`;
export const OrganizationMembersList_OrganizationFragmentDoc = `
    fragment OrganizationMembersList_Organization on Organizations {
  organizationMembersList_organization_organizationMembersCollection: organizationMembersCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...OrganizationMembersList_OrganizationMember
      }
    }
  }
  organizationMembersList_organization_organizationMemberInvitationsCollection: organizationMemberInvitationsCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...OrganizationMembersList_OrganizationMemberInvitation
      }
    }
  }
}
    ${OrganizationMembersList_OrganizationMemberFragmentDoc}
${OrganizationMembersList_OrganizationMemberInvitationFragmentDoc}`;