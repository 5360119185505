import { MutableRefObject, useCallback, useRef, useState } from "react"

export const useStateWithRef = <T>(defaultValue: T): [
    T,
    React.Dispatch<React.SetStateAction<T>>,
    MutableRefObject<T>
] => {
    // state
    const [value, setValue] = useState<T>(defaultValue)

    // ref
    const valueRef = useRef<T>(value)

    // state interceptor
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSetState = useCallback(((nextState) => {
        setValue(prevState => {
            // @ts-ignore
            const effectiveValue = typeof nextState === 'function' ? nextState(prevState) : nextState;
            valueRef.current = effectiveValue;
            return effectiveValue;
        })
    }) as React.Dispatch<React.SetStateAction<T>>, [])

    return [
        value,
        handleSetState,
        valueRef,
    ]
}