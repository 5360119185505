import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AuthenticatedProviders } from "../../modules/authenticated/authenticated-providers";

export const AuthenticatedRoute: FC = () => {
    return (
        <AuthenticatedProviders>
            <Outlet />
        </AuthenticatedProviders>
    )
}