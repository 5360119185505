import { Route } from "react-router-dom";
import { OrganizationConstellationsRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/organization-constellations-route/OrganizationConstellationsRoute";
import { OrganizationRoute } from "../../../../../../routes/app-route/organization-route/organization-route";
import { OrganizationRouteName } from "../../../../../organizations/common/organization-route-name/organization-route-name";
import { ConstellationRoutes } from "./constellation-routes";
import { GroupRoutes } from "./group-routes";
import { OrganizationArchivedConstellationsRoute } from "../../../../../../routes/app-route/constellations-route/subroutes/organization-archived-constellations-route/OrganizationArchivedConstellationsRoute";
import { ArchivedRouteName } from "../../../../../organizations/common/archived-route-name/archived-route-name";

export const OrganizationRoutes = (
    <>
        <Route path=":organization_id" element={<OrganizationRoute />} handle={{ RouteNameComponent: OrganizationRouteName }}>

            {/* index */}
            <Route index element={<OrganizationConstellationsRoute />} />

            {/* constellations */}
            <Route path="constellations">
                {ConstellationRoutes}
            </Route>

            {/* qr code groups */}
            <Route path="groups" >
                {GroupRoutes}
            </Route>

            {/* archived */}
            <Route path="archived">
                {/* index */}
                <Route index element={<OrganizationArchivedConstellationsRoute />} handle={{ RouteNameComponent: ArchivedRouteName }} />

                {/* constellations */}
                <Route path="constellations">
                    {ConstellationRoutes}
                </Route>

                {/* qr code groups */}
                <Route path="groups" >
                    {GroupRoutes}
                </Route>
            </Route>
        </Route>
    </>
)