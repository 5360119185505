import { FC } from "react";
import { HeaderContainer, HeaderSubtitle, HeaderTitle } from "./authentication-form-header.styled";

type AuthenticationFormHeaderProps = {
    title: string
    subtitle?: string
}

export const AuthenticationFormHeader: FC<AuthenticationFormHeaderProps> = ({
    title,
    subtitle,
}) => {
    return (
        <HeaderContainer>
            <HeaderTitle>
                {title}
            </HeaderTitle>
            {!!subtitle && (
                <HeaderSubtitle>
                    {subtitle}
                </HeaderSubtitle>
            )}
        </HeaderContainer>
    )
}