import * as colors from '@ant-design/colors'
import { useQueryClient } from '@tanstack/react-query'
import get from 'lodash/get'
import React, { FC, useCallback, useContext } from 'react'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Notification } from '../notification/Notification'
import { NotificationActionCoordinator } from './notification-action-coordinator/NotificationActionCoordinator'
import { NotificationTitleCoordinator } from './notification-title-coordinator/NotificationTitleCoordinator'
import { service } from './notification-coodinator.service'
import message from 'antd/es/message'
import { NotificationCoordinator_NotificationFragment } from './notification-coodinator.generated'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'

type NotificationCoordinatorProps = {
	notification: NotificationCoordinator_NotificationFragment
}

export const NotificationCoordinator: FC<NotificationCoordinatorProps> = ({
	notification,
}) => {

	// react query
	const queryClient = useQueryClient()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// event handlers
	const handleDismiss = useCallback(async () => {
		/* dismiss the notification */
		const result = await service.dismissNotification(notification.id)

		/* handle any errors that occurred */
		if (!result.success || !result.notification) {
			message.error('Something went wrong. Please try again.')
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ userId: result.notification.userId }
			])
		)

		/* log an app event */
		await logAppEvent('dismissed_notification', {
			notificationId: notification.id,
		})
	}, [notification.id, queryClient, logAppEvent])

	return (
		<Notification
			iconColor={get(colors, notification.notificationType?.iconColor ?? 'grey.primary')}
			icon={<FontAwesomeIcon icon={notification.notificationType?.icon as IconProp ?? faQuestionCircle} />}
			title={(
				<NotificationTitleCoordinator notification={notification} />
			)}
			timestamp={notification.timestamp}
			object={notification.notificationType?.objectName ?? ''}
			actions={(
				<NotificationActionCoordinator notification={notification} />
			)}
			onDismiss={handleDismiss}
		/>
	)
}