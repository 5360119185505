import React, { FC, ReactNode, useMemo } from 'react'
import { ViewContainer } from '../../../common/view-container/ViewContainer'
import { ViewBar } from '../../../common/view-bar/view-bar'
import { StyledBillingIcon } from '../../../common/icons/styled-icons/styled-billing-icon/styled-billing-icon'
import { TabBar } from '../../../common/tab-bar/tab-bar'
import { Content } from '../../../common/content/content'

type BillingPreferencesViewProps = {
	organizationId: string
	children: ReactNode
}

export const BillingPreferencesView: FC<BillingPreferencesViewProps> = ({
	children,
}) => {

	// constants
	const tabBar = useMemo(() => ((
		<TabBar links={[
			{ label: 'Invoices', to: `invoices` },
			{ label: 'Payment Methods', to: `payment-methods` },
			{ label: 'Subscriptions', to: `subscriptions` },
		]} />
	)), [])

	return (
		<ViewContainer>
			<ViewBar
				icon={<StyledBillingIcon />}
				viewName="Billing & Subscriptions"
				tabBar={tabBar}
			/>
			<Content>
				{children}
			</Content>
		</ViewContainer>
	)
}