import { FC } from "react";
import { UpgradeToPremiumButton } from "./subcomponents/upgrade-to-premium-button/upgrade-to-premium-button";
import { SubscriptionButton_OrganizationFragment } from "./subscription-button.generated";
import { YouArePremiumButton } from "./subcomponents/you-are-premium-button/you-are-premium-button";
import { useCheckPermissions } from "./subscription-button.hooks";
import { OrganizationProvider } from "../../../../../../../organizations/common/organization-provider/organization-provider";

type SubscriptionButtonProps = {
    organization: SubscriptionButton_OrganizationFragment
}

export const SubscriptionButton: FC<SubscriptionButtonProps> = ({
    organization,
}) => {

    // constants
    const { data } = useCheckPermissions({ resources: [`arn:cqr:organization:${organization.id}:billing:*`], actions: ['billing:*'] })();

    if (!data?.hasPermission) return null;

    return (
        <OrganizationProvider organizationId={organization.id}>
            <YouArePremiumButton />
            <UpgradeToPremiumButton />
        </OrganizationProvider>
    )
}