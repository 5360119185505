import Button from 'antd/es/button'
import message from 'antd/es/message'
import Select from 'antd/es/select'
import Tag from 'antd/es/tag'
import Tooltip from 'antd/es/tooltip'
import { FC, useCallback, useContext, useEffect, MouseEvent, useState, useMemo } from 'react'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { service } from './add-user-to-organization-dialog.service'
import { InviteUserToOrganizationResult } from 'constellation-sdk'
import { AddUserToOrganizationDialog_OrganizationFragment } from './add-user-to-organization-dialog.generated'
import { Colors } from '../../../../../../common/colors/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { queryInvalidationHelper } from '../../../../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../../../../app/providers/app-event-provider/AppEventProvider'
import { SelfContext } from '../../../../../app/providers/self-provider/SelfProvider'
import { DialogBody } from '../../../../../common/dialog/subcomponents/dialog-body/dialog-body'
import { DialogFooter } from '../../../../../common/dialog/subcomponents/dialog-footer/dialog-footer'
import { DialogHeroPanel } from '../../../../../common/dialog/subcomponents/dialog-hero-panel/dialog-hero-panel'
import { Loading } from '../../../../../common/loading/Loading'
import { Dialog } from '../../../../../common/dialog/dialog'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { DialogTitle } from '../../../../../common/dialog/subcomponents/dialog-title/dialog-title'
import { FormItem } from '../../../../../common/forms/form-item/form-item'
import { FormItemsContainer } from '../../../../../common/forms/form-items-container/form-items-container'
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'

type FormValues = {
	emailAddresses: string[]
	userGroupId: string
}

type AddUserToOrganizationDialogProps = {
	organization: AddUserToOrganizationDialog_OrganizationFragment
	open: boolean
	onDismiss?: () => void
}

export const AddUserToOrganizationDialog: FC<AddUserToOrganizationDialogProps> = ({
	organization,
	open,
	onDismiss,
}) => {

	// context
	const { selfUser } = useContext(SelfContext)
	const { logAppEvent } = useContext(AppEventContext)

	// memoized values and constants
	const userEmails = useMemo(() => (
		organization.addUserToOrganizationDialog_organization_organizationMembersCollection?.edges
			.map(edge => edge?.node as NonNullable<typeof edge.node>)
			.filter(node => !!node)
			.map(organizationMember => organizationMember.user?.emailAddress as string)
			.filter(node => !!node)
	), [organization.addUserToOrganizationDialog_organization_organizationMembersCollection?.edges])

	const invitationEmails = useMemo(() => (
		organization.addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection?.edges
			.map(edge => edge?.node as NonNullable<typeof edge.node>)
			.filter(node => !!node)
			.map(invitation => invitation.invitedUserEmailAddress as string)
			.filter(node => !!node)
	), [organization.addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection?.edges])

	const userGroups = useMemo(() => (
		organization.AddUserToOrganizationDialog_Organization_UserGroupsCollection?.edges
			.map(edge => edge.node as NonNullable<typeof edge.node>)
	), [organization.AddUserToOrganizationDialog_Organization_UserGroupsCollection?.edges])

	const memberUserGroup = useMemo(() => (
		userGroups?.find(userGroup => userGroup.name.toLowerCase().includes('member'))
	), [userGroups])

	// state
	const [isLoading, setIsLoading] = useState(false)

	// form state
	const {
		control,
		reset,
		getValues,
		formState: { isValid: formValid },
		handleSubmit,
		setValue,
	} = useForm({
		mode: 'all',
		defaultValues: {
			emailAddresses: [] as string[],
			userGroupId: memberUserGroup?.id,
		} as FormValues
	})

	// query client
	const queryClient = useQueryClient()

	// side effects
	useEffect(() => {
		if (open) {
			reset()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	// event handlers
	const handleClose = useCallback(() => {
		onDismiss && onDismiss()
	}, [onDismiss])

	const validateEmail = useCallback((email: string): { isValid: boolean, message: string } => {
		const isRealEmail = service.emailIsLegit(email)
		if (!isRealEmail) {
			return {
				isValid: false,
				message: 'Not a valid email',
			}
		}

		const isSelf = selfUser?.emailAddress?.toLowerCase() === email.toLowerCase()
		if (isSelf) {
			return {
				isValid: false,
				message: 'Can\'t invite yourself',
			}
		}

		const alreadyAdded = userEmails?.includes(email.toLowerCase())
		if (alreadyAdded) {
			return {
				isValid: false,
				message: 'Already added',
			}
		}

		const alreadySent = invitationEmails?.includes(email.toLowerCase())
		if (alreadySent) {
			return {
				isValid: false,
				message: 'Invitation already sent',
			}
		}

		return {
			isValid: true,
			message: '',
		}
	}, [selfUser?.emailAddress, userEmails, invitationEmails])

	const handleFormSubmit = useCallback(async () => {
		setIsLoading(true)

		/* extract form values */
		const {
			userGroupId,
			emailAddresses,
		} = getValues()

		/* filter valid email addresses */
		const validEmailAddresses = emailAddresses.filter(email => validateEmail(email).isValid)

		const succeededResults: InviteUserToOrganizationResult[] = [];
		const failedResults: InviteUserToOrganizationResult[] = [];

		/* invite the users */
		for (const emailAddress of validEmailAddresses) {
			/* invite the user */
			const result = await service.inviteUserToOrganization({
				organizationId: organization.id,
				userGroupId,
				inviteEmailAddress: emailAddress,
			})

			/* handle any errors that occurred */
			if (!result.success || !result.organizationMemberInvitation) {
				failedResults.push(result)
			} else {
				succeededResults.push(result)
			}
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ organizationId: organization.id },
			])
		)

		/* log an app event */
		if (succeededResults.length) {
			for (const result of succeededResults) {
				await logAppEvent('invited_user_to_organization', {
					organizationMemberInvitationId: result.organizationMemberInvitation?.id,
				})
			}
		}

		/* notify the user of the requests that succeeded */
		if (succeededResults.length) {
			let successMessage = '';
			if (succeededResults.length === 1) {
				const result = succeededResults[0]
				successMessage = `Successfully invited ${result.organizationMemberInvitation?.invitedUserEmailAddress} to ${organization.name}`
			} else {
				const emailAddresses = succeededResults.map(result => ` - ${result.organizationMemberInvitation?.invitedUserEmailAddress}`).join('\n')
				successMessage = `Successfully invited ${succeededResults.length} users to ${organization.name}:\n${emailAddresses}`
			}

			message.success(successMessage)
		}

		/* notify the user of the requests that failed */
		const succeededEmailAddresses = succeededResults.map(result => result.organizationMemberInvitation?.invitedUserEmailAddress)
		const failedEmailAddresses = validEmailAddresses.filter(validAddress => !succeededEmailAddresses.find(succeededAddress => succeededAddress === validAddress))

		if (failedEmailAddresses.length) {
			let failedMessage = '';
			if (failedEmailAddresses.length === 1) {
				const failedAddress = failedEmailAddresses[0]
				failedMessage = `Something went wrong inviting ${failedAddress} to ${organization.name}.`
			} else {
				const emailAddresses = failedEmailAddresses.map(address => ` - ${address}`).join('\n')
				failedMessage = `Something went wrong inviting ${succeededResults.length} users to ${organization.name}:\n${emailAddresses}`
			}

			message.error(failedMessage)
		}

		/* if any requests failed, remove the succeeded email addresses from the form field */
		if (failedEmailAddresses.length) {
			const newEmailAddresses = emailAddresses.filter(address => !succeededEmailAddresses.includes(address))
			setValue('emailAddresses', newEmailAddresses)
		}

		/* finally, clear the loading state and if no requests failed dismiss the modal */
		setIsLoading(false)
		if (failedEmailAddresses.length < 1) {
			handleClose()
		}
	}, [getValues, queryClient, organization.id, organization.name, validateEmail, logAppEvent, setValue, handleClose])

	const handleRemoveEmail = useCallback((ev: MouseEvent<HTMLElement>, field: ControllerRenderProps<FormValues, 'emailAddresses'>, address: string) => {
		ev.preventDefault()
		field.onChange([...field.value].filter(fieldAddress => fieldAddress !== address))
	}, [])

	const handleCancelButtonClick = useCallback(() => {
		handleClose()
	}, [handleClose])

	const handleInviteButtonClicked = useCallback(() => {
		handleSubmit(handleFormSubmit)()
	}, [handleFormSubmit, handleSubmit])

	if (isLoading) {
		return (
			<Loading />
		)
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogHeroPanel
				title={'Add Users'}
				description={(
					<span>
						Add users so that they can view and manage resources in this organization.
					</span>
				)}
				icon={<FontAwesomeIcon icon={faUsers} />}
			/>
			<DialogBody>
				<DialogTitle icon={<FontAwesomeIcon icon={faUsers} />}>
					Add User(s)
				</DialogTitle>
				<form onSubmit={handleFormSubmit}>
					<FormItemsContainer>
						<FormItem label="User Email Address(es)">
							<Controller
								control={control}
								name="emailAddresses"
								rules={{
									validate: value => {
										if (value.length < 1) return false
										const validation = value.map(val => validateEmail(val).isValid)
										return validation.includes(true)
									}
								}}
								render={({ field }) => (
									<Select
										mode="tags"
										tokenSeparators={[',', ' ']}
										autoFocus
										placeholder="Enter email address to invite..."
										tagRender={props => {
											const { isValid, message } = validateEmail(props.value)
											return (
												<Tooltip overlay={message} trigger={isValid ? [] : ['hover']} color="volcano">
													<Tag
														color={isValid ? Colors.cardBackground : 'volcano'}
														closable
														onClose={(ev) => handleRemoveEmail(ev, field, props.value)}
														icon={isValid ? undefined : <FontAwesomeIcon icon={faCircle} />}
													>
														<span>{props.label}</span>
													</Tag>
												</Tooltip>
											)
										}}
										{...field}
									/>
								)}
							/>
						</FormItem>
						<FormItem label="User Role">
							<Controller
								control={control}
								name="userGroupId"
								rules={{ required: true }}
								render={({ field }) => (
									<Select {...field}>
										{userGroups?.map(userGroup => (
											<Select.Option key={userGroup.id} value={userGroup.id}>{userGroup.name}</Select.Option>
										))}
									</Select>
								)}
							/>
						</FormItem>
					</FormItemsContainer>
				</form>
			</DialogBody>
			<DialogFooter>
				<Button onClick={handleCancelButtonClick}>
					Cancel
				</Button>
				<Button type="primary" disabled={!formValid} onClick={handleInviteButtonClicked}>
					Invite User(s)
				</Button>
			</DialogFooter>
		</Dialog>
	)
}