import { CheckPermissionsPayload, CheckPermissionsResult } from "constellation-sdk";
import { api } from "../../../../../../../../common/api/api";

class SubscriptionButtonService {
    public async checkPermissions(payload: CheckPermissionsPayload): Promise<CheckPermissionsResult> {
        let result: CheckPermissionsResult | undefined = undefined;

        try {
            result = await api.iam.checkPermissions(payload);
        } catch {}

        if (!result) {
            result = new CheckPermissionsResult();
            result.success = false;
            result.errorCode = 'unknown';
        }

        return result;
    }
}

export const service = new SubscriptionButtonService();