import { InviteUserToOrganizationPayload, InviteUserToOrganizationResult } from "constellation-sdk";
import { api } from "../../../../../../common/api/api";

class AddUserToOrganizationDialogService {
    public async inviteUserToOrganization(payload: InviteUserToOrganizationPayload): Promise<InviteUserToOrganizationResult> {
        let result: InviteUserToOrganizationResult | undefined = undefined;

        try {
            result = await api.organizationMemberInvitations.inviteUser(payload)
        } catch {}

        if (!result) {
            result = new InviteUserToOrganizationResult();
            result.success = false;
        }

        return result;
    }

    emailIsLegit = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
}

export const service = new AddUserToOrganizationDialogService()