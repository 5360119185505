import { FC } from "react";
import { PageViewProvider } from "../app/providers/page-view-provider/PageViewProvider";

type PlatformProvidersProps = {
    children: React.ReactNode
}

export const PlatformProviders: FC<PlatformProvidersProps> = ({
    children,
}) => {
    return (
        <PageViewProvider>
            {children}
        </PageViewProvider>
    )
}