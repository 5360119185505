export const opacify = (hex: string, opacity: number): string => {
    const { r, g, b } = hexToRgb(hex)!;
    return `rgba(${[r, g, b, opacity].join(', ')})`
}

export const hexToRgb = (hex: string): { r: number, g: number, b: number } | null => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  