/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { PreferencesHeader_OrganizationFragmentDoc } from './subcomponents/preferences-header/preferences-header.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type PreferencesShell_OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type PreferencesShell_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { id: any } }> } | null };


export const PreferencesShell_OrganizationDocument = `
    query PreferencesShell_Organization($organizationId: UUID!) {
  organizationsCollection(filter: {id: {eq: $organizationId}}) {
    edges {
      node {
        ...PreferencesHeader_Organization
      }
    }
  }
}
    ${PreferencesHeader_OrganizationFragmentDoc}`;
export const usePreferencesShell_OrganizationQuery = <
      TData = PreferencesShell_OrganizationQuery,
      TError = unknown
    >(
      variables: PreferencesShell_OrganizationQueryVariables,
      options?: UseQueryOptions<PreferencesShell_OrganizationQuery, TError, TData>
    ) =>
    useQuery<PreferencesShell_OrganizationQuery, TError, TData>(
      ['PreferencesShell_Organization', variables],
      fetcher<PreferencesShell_OrganizationQuery, PreferencesShell_OrganizationQueryVariables>(PreferencesShell_OrganizationDocument, variables),
      options
    );

usePreferencesShell_OrganizationQuery.getKey = (variables: PreferencesShell_OrganizationQueryVariables) => ['PreferencesShell_Organization', variables];
;

export const useInfinitePreferencesShell_OrganizationQuery = <
      TData = PreferencesShell_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof PreferencesShell_OrganizationQueryVariables,
      variables: PreferencesShell_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<PreferencesShell_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PreferencesShell_OrganizationQuery, TError, TData>(
      ['PreferencesShell_Organization.infinite', variables],
      (metaData) => fetcher<PreferencesShell_OrganizationQuery, PreferencesShell_OrganizationQueryVariables>(PreferencesShell_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfinitePreferencesShell_OrganizationQuery.getKey = (variables: PreferencesShell_OrganizationQueryVariables) => ['PreferencesShell_Organization.infinite', variables];
;

usePreferencesShell_OrganizationQuery.fetcher = (variables: PreferencesShell_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<PreferencesShell_OrganizationQuery, PreferencesShell_OrganizationQueryVariables>(PreferencesShell_OrganizationDocument, variables, options);