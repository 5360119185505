import { FC, useMemo } from "react";
import { RouteNameProps } from "../../../common/route-name/route-name.types";
import { useOrganizationRouteName_OrganizationQuery } from "./organization-route-name.generated";

export const OrganizationRouteName: FC<RouteNameProps> = ({
    data,
}) => {

    // router hooks
    const { organization_id: organizationId } = data.params as { organization_id: string }

    // data hooks
    const { data: organizations, isLoading } = useOrganizationRouteName_OrganizationQuery({ organizationId })

    // data abstraction
    const organization = useMemo(() => (
        organizations?.organizationsCollection?.edges[0]?.node
    ), [organizations?.organizationsCollection?.edges])

    if (!organization || isLoading) {
        return null
    }
    
    return (
        <span>
            {organization.name}
        </span>
    )
}