import React, { FC, useState } from 'react'
import { DialogsContext } from './dialogs-context'

type DialogsProviderProps = {
	children: React.ReactNode
}

export const DialogsProvider: FC<DialogsProviderProps> = ({
	children,
}) => {

	// hooks
	const [currentDialogs, setCurrentDialogs] = useState<string[]>([])

	return (
		<DialogsContext.Provider value={{
			currentDialogs,
			setCurrentDialogs,
		}}>
			{children}
		</DialogsContext.Provider>
	)
}