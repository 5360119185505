/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { GroupDragReceiver_GroupFragmentDoc } from '../common/group-drag-receiver/group-drag-receiver.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../common/graphql/fetcher';
export type GroupRouteName_GroupQueryVariables = Types.Exact<{
  groupId: Types.Scalars['UUID'];
}>;


export type GroupRouteName_GroupQuery = { groupsCollection: { edges: Array<{ node: { id: any, name: string, parentGroupId: any | null, organizationId: any | null } }> } | null };


export const GroupRouteName_GroupDocument = `
    query GroupRouteName_Group($groupId: UUID!) {
  groupsCollection(filter: {id: {eq: $groupId}}) {
    edges {
      node {
        id
        name
        ...GroupDragReceiver_Group
      }
    }
  }
}
    ${GroupDragReceiver_GroupFragmentDoc}`;
export const useGroupRouteName_GroupQuery = <
      TData = GroupRouteName_GroupQuery,
      TError = unknown
    >(
      variables: GroupRouteName_GroupQueryVariables,
      options?: UseQueryOptions<GroupRouteName_GroupQuery, TError, TData>
    ) =>
    useQuery<GroupRouteName_GroupQuery, TError, TData>(
      ['GroupRouteName_Group', variables],
      fetcher<GroupRouteName_GroupQuery, GroupRouteName_GroupQueryVariables>(GroupRouteName_GroupDocument, variables),
      options
    );

useGroupRouteName_GroupQuery.getKey = (variables: GroupRouteName_GroupQueryVariables) => ['GroupRouteName_Group', variables];
;

export const useInfiniteGroupRouteName_GroupQuery = <
      TData = GroupRouteName_GroupQuery,
      TError = unknown
    >(
      pageParamKey: keyof GroupRouteName_GroupQueryVariables,
      variables: GroupRouteName_GroupQueryVariables,
      options?: UseInfiniteQueryOptions<GroupRouteName_GroupQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<GroupRouteName_GroupQuery, TError, TData>(
      ['GroupRouteName_Group.infinite', variables],
      (metaData) => fetcher<GroupRouteName_GroupQuery, GroupRouteName_GroupQueryVariables>(GroupRouteName_GroupDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteGroupRouteName_GroupQuery.getKey = (variables: GroupRouteName_GroupQueryVariables) => ['GroupRouteName_Group.infinite', variables];
;

useGroupRouteName_GroupQuery.fetcher = (variables: GroupRouteName_GroupQueryVariables, options?: RequestInit['headers']) => fetcher<GroupRouteName_GroupQuery, GroupRouteName_GroupQueryVariables>(GroupRouteName_GroupDocument, variables, options);