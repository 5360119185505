"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationsModule = void 0;
const common_1 = require("../../../common");
class OrganizationsModule extends common_1.APIModule {
    constructor(client) {
        super(client, '/organizations');
    }
    updateOrganization(organizationId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([organizationId]);
            const response = yield this.client.put(endpoint, payload);
            return response.data;
        });
    }
    getSubscriptionDetails(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                organizationId,
                'subscription-details',
            ]);
            const response = yield this.client.get(endpoint);
            return response.data;
        });
    }
    createBillingPortalSession(organizationId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                organizationId,
                'billing-portal-session',
            ]);
            const response = yield this.client.post(endpoint, payload);
            return response.data;
        });
    }
    createDefaultPremiumCheckoutSession(organizationId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                organizationId,
                'default-premium-checkout-session',
            ]);
            const response = yield this.client.post(endpoint, payload);
            return response.data;
        });
    }
}
exports.OrganizationsModule = OrganizationsModule;
