import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type OrganizationTeamsViewProps = {}

export const OrganizationTeamsView: FC<OrganizationTeamsViewProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.enterpriseTeamsView}>
			EnterpriseTeamsView works!
		</div>
	)
}

const useStyles = createUseStyles({
	enterpriseTeamsView: {
		display: 'grid'
	}
})