import { AcceptOrganizationMemberInvitationResult, DeclineOrganizationMemberInvitationResult, UpdateNotificationResult } from "constellation-sdk";
import { api } from "../../../../common/api/api";

class InvitedToOrganizationNotificationActionsService {
    async acceptInvitation(organizationMemberInvitationId: string): Promise<AcceptOrganizationMemberInvitationResult> {
        let result: AcceptOrganizationMemberInvitationResult | undefined = undefined;

        try {
            result = await api.organizationMemberInvitations.acceptInvitation(organizationMemberInvitationId, {})
        } catch {}

        if (!result) {
            result = new AcceptOrganizationMemberInvitationResult()
            result.success = false;
        }

        return result;
    }

    async declineInvitation(organizationMemberInvitationId: string): Promise<DeclineOrganizationMemberInvitationResult> {
        let result: DeclineOrganizationMemberInvitationResult | undefined = undefined;

        try {
            result = await api.organizationMemberInvitations.declineInvitation(organizationMemberInvitationId, {})
        } catch {}

        if (!result) {
            result = new DeclineOrganizationMemberInvitationResult()
            result.success = false;
        }

        return result;
    }

    async dismissNotification(notificationId: string): Promise<UpdateNotificationResult> {
        let result: UpdateNotificationResult | undefined = undefined;

        try {
            result = await api.notifications.updateNotification(notificationId, {
                read: true,
                dismissed: true,
            })
        } catch {}

        if (!result) {
            result = new UpdateNotificationResult()
            result.success = false;
        }

        return result;
    }
}

export const service = new InvitedToOrganizationNotificationActionsService();