import { FC } from "react";
import { ViewBar } from "../../../../../common/view-bar/view-bar";
import { StyledToolsIcon } from "../../../../../common/icons/styled-icons/styled-tools-icon/styled-tools-icon";

export const AdminToolsViewBar: FC = () => {
    return (
        <ViewBar
            icon={(<StyledToolsIcon />)}
            viewName="Admin Tools"
        />
    )
}