"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIModule = void 0;
class APIModule {
    constructor(client, apiPath) {
        this.client = client;
        this.baseUrl = [client.options.apiUrl, apiPath].join('');
    }
    buildEndpoint(segments) {
        return [this.baseUrl, ...segments].join('/');
    }
}
exports.APIModule = APIModule;
