import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserProfileView_UserFragment } from './UserProfileView.generated'
import { FormFieldsAccordion } from '../../../../../common/form-fields-accordion/FormFieldsAccordion'
import { UpdateUserProfileForm } from '../../../../../users/update-user-profile-form/update-user-profile-form'
import { UserAvatar } from '../../../../../users/user-avatar/UserAvatar'
import { PreferencesGeneralLayout } from '../../../../common/preferences-general-layout/PreferencesGeneralLayout'
import { faSmile } from '@fortawesome/pro-regular-svg-icons/faSmile'

type UserProfileViewProps = {
	user: UserProfileView_UserFragment
}

export const UserProfileView: FC<UserProfileViewProps> = ({
	user,
}) => {
	return (
		<PreferencesGeneralLayout
			title="Personal Info"
			subtitle="Update the info we use to personalize your products."
			icon={(
				<UserAvatar
					user={user}
					size={56}
				/>
			)}
		>
			<FormFieldsAccordion
				title="Personal info"
				description={(
					<>
						<span>This is the information we will use when we communicate with you.</span>
						<br />
						<span>Other users will see this information when they interact with you.</span>
					</>
				)}
				fieldPanels={[
					{
						id: 'name',
						label: 'Your Name',
						icon: <FontAwesomeIcon icon={faSmile} />,
						description: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
						component: UpdateUserProfileForm,
						props: { user }
					}
				]}
			/>
		</PreferencesGeneralLayout>
	)
}