/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type QrCodeImage_NovaFragment = { embeddedLink: string | null, novaType: { code: string } | null, novaDecorationImage: { imageUrl: string | null } | null };

export const QrCodeImage_NovaFragmentDoc = `
    fragment QRCodeImage_Nova on Novas {
  embeddedLink
  novaType {
    code
  }
  novaDecorationImage {
    imageUrl
  }
}
    `;