import { FC, ReactNode, createContext } from "react";

type NavSectionProviderProps = {
    hasLabel: boolean
    children: ReactNode
}

export const NavSectionProvider: FC<NavSectionProviderProps> = ({
    hasLabel,
    children,
}) => {
    return (
        <NavSectionContext.Provider value={{
            hasLabel,
        }}>
            {children}
        </NavSectionContext.Provider>
    )
}

type NavSectionContextType = {
    hasLabel: boolean
}

export const NavSectionContext = createContext<NavSectionContextType>({
    hasLabel: false
})