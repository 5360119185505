import styled from "@emotion/styled";

export const AuthenticationFormContainer = styled.div({
    // layout
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
})

export const AuthForm = styled.form({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    // size
    width: 324,
    maxWidth: '100%',
})