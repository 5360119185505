import styled from "@emotion/styled";

export const LogoContainer = styled.div({})

export const LogoImage = styled.img({
    // size
    width: 200,

    // opacity
    opacity: 0.75,
})