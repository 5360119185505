import { css } from "@emotion/react";

export const NavButtonStyles = css({
    justifyContent: 'start',

    '&>span': {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
})