import Spin from 'antd/es/spin'
import React, { FC, useContext, useMemo } from 'react'
import { createBrowserRouter, Route, RouterProvider } from 'react-router-dom'
import { AuthenticationContext } from '../../providers/authentication-provider/AuthenticationProvider'
import { AuthenticatorRoutes } from './routes/authenticator-routes/authenticator-routes'
import { PlatformRoute } from '../../../../routes/platform-route/platform-route'
import { AuthenticatedRoutes } from './routes/authenticated-routes/authenticated-routes'
import { RedirectorRoute } from '../../../../routes/redirector-route/redirector-route'
import { AppLoadingContainer } from './app-router.styled'
import { createRoutesFromElements } from './app-router.utils';
import { WidgetRoutes } from './routes/prototype/widget-routes'

export const AppRouter: FC = () => {

    // hooks
    const { authenticationState } = useContext(AuthenticationContext)

    const routes = useMemo(() => {
        return (
            <Route path="*" element={<PlatformRoute />} id="platform-route">
                {/* redirector is always available */}
                <Route path="qr/:alias" element={<RedirectorRoute />} />

                {/* widgets are always available */}
                <Route path="widget">
                    {WidgetRoutes}
                </Route>

                {/* authenticating */}
                {authenticationState === 'authenticating' && (
                    <Route element={(
                        <AppLoadingContainer>
                            <Spin />
                        </AppLoadingContainer>
                    )} />
                )}

                {/* authenticated */}
                {authenticationState === 'authenticated' && (
                    AuthenticatedRoutes
                )}
                
                {/* not authenticated */}
                {authenticationState === 'notAuthenticated' && (
                    AuthenticatorRoutes
                )}
            </Route>
        )
    }, [authenticationState])

    const router = useMemo(() => {
        const routeObjects = createRoutesFromElements(
            routes
        )

        return createBrowserRouter(routeObjects, {
            async unstable_patchRoutesOnMiss({ matches, patch }) {
                const leafRoute = matches[matches.length - 1]?.route;
                if (leafRoute?.handle?.lazyChildren) {
                  const children = await leafRoute.handle.lazyChildren();
                  patch(leafRoute.id ?? null, createRoutesFromElements(children, leafRoute.id));
                }
            },
        })
    }, [routes])

    return <RouterProvider router={router} />
}