import { useContext, useEffect, useMemo, useState } from "react"
import { DialogsContext } from "../dialogs-provider/dialogs-context"
import * as uuid from 'uuid'
import { SerializedStyles } from "@emotion/react"
import { BlockInteractionStyles, PortalStyles } from "./dialog.styled"

export const useShowDialogBackground = (dialogId: string, isOpen: boolean): { showDialogBackground: boolean } => {

    // context
    const { currentDialogs } = useContext(DialogsContext)

    // constants
    const isBottomDialog = currentDialogs[0] === dialogId;

    return useMemo(() => ({
        showDialogBackground: isOpen && isBottomDialog,
    }), [isBottomDialog, isOpen]);
}

export const useWindowHeight = (): { windowHeight: number } => {

    // state
    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
    
    // side effects
    useEffect(() => {
		const onWindowResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', onWindowResize)

		return () => {
			window.removeEventListener('resize', onWindowResize)
		}
	}, [])

    useEffect(() => {
        setWindowHeight(window.innerHeight)
        setTimeout(() => {
            setWindowHeight(window.innerHeight)
        }, 500);
    }, [])

    return useMemo(() => ({
        windowHeight,
    }), [windowHeight])
}

export const useRenderDialog = (isOpen: boolean): { renderDialog: boolean } => {

    // state
    const [renderDialog, setRenderDialog] = useState<boolean>(isOpen)

    // side effects
    useEffect(() => {
        if (isOpen) {
            setRenderDialog(true);
            return;
        };

        const timeout = setTimeout(() => {
            setRenderDialog(false)
        }, 400);

		return () => {
            clearTimeout(timeout)
        }
	}, [isOpen])

    return useMemo(() => ({
        renderDialog,
    }), [renderDialog]);
}

export const useShowDialogContainer = (dialogId: string, isOpen: boolean): { showDialogContainer: boolean } => {
    // context
    const { currentDialogs } = useContext(DialogsContext)

    // constants
    const isTopDialog = currentDialogs[currentDialogs.length - 1] === dialogId;

    return useMemo(() => ({
        showDialogContainer: isOpen && isTopDialog,
    }), [isTopDialog, isOpen]);
}

export const useRegisterDialog = (isOpen: boolean): { dialogId: string } => {

    // context
    const { setCurrentDialogs } = useContext(DialogsContext)
    
    // memoized values and constants
    const dialogId = useMemo(() => uuid.v4(), [])

    // side effects
    useEffect(() => {
        if (!isOpen) return;
    
        setCurrentDialogs(currentDialogs => [...currentDialogs, dialogId])

        return () => {
            setCurrentDialogs(currentDialogs => currentDialogs.filter(dialog => dialog !== dialogId))
        }
    }, [dialogId, isOpen, setCurrentDialogs])

    return useMemo(() => ({
        dialogId,
    }), [dialogId])
}

export const usePortalClassName = (isOpen: boolean): { portalStyles: SerializedStyles[] } => {
    const portalStyles = useMemo(() => {
		const styles = [PortalStyles]
		if (!isOpen) {
			styles.push(BlockInteractionStyles)
		}
        return styles;
	}, [isOpen])

    return useMemo(() => ({
        portalStyles,
    }), [portalStyles])
}