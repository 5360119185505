import { grey } from '@ant-design/colors'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import Button from 'antd/es/button'
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import { BorderRadius, Padding, Gap, FontSize } from '../../../common/constants/constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OrganizationMemberInvitationContextMenu } from '../enterprise-member-invitation-context-menu/OrganizationMemberInvitationContextMenu'
import { Colors } from '../../../common/colors/colors'
import { OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragment } from './OrganizationMemberInvitationGridItem.generated'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'

type OrganizationMemberInvitationGridItemProps = {
    organizationMemberInvitation: OrganizationMemberInvitationGridItem_OrganizationMemberInvitationFragment
    isActive?: boolean
    onClick?: () => void
}

export const OrganizationMemberInvitationGridItem: FC<OrganizationMemberInvitationGridItemProps> = ({
    organizationMemberInvitation,
    isActive,
    onClick,
}) => {

    // hooks
    const styles = useStyles()

    if (!organizationMemberInvitation) return null

    return (
        <div className={`${styles.container} ${isActive && styles.active}`} onClick={onClick}>
            <div className={styles.icon}>
                <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className={styles.detailsContainer}>
                <h3>
                    {organizationMemberInvitation.invitedUserEmailAddress}
                </h3>
                <div className={styles.descriptorContainer}>
                    <span className={styles.email}>
                        User Invitation Sent
                    </span>
                </div>
            </div>
            <div onClick={ev => ev.stopPropagation()}>
                <OrganizationMemberInvitationContextMenu
                    organizationMemberInvitation={organizationMemberInvitation}
                >
                    <Button icon={<EllipsisOutlined style={{ fontSize: 20 }} />} />
                </OrganizationMemberInvitationContextMenu>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        backgroundColor: Colors.cardBackground,
        borderRadius: BorderRadius.more,
        padding: Padding.default,
        display: 'grid',
        gridAutoFlow: 'column',
        cursor: 'pointer',
        position: 'relative',
        border: '2px solid transparent',
        transition: 'border 100ms ease',
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        gap: Gap.default,
        '&:hover': {
            border: `2px solid ${grey.primary}`
        },
    },
    imageContainer: {
        borderRadius: 4,
        overflow: 'hidden',
        opacity: 0.85,
        position: 'relative',
    },
    qrCodeTitleBar: {
    },
    qrCodeActionName: {
        maxWidth: '100%',
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: 500,
        fontSize: 18,
    },
    active: {
        borderColor: grey.primary,
    },
    color: {
        width: 24,
        height: 24,
        borderRadius: 2,
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
    },
    detailsContainer: {
        display: 'grid',
        gridTemplateRows: 'auto auto',

        '& h3': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    },
    descriptorContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    email: {
        fontStyle: 'italic',
        fontSize: FontSize.default,
    },
    icon: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: BorderRadius.less,
        padding: 12,
        fontSize: FontSize.default,
        backgroundColor: grey.primary,
    },
})