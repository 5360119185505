import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type ViewContainerProps = {
	children: React.ReactNode
}

export const ViewContainer: FC<ViewContainerProps> = ({
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.viewContainer}>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	viewContainer: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		height: '100%',
	}
})