import { Route } from "react-router-dom";
import { GroupRoute } from "../../../../../../routes/app-route/group-route/GroupRoute";
import { GroupRouteName } from "../../../../../groups/group-route-name/group-route-name";
import { ConstellationRoutes } from "./constellation-routes";

export const GroupRoutes = (
    <Route path=":group_id" handle={{ RouteNameComponent: GroupRouteName }}>
        <Route index element={<GroupRoute />} />
        <Route path="constellations">
            {ConstellationRoutes}
        </Route>
        <Route path="groups" handle={{ lazyChildren: () => GroupRoutes }} />
    </Route>
)