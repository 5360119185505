import { CSSProperties, FC, useState } from "react"
import Measure, { ContentRect } from 'react-measure'
import { ContentContainer, ContentInnerContainer } from "./content.styled"

type ContentProps = {
    className?: string
    children: React.ReactNode
    style?: CSSProperties
    innerStyle?: CSSProperties
}

export const Content: FC<ContentProps> = ({
    className,
    children,
    style,
    innerStyle,
}) => {

    // hooks
    const [maxHeight, setMaxHeight] = useState<number>()

    // event handlers
    const handleResize = (contentRect: ContentRect) => {
        if (contentRect.offset) {
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            const newMaxHeight = vh - contentRect.offset.top
            setMaxHeight(newMaxHeight)
        }
    }

    return (
        <Measure offset onResize={handleResize}>
            {({ measureRef }) => (
                <ContentContainer
                    ref={measureRef}
                    className={className}
                    style={style}
                >
                    <ContentInnerContainer
                        maxHeight={maxHeight}
                        style={innerStyle}
                    >
                        {children}
                    </ContentInnerContainer>
                </ContentContainer>
            )}
        </Measure >
    )
}