import { NavLinkProps, To } from "react-router-dom";
import { UnsafeRouterContext } from "../../hooks/use-unsafe-router-context/use-unsafe-router-context.types";
import { getResolvedPath } from "./router-is-link-active.utils";

export const routerIsLinkActive = (
    to: To,
    unsafeRouterContext: UnsafeRouterContext,
    options?: NavLinkProps
): boolean => {

    // context
    const path = getResolvedPath(to, unsafeRouterContext, options);
    const { navigator } = unsafeRouterContext.navigationContext;
    let { pathname: locationPathname } = unsafeRouterContext.location;

    // constants
    let toPathname = navigator.encodeLocation
        ? navigator.encodeLocation(path).pathname
        : path.pathname;

    if (!options?.caseSensitive) {
        locationPathname = locationPathname.toLowerCase();
        toPathname = toPathname.toLowerCase();
    }

    const isActive =
        locationPathname === toPathname ||
        (!options?.end &&
            locationPathname.startsWith(toPathname) &&
            locationPathname.charAt(toPathname.length) === "/");

    return isActive;
}