import { FC, ReactNode } from "react";
import { DialogTitleContainer, IconContainer, TitleText } from "./dialog-title.styled";

type DialogTitleProps = {
    icon?: ReactNode
    children?: ReactNode
}

export const DialogTitle: FC<DialogTitleProps> = ({
    icon,
    children,
}) => {
    return (
        <DialogTitleContainer>
            {icon ? (
                <IconContainer>
                    {icon}
                </IconContainer>
            ) : (
                <img
                    src="/images/logo/cqr23_icons_Dark BG.svg"
                    alt="Constellation QR Logo"
                    width={48}
                />
            )}
            <TitleText>
                {children}
            </TitleText>
        </DialogTitleContainer>
    )
}