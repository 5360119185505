import { FC, useCallback, useState } from "react";
import { AdminToolGridItem } from "../admin-tool-grid-item/admin-tool-grid-item";
import { faCodeMerge } from "@fortawesome/pro-regular-svg-icons/faCodeMerge";
import { MergeAccountsDialog } from "../../../../../../../users/merge-accounts-dialog/merge-accounts-dialog";

export const MergeAccountsGridItem: FC = () => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleGridItemClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogDismiss = useCallback(() => {
        setDialogOpen(false)
    }, [])
    
    return (
        <>
            <AdminToolGridItem
                title="Merge Accounts"
                subtitle="Merge two accounts into one"
                icon={faCodeMerge}
                onClick={handleGridItemClick}
            />
            <MergeAccountsDialog
                isOpen={dialogOpen}
                onDismiss={handleDialogDismiss}
            />
        </>
    )
}