/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { PaginationFragmentFragmentDoc } from '../../../../../app/providers/types-provider/TypesProvider.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../../../common/graphql/fetcher';
export type PreferencesOrganizationSelector_OrganizationsQueryVariables = Types.Exact<{
  cursor: Types.InputMaybe<Types.Scalars['Cursor']>;
}>;


export type PreferencesOrganizationSelector_OrganizationsQuery = { organizationsCollection: { edges: Array<{ node: { id: any, name: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null };


export const PreferencesOrganizationSelector_OrganizationsDocument = `
    query PreferencesOrganizationSelector_Organizations($cursor: Cursor) {
  organizationsCollection(orderBy: {name: AscNullsLast}, after: $cursor) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragmentFragmentDoc}`;
export const usePreferencesOrganizationSelector_OrganizationsQuery = <
      TData = PreferencesOrganizationSelector_OrganizationsQuery,
      TError = unknown
    >(
      variables?: PreferencesOrganizationSelector_OrganizationsQueryVariables,
      options?: UseQueryOptions<PreferencesOrganizationSelector_OrganizationsQuery, TError, TData>
    ) =>
    useQuery<PreferencesOrganizationSelector_OrganizationsQuery, TError, TData>(
      variables === undefined ? ['PreferencesOrganizationSelector_Organizations'] : ['PreferencesOrganizationSelector_Organizations', variables],
      fetcher<PreferencesOrganizationSelector_OrganizationsQuery, PreferencesOrganizationSelector_OrganizationsQueryVariables>(PreferencesOrganizationSelector_OrganizationsDocument, variables),
      options
    );

usePreferencesOrganizationSelector_OrganizationsQuery.getKey = (variables?: PreferencesOrganizationSelector_OrganizationsQueryVariables) => variables === undefined ? ['PreferencesOrganizationSelector_Organizations'] : ['PreferencesOrganizationSelector_Organizations', variables];
;

export const useInfinitePreferencesOrganizationSelector_OrganizationsQuery = <
      TData = PreferencesOrganizationSelector_OrganizationsQuery,
      TError = unknown
    >(
      pageParamKey: keyof PreferencesOrganizationSelector_OrganizationsQueryVariables,
      variables?: PreferencesOrganizationSelector_OrganizationsQueryVariables,
      options?: UseInfiniteQueryOptions<PreferencesOrganizationSelector_OrganizationsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PreferencesOrganizationSelector_OrganizationsQuery, TError, TData>(
      variables === undefined ? ['PreferencesOrganizationSelector_Organizations.infinite'] : ['PreferencesOrganizationSelector_Organizations.infinite', variables],
      (metaData) => fetcher<PreferencesOrganizationSelector_OrganizationsQuery, PreferencesOrganizationSelector_OrganizationsQueryVariables>(PreferencesOrganizationSelector_OrganizationsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfinitePreferencesOrganizationSelector_OrganizationsQuery.getKey = (variables?: PreferencesOrganizationSelector_OrganizationsQueryVariables) => variables === undefined ? ['PreferencesOrganizationSelector_Organizations.infinite'] : ['PreferencesOrganizationSelector_Organizations.infinite', variables];
;

usePreferencesOrganizationSelector_OrganizationsQuery.fetcher = (variables?: PreferencesOrganizationSelector_OrganizationsQueryVariables, options?: RequestInit['headers']) => fetcher<PreferencesOrganizationSelector_OrganizationsQuery, PreferencesOrganizationSelector_OrganizationsQueryVariables>(PreferencesOrganizationSelector_OrganizationsDocument, variables, options);