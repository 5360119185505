/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { NotificationActionCoordinator_NotificationFragmentDoc } from './notification-action-coordinator/NotificationActionCoordinator.generated';
import { NotificationTitleCoordinator_NotificationFragmentDoc } from './notification-title-coordinator/NotificationTitleCoordinator.generated';
export type NotificationCoordinator_NotificationFragment = { id: any, timestamp: any | null, organizationMemberInvitationId: any | null, notificationType: { id: any, name: string, code: string, icon: string | null, iconColor: string | null, objectName: string | null } | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedByUser: { id: any, firstName: string | null, lastName: string | null } | null, invitedUser: { id: any, firstName: string | null, lastName: string | null } | null } | null };

export const NotificationCoordinator_NotificationFragmentDoc = `
    fragment NotificationCoordinator_Notification on Notifications {
  id
  timestamp
  notificationType {
    id
    name
    code
    icon
    iconColor
    objectName
  }
  ...NotificationActionCoordinator_Notification
  ...NotificationTitleCoordinator_Notification
}
    ${NotificationActionCoordinator_NotificationFragmentDoc}
${NotificationTitleCoordinator_NotificationFragmentDoc}`;