import { blue, green, orange } from '@ant-design/colors'
import Button from 'antd/es/button'
import message from 'antd/es/message'
import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { api } from '../../../common/api/api'
import { Colors } from '../../../common/colors/colors'
import { Gap, Padding } from '../../../common/constants/constants'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { SelfContext } from '../../app/providers/self-provider/SelfProvider'

type VerifyEmailFormProps = {}

export const VerifyEmailForm: FC<VerifyEmailFormProps> = () => {

	// styles
	const styles = useStyles()

	// state
	const [resendVerificationLoading, setResendVerificationLoading] = useState<boolean>(false)
	const [linkSent, setLinkSent] = useState<boolean>(false)

	// context
	const { logAppEvent } = useContext(AppEventContext)
	const { selfUser } = useContext(SelfContext)

	// constants
	const emailVerified = true; // TODO: fix me

	// event handlers
	const handleResendVerificationCodeClick = useCallback(async () => {
		setResendVerificationLoading(true)

		try {
			await api.auth.sendEmailVerificationEmail(selfUser?.id)

			await logAppEvent('requested_email_verification_link', {})
	
			message.success('Successfully resent verification email. Please check your email.')
			setLinkSent(true)
			setResendVerificationLoading(false)
		} catch (error) {
			setLinkSent(false)
			setResendVerificationLoading(false)
			message.error('Something went wrong. Please try again later.')
		}
	}, [logAppEvent, selfUser?.id])

	if (emailVerified) {
		return (
			<span>Your email address is <strong style={{ color: green.primary }}>verified</strong>.</span>
		)
	}

	return (
		<div className={styles.changePrimaryEmailForm}>
			<div style={{ marginBottom: Padding.default }}>
				<span>Your email address is <strong style={{ color: orange.primary }}>not verified</strong>.</span>
				<br />
				<br />
				<span>Please check your email for a link to verify your account. If you don't see the email, click the button below and we'll send a new one to you.</span>
				<br />
				<br />
				{linkSent && (
					<>
						<strong style={{ color: blue.primary }}>If you verified your email just now, you may need to refresh this page for this message to go away.</strong>
						<br />
						<br />
					</>
				)}
			</div>
			<div className={styles.formFooter}>
				<Button type="primary" onClick={handleResendVerificationCodeClick} loading={resendVerificationLoading}>
					Resend Verification Email
				</Button>
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	changePrimaryEmailForm: {
		display: 'grid'
	},
	form: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: Gap.default,
	},
	formFooter: {
		display: 'grid',
		// justifyContent: 'end',
		gridAutoFlow: 'column',
		gap: Gap.default,
	},
	resendCodeButton: {
		alignSelf: 'end',
		marginBottom: 13,
		backgroundColor: Colors.cardBackgroundHover,
	}
})