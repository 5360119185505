/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type OrganizationMemberContextMenu_OrganizationMemberFragment = { id: any, userId: any | null };

export const OrganizationMemberContextMenu_OrganizationMemberFragmentDoc = `
    fragment OrganizationMemberContextMenu_OrganizationMember on OrganizationMembers {
  id
  userId
}
    `;