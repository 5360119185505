import { FC, useMemo } from "react";
import { RouteNameProps } from "../../common/route-name/route-name.types";
import { useScheduleRouteName_ScheduleQuery } from "./schedule-route-name.generated";

export const ScheduleRouteName: FC<RouteNameProps> = ({
    data,
}) => {

    // params
    const { schedule_id: scheduleId } = data.params as { schedule_id: string }

    // data hooks
    const { data: schedules } = useScheduleRouteName_ScheduleQuery({ scheduleId })

    // data abstraction
    const schedule = useMemo(() => (
        schedules?.schedulesCollection?.edges[0]?.node
    ), [schedules?.schedulesCollection?.edges])

    if (!schedule) return null

    return (
        <span>
            {schedule.name}
        </span>
    )
}