import { FC, useCallback, useEffect } from "react";
import { LogoImage, MenuHeader } from "./mobile-nav-menu.styled";
import { Link, useLocation } from "react-router-dom";
import { PreferencesNavSections } from "../../../../../preferences-side-bar/subcomponents/preferences-nav-sections/preferences-nav-sections";
import { Dialog } from "../../../../../../../../../common/dialog/dialog";
import { DialogBody } from "../../../../../../../../../common/dialog/subcomponents/dialog-body/dialog-body";
import { AppMenu } from "../../../../../../../../../app/components/app-shell/subcomponents/app-menu/app-menu";

type MobileNavMenuProps = {
    open: boolean
    onDismiss?: () => void
}

export const MobileNavMenu: FC<MobileNavMenuProps> = ({
    open,
    onDismiss,
}) => {

    // router hooks
    const location = useLocation()

    // event handlers
    const handleDialogClose = useCallback(() => {
        onDismiss && onDismiss()
    }, [onDismiss])

    // side effects
    useEffect(() => {
        handleDialogClose()
    }, [handleDialogClose, location.pathname])

    return (
        <Dialog
            open={open}
            onClose={handleDialogClose}
        >
            <DialogBody>
                <MenuHeader>
                    <Link to="/">
                        <LogoImage
                            src="/images/logo/cqr23_logos_Horizontal Dark BG.svg"
                        />
                    </Link>
                </MenuHeader>
                <AppMenu />
                {/* <OrganizationSectionContainer>
                    <PreferencesOrganizationSelector />
                </OrganizationSectionContainer> */}
                <PreferencesNavSections />
            </DialogBody>
        </Dialog>
    )
}