import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import { CopyrightContainer, CopyrightText } from "./copyright.styled";

export const Copyright: FC = () => {

    const currentYear = useMemo(() => DateTime.now().year, [])

    return (
        <CopyrightContainer>
            <CopyrightText>
                Ⓒ {currentYear} Constellation QR LLC • All Rights Reserved
            </CopyrightText>
        </CopyrightContainer>
    )
}