/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { OrganizationMemberRemoveMemberForm_OrganizationMemberFragmentDoc } from '../../../organization-members/organization-member-remove-user-form/organization-member-remove-user-form.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type OrganizationMemberView_OrganizationMemberQueryVariables = Types.Exact<{
  organizationMemberId: Types.Scalars['UUID'];
  organizationId: Types.Scalars['UUID'];
}>;


export type OrganizationMemberView_OrganizationMemberQuery = { organizationMembersCollection: { edges: Array<{ node: { id: any, userId: any | null, organizationId: any | null, user: { firstName: string | null, lastName: string | null } | null } }> } | null };


export const OrganizationMemberView_OrganizationMemberDocument = `
    query OrganizationMemberView_OrganizationMember($organizationMemberId: UUID!, $organizationId: UUID!) {
  organizationMembersCollection(
    filter: {id: {eq: $organizationMemberId}, isDeleted: {eq: false}, organizationId: {eq: $organizationId}}
  ) {
    edges {
      node {
        id
        user {
          firstName
          lastName
        }
        ...OrganizationMemberRemoveMemberForm_OrganizationMember
      }
    }
  }
}
    ${OrganizationMemberRemoveMemberForm_OrganizationMemberFragmentDoc}`;
export const useOrganizationMemberView_OrganizationMemberQuery = <
      TData = OrganizationMemberView_OrganizationMemberQuery,
      TError = unknown
    >(
      variables: OrganizationMemberView_OrganizationMemberQueryVariables,
      options?: UseQueryOptions<OrganizationMemberView_OrganizationMemberQuery, TError, TData>
    ) =>
    useQuery<OrganizationMemberView_OrganizationMemberQuery, TError, TData>(
      ['OrganizationMemberView_OrganizationMember', variables],
      fetcher<OrganizationMemberView_OrganizationMemberQuery, OrganizationMemberView_OrganizationMemberQueryVariables>(OrganizationMemberView_OrganizationMemberDocument, variables),
      options
    );

useOrganizationMemberView_OrganizationMemberQuery.getKey = (variables: OrganizationMemberView_OrganizationMemberQueryVariables) => ['OrganizationMemberView_OrganizationMember', variables];
;

export const useInfiniteOrganizationMemberView_OrganizationMemberQuery = <
      TData = OrganizationMemberView_OrganizationMemberQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationMemberView_OrganizationMemberQueryVariables,
      variables: OrganizationMemberView_OrganizationMemberQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationMemberView_OrganizationMemberQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationMemberView_OrganizationMemberQuery, TError, TData>(
      ['OrganizationMemberView_OrganizationMember.infinite', variables],
      (metaData) => fetcher<OrganizationMemberView_OrganizationMemberQuery, OrganizationMemberView_OrganizationMemberQueryVariables>(OrganizationMemberView_OrganizationMemberDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationMemberView_OrganizationMemberQuery.getKey = (variables: OrganizationMemberView_OrganizationMemberQueryVariables) => ['OrganizationMemberView_OrganizationMember.infinite', variables];
;

useOrganizationMemberView_OrganizationMemberQuery.fetcher = (variables: OrganizationMemberView_OrganizationMemberQueryVariables, options?: RequestInit['headers']) => fetcher<OrganizationMemberView_OrganizationMemberQuery, OrganizationMemberView_OrganizationMemberQueryVariables>(OrganizationMemberView_OrganizationMemberDocument, variables, options);