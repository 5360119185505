/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type InvitedToOrganizationNotificationAction_NotificationFragment = { id: any, organizationMemberInvitationId: any | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedUser: { id: any, firstName: string | null, lastName: string | null } | null } | null };

export const InvitedToOrganizationNotificationAction_NotificationFragmentDoc = `
    fragment InvitedToOrganizationNotificationAction_Notification on Notifications {
  id
  organizationMemberInvitationId
  organizationMemberInvitation {
    invitedToOrganization {
      id
      name
    }
    invitedUser {
      id
      firstName
      lastName
    }
  }
}
    `;