"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownloadQRCodePayload = exports.QRCodeFileType = void 0;
const class_validator_1 = require("class-validator");
var QRCodeFileType;
(function (QRCodeFileType) {
    QRCodeFileType["PNG"] = "PNG";
    QRCodeFileType["SVG"] = "SVG";
    QRCodeFileType["PDF"] = "PDF";
})(QRCodeFileType = exports.QRCodeFileType || (exports.QRCodeFileType = {}));
class DownloadQRCodePayload {
}
__decorate([
    (0, class_validator_1.IsNumber)()
], DownloadQRCodePayload.prototype, "size", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(QRCodeFileType)
], DownloadQRCodePayload.prototype, "fileType", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], DownloadQRCodePayload.prototype, "fileName", void 0);
exports.DownloadQRCodePayload = DownloadQRCodePayload;
