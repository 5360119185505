/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { AddGroupMemberForm_GroupFragmentDoc } from './subcomponents/add-group-member-form/AddGroupMemberForm.generated';
export type AddGroupMemberModal_GroupFragment = { id: any, addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const AddGroupMemberModal_GroupFragmentDoc = `
    fragment AddGroupMemberModal_Group on Groups {
  id
  ...AddGroupMemberForm_Group
}
    ${AddGroupMemberForm_GroupFragmentDoc}`;