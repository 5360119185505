import { UpdateOrganizationPayload, UpdateOrganizationResult } from "constellation-sdk";
import { api } from "../../../../common/api/api";

class OrganizationNamePanelService {
    public async updateOrganization(organizationId: string, payload: UpdateOrganizationPayload): Promise<UpdateOrganizationResult> {
        let result: UpdateOrganizationResult | undefined = undefined;

        try {
            result = await api.organizations.updateOrganization(
                organizationId,
                payload,
            )
        } catch {}

        if (!result) {
            result = new UpdateOrganizationResult();
            result.success = false
        }

        return result;
    }
}

export const service = new OrganizationNamePanelService();