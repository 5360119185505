import styled from "@emotion/styled";
import { Colors } from "../../../../common/colors/colors";
import { BorderRadius } from "../../../../common/constants/constants";

export const WebsiteDemoWidgetContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: '2fr 3fr',

    /* border */
    border: `1px solid ${Colors.border}`,
    borderRadius: BorderRadius.default,

    /* size */
    width: '100vw',
    height: '100vh',
})

export const ConstellationSection = styled.div({

})

export const ActionsSection = styled.div({

})