import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import Avatar from "antd/es/avatar";
import Dropdown from "antd/es/dropdown";
import { FC, useCallback, useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { AppShellContext } from "../../app-shell-context";
import { SelfContext } from "../../../../providers/self-provider/SelfProvider";
import { ContextMenuProvider } from "../../../../../common/context-menu-provider/context-menu-provider";
import { AppMenuContainer } from "./app-menu.styled";
import { AppMenuActions } from "../app-menu-actions/AppMenuActions";
import { AppMenuSettings } from "../app-menu-settings/AppMenuSettings";
import { AppMenuUserProfile } from "../app-menu-user-profile/AppMenuUserProfile";

export const AppMenu: FC = () => {

    // context
    const { selfUser } = useContext(SelfContext)
    const { sidebarCollapsed } = useContext(AppShellContext)

    // styles
    const styles = useStyles()

    // state
    const [menuOpen, setMenuOpen] = useState<boolean>(false)

    // constants
    const initials = (selfUser?.firstName?.[0] ?? '') + (selfUser?.lastName?.[0] ?? '')
    const fullName = `${selfUser?.firstName ?? ''} ${selfUser?.lastName ?? ''}`

    // event handlers
    const handleMenuClose = useCallback(() => {
        setMenuOpen(false)
    }, [])

    const handleMenuOpenChange = useCallback((newMenuOpen: boolean) => {
        setMenuOpen(newMenuOpen)
    }, [])

    return (
        <ContextMenuProvider onMenuClose={handleMenuClose}>
            <Dropdown overlay={(
                <>
                    <AppMenuUserProfile />
                    <AppMenuSettings />
                    <AppMenuActions />
                </>
            )} overlayClassName={styles.dropdownMenuContainer} trigger={['click']} open={menuOpen} onOpenChange={handleMenuOpenChange}>
                <AppMenuContainer>
                    {sidebarCollapsed ? (
                        <Avatar>{initials}</Avatar>
                    ) : (
                        <>
                            {fullName}
                            <CaretDownOutlined />
                        </>
                    )}
                </AppMenuContainer>
            </Dropdown>
        </ContextMenuProvider>
    )
}

const useStyles = createUseStyles({
    dropdownMenuContainer: {
        backgroundColor: 'white',
        color: 'rgb(40, 40, 40)',
        borderRadius: 8,
        paddingTop: 8,
        minWidth: '282px !important',
    }
})