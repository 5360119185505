import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Mosaic, MosaicNode, MosaicWindow } from 'react-mosaic-component'
import { mosaicStyleOverrides } from '../../../common/constants/mosaic-style-overrides'
import { ViewPanelHeader } from '../view-panels/subcomponents/view-panel-header/ViewPanelHeader'
import { Panel } from '../view-panels/ViewPanels'
import { useDragDropManager } from 'react-dnd'
import { Loading } from '../loading/Loading'

type PanelsMosaicProps = {
	panels: Panel<any>[]
	layoutId: string
	defaultLayout: MosaicNode<any>
}

export const PanelsMosaic: FC<PanelsMosaicProps> = ({
	panels,
	layoutId,
	defaultLayout,
}) => {

	// styles
	const styles = useStyles()

	// drag and drop
	const dragDropManager = useDragDropManager()

	// state
	const initialMosaicLayout: MosaicNode<any> | undefined = useMemo(() => {
		const storedState = window.localStorage.getItem(layoutId)
		if (!storedState) return
		return JSON.parse(storedState) as MosaicNode<any>
	}, [layoutId])

	const [mosaicLayout, setMosaicLayout] = useState<MosaicNode<any> | null>(initialMosaicLayout ?? defaultLayout)

	// constants
	const panelsMap = useMemo(() => {
		const newPanelsMap: { [key: string]: Panel<any> } = {}
		for (const panel of panels) {
			newPanelsMap[panel.id] = panel
		}
		return newPanelsMap
	}, [panels])

	// refs
	const containerRef = useRef<HTMLDivElement>(null)

	// event handlers
	const handleMosaicLayoutChange = useCallback((node: MosaicNode<any> | null) => {
		setMosaicLayout(node)
		window.localStorage.setItem(layoutId, JSON.stringify(node))
	}, [layoutId])

	const handleHeaderDoubleClick = useCallback(() => {
		handleMosaicLayoutChange(defaultLayout)
	}, [handleMosaicLayoutChange, defaultLayout])

	const handleSplitLineDoubleClick = useCallback(() => {
		handleMosaicLayoutChange(defaultLayout)
	}, [handleMosaicLayoutChange, defaultLayout])

	// side effects
	useEffect(() => {
		const viewSplits: NodeListOf<HTMLDivElement> | undefined = containerRef.current?.querySelectorAll('.mosaic-split')
		if (viewSplits) {
			for (var i = 0; i < viewSplits.length; i++) {
				const viewSplit = viewSplits[i]
				viewSplit.addEventListener('dblclick', handleSplitLineDoubleClick)
			}
			return () => {
				for (var i = 0; i < viewSplits.length; i++) {
					const viewSplit = viewSplits[i]
					viewSplit.removeEventListener('dblclick', handleSplitLineDoubleClick)
				}	
			}
		}
	}, [handleSplitLineDoubleClick])

	if (!dragDropManager) return <Loading />

	return (
		<div className={styles.panelsMosaic} ref={containerRef}>
			<Mosaic<any>
				renderTile={(id, path) => (
					<MosaicWindow<any> path={path} title={panelsMap[id]?.label} renderToolbar={() => (
						<div className={styles.panelHeader} onDoubleClick={handleHeaderDoubleClick}>
							<ViewPanelHeader panel={panelsMap[id]} />
						</div>
					)}>
						{React.createElement(panelsMap[id]?.component, panelsMap[id]?.props as any)}
					</MosaicWindow>
				)}
				value={mosaicLayout}
				initialValue={mosaicLayout}
				onChange={handleMosaicLayoutChange}
				dragAndDropManager={dragDropManager}
			/>
		</div>
	)
}

const useStyles = createUseStyles({
	panelsMosaic: {
		width: '100%',
		height: '100%',

		...mosaicStyleOverrides
	},
	panelHeader: {
        width: '100%',
        height: '100%',
    },
})