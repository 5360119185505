import { purple } from "@ant-design/colors";
import { css } from "@emotion/react";
import { Colors } from "../../../../../common/colors/colors";

export const TabBarButtonLinkStyles = css({
    /* layout */
    display: 'grid',
    alignItems: 'center',
    gap: 6,
    
    /* position */
    position: 'relative',

    /* text */
    color: Colors.text,

    /* padding */
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 12,

    /* interaction */
    cursor: 'pointer',

    /* animation */
    transition: 'all 100ms ease',

    /* hover */
    '&:hover': {
        color: purple[4],
    }
})

export const ActiveTabBarButtonLinkStyles = css({
    /* text */
    color: purple[4],

    /* after */
    '&:after': {
        /* content */
        content: '""',

        /* position */
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        height: 2,

        /* background */
        backgroundColor: purple[4],
    },
})

export const TooltipStyles = css({
    paddingTop: 4,

    '& .ant-tooltip-inner': {
        textAlign: 'left',
        lineHeight: '1.1rem',
    }
})