import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GroupDragReceiverContainer, HoverIndicator } from "./group-drag-receiver.styled";
import { useDrop } from "react-dnd";
import { service } from "./group-drag-receiver.service";
import Spin from "antd/es/spin";
import message from "antd/es/message";
import { useQueryClient } from "@tanstack/react-query";
import { SelfContext } from "../../../app/providers/self-provider/SelfProvider";
import { queryInvalidationHelper } from "../../../../common/react-query/query-invalidation.helper";
import { ClearGroupParentDragReceiverContext } from "../clear-group-parent-drag-receiver/clear-group-parent-drag-receiver";
import { captureParentGroupIds } from "../utils/group.utils";
import { OrganizationContext } from "../../../organizations/common/organization-provider/organization-provider";
import { GroupDragReceiver_GroupFragment } from "./group-drag-receiver.generated";
import { useShiftKeyRef } from "../../../../common/hooks/use-shift-key-ref/use-shift-key-ref";

type GroupDragReceiverProps = {
    group: GroupDragReceiver_GroupFragment
    children: ReactNode
}

export const GroupDragReceiver: FC<GroupDragReceiverProps> = ({
    group,
    children
}) => {

    // react query
    const queryClient = useQueryClient()

    // context
    const { selfUser } = useContext(SelfContext)
    const { onActiveHoverGroupIdChange } = useContext(ClearGroupParentDragReceiverContext)
    const { allGroups } = useContext(OrganizationContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // refs
    const shiftKeyRef = useShiftKeyRef();

    // constants
    const allParentIds = useMemo(() => {
		return captureParentGroupIds(allGroups, group)
	}, [allGroups, group]);
    
    // event handlers
    const handleGroupDrop = useCallback(async (item: { id: string, organizationId: string }) => {
        /* start the loading state */
        setIsLoading(true)

        /* update the group parent */
        const result = await service.updateGroupParent(
            item.id,
            { parentGroupId: group.id },
        );

        /* handle any errors that occurred */
        if (!result.success || !result.group) {
            message.error(`Something went wrong. Please try again.`)
        } else {
            message.success(`Successfully moved group.`)
        }

        /* invalidate relevant queries */
        queryClient.invalidateQueries(
            queryInvalidationHelper([
                { userId: selfUser?.id },
                { groupId: item.id },
                { organizationId: item.organizationId },
            ])
        )
        
        /* clear the loading state */
        setIsLoading(false)
    }, [group.id, queryClient, selfUser?.id])

    const handleConstellationDrop = useCallback(async (item: { id: string, groupId: string | null, organizationId: string }, mode: 'move' | 'append') => {
        /* start the loading state */
        setIsLoading(true)

        /* add the constellation to the new group */
        const addConstellationResult = await service.addConstellationToGroup(
            group.id,
            item.id,
        );

        if (!addConstellationResult.success && addConstellationResult.errorCode === 'group_constellation_link_already_exists') {
            message.info('Your QR Code is already in that group (nothing to change)');
        } else if (!addConstellationResult.success) {
            message.error('Something wen\'t wrong. Please try again.');
            setIsLoading(false);
            return;
        }

        if (item.groupId && mode === 'move') {
            const removeConstellationResult = await service.removeConstellationFromGroup(
                item.groupId,
                item.id,
            );

            if (!removeConstellationResult.success) {
                message.warn('Something wen\'t wrong: your QR Code was added to the new group but not removed from the old one. Please try removing it manually.');
            }
        }

        /* invalidate relevant queries */
        queryClient.invalidateQueries(
            queryInvalidationHelper([
                { userId: selfUser?.id },
                { groupId: group.id },
                { groupId: item.groupId },
                { constellationId: item.id },
                { organizationId: item.organizationId },
            ])
        )

        /* clear the loading state */
        setIsLoading(false)
    }, [group.id, queryClient, selfUser?.id])

    // drag and drop
    const [{ isOver, isOverNoOp }, drop] = useDrop({
        accept: ['group', 'constellation'],
        collect: monitor => ({
            isOver: monitor.isOver() && monitor.canDrop(),
            isOverNoOp: monitor.isOver() && !monitor.canDrop(),
        }),
        canDrop: (item, monitor) => {
            const itemType = monitor.getItemType();

            if ((item as { organizationId: string }).organizationId !== group.organizationId) {
                return false;
            }

            if (itemType === 'group') {
                return (
                    (item as { id: string }).id !== group.id
                    && !allParentIds.includes((item as { id: string }).id)
                )
            } else if (itemType === 'constellation') {
                return true;
            }

            return false;
        },
        drop: (item, monitor) => {
            if (!monitor.canDrop()) return;

            const itemType = monitor.getItemType();
            if (itemType === 'group') {
                handleGroupDrop(item as { id: string, organizationId: string })
            } else if (itemType === 'constellation') {
                handleConstellationDrop(
                    item as { id: string, groupId: string | null, organizationId: string },
                    shiftKeyRef.current ? 'append' : 'move'
                )
            }
        },
    })

    // side effects
    useEffect(() => {
        onActiveHoverGroupIdChange(currentActiveHoverGroupId => {
            if (isOver) return group.id;
            if (isOverNoOp) return group.id;
            if (currentActiveHoverGroupId === group.id) return null;
            return currentActiveHoverGroupId;
        })
    }, [group.id, isOver, isOverNoOp, onActiveHoverGroupIdChange])

    if (isLoading) {
        return (
            <Spin>
                {children}
            </Spin>
        )
    }

    return (
        <GroupDragReceiverContainer ref={drop}>
            {children}
            <HoverIndicator
                isOver={isOver}
                isOverNoOp={isOverNoOp}
            />
        </GroupDragReceiverContainer>
    )
}