import { css } from "@emotion/react";

export const BreadcrumbStyles = css({
    // text
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
    whiteSpace: 'nowrap',
    lineHeight: '1rem',

    // animation
    transition: 'color 100ms ease',

    // hover
    ':hover': {
        color: 'rgba(255, 255, 255, 0.75)',
    },
})