/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type NotificationTitleCoordinator_NotificationFragment = { id: any, notificationType: { id: any, code: string } | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedByUser: { id: any, firstName: string | null, lastName: string | null } | null } | null };

export const NotificationTitleCoordinator_NotificationFragmentDoc = `
    fragment NotificationTitleCoordinator_Notification on Notifications {
  id
  notificationType {
    id
    code
  }
  organizationMemberInvitation {
    invitedToOrganization {
      id
      name
    }
    invitedByUser {
      id
      firstName
      lastName
    }
  }
}
    `;