import { FC, ReactNode, createContext, useCallback, useMemo } from "react";
import { BillingPortalSessionFlowType, GetSubscriptionDetailsResult } from "constellation-sdk";
import { useSubscriptionDetails } from "./organization-provider.hooks";
import { EntitlementCode } from "constellation-types";
import { service } from "./organization-provider.service";
import message from 'antd/es/message';
import { OrganizationProvider_GroupFragment, useOrganizationProvider_OrganizationQuery } from "./organization-provider.generated";

type OrganizationProviderProps = {
    organizationId: string
    children: ReactNode
}

export const OrganizationProvider: FC<OrganizationProviderProps> = ({
    organizationId,
    children,
}) => {

    // data hooks
    const { subscriptions, activeEntitlements } = useSubscriptionDetails(organizationId);
    const { data } = useOrganizationProvider_OrganizationQuery({ organizationId })

    // constants
    const activeEntitlementCodes = useMemo(() => (
        activeEntitlements?.map(activeEntitlement => activeEntitlement.lookup_key as EntitlementCode) ?? null
    ), [activeEntitlements])

    const currentSubscription = useMemo(() => (
        subscriptions?.[0] ?? null
    ), [subscriptions])

    const allGroups = useMemo(() => (
        data?.organizationsCollection?.edges
            .flatMap(edge => (
                edge.node.groupsCollection?.edges
                .map(edge => edge.node as NonNullable<typeof edge.node>)
            ))
            .map(group => group as NonNullable<typeof group>) ?? null
    ), [data?.organizationsCollection?.edges])

    // event handlers
    const handleOpenCustomerBillingPortal = useCallback(async (flowType: BillingPortalSessionFlowType) => {
        const billingPortalSession = await service.createBillingPortalSession(
            organizationId,
            flowType,
        )

        if (!billingPortalSession.success || !billingPortalSession.stripeBillingPortalSession) {
            message.error('Something went wrong: cannot open billing portal. Please try again soon.');
            return;
        }

        window.open(billingPortalSession.stripeBillingPortalSession.url)
    }, [organizationId])

    if (!subscriptions) return null;
    if (!activeEntitlements) return null;
    if (!allGroups) return null;

    return (
        <OrganizationContext.Provider value={{
            currentSubscription,
            subscriptions,
            activeEntitlements,
            activeEntitlementCodes,
            openCustomerBillingPortal: handleOpenCustomerBillingPortal,
            allGroups,
        }}>
            {children}
        </OrganizationContext.Provider>
    )
}

type OrganizationContextType = {
    currentSubscription: ElementType<GetSubscriptionDetailsResult['subscriptions']> | null;
    subscriptions: GetSubscriptionDetailsResult['subscriptions'] | null;
    activeEntitlements: GetSubscriptionDetailsResult['activeEntitlements'] | null;
    activeEntitlementCodes: EntitlementCode[] | null;
    openCustomerBillingPortal: (flowType: BillingPortalSessionFlowType) => void;
    allGroups: OrganizationProvider_GroupFragment[];
}

export const OrganizationContext = createContext<OrganizationContextType>({
    currentSubscription: null,
    subscriptions: null,
    activeEntitlements: null,
    activeEntitlementCodes: null,
    openCustomerBillingPortal: () => {},
    allGroups: [],
})

type ElementType<T> = T extends (infer U)[] ? U : T;