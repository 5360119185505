/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { InvitedToOrganizationNotificationAction_NotificationFragmentDoc } from '../../notification-actions/invited-to-organization-notification-actions/invited-to-organization-notification-actions.generated';
export type NotificationActionCoordinator_NotificationFragment = { id: any, organizationMemberInvitationId: any | null, notificationType: { id: any, name: string, code: string } | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedUser: { id: any, firstName: string | null, lastName: string | null } | null } | null };

export const NotificationActionCoordinator_NotificationFragmentDoc = `
    fragment NotificationActionCoordinator_Notification on Notifications {
  id
  notificationType {
    id
    name
    code
  }
  ...InvitedToOrganizationNotificationAction_Notification
}
    ${InvitedToOrganizationNotificationAction_NotificationFragmentDoc}`;