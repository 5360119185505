"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthModule = void 0;
const common_1 = require("../../../common");
class AuthModule extends common_1.APIModule {
    constructor(client) {
        super(client, '/auth');
    }
    signUp(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            // sign up with the API
            const endpoint = this.buildEndpoint(['sign-up']);
            const response = yield this.client.post(endpoint, payload);
            const result = response.data;
            if (!result.success) {
                return result;
            }
            // authenticate supabase
            yield this.client.supabase.auth.signInWithPassword({
                email: payload.emailAddress,
                password: payload.password,
            });
            /* retrieve a new access token */
            yield this.client.updateAccessToken();
            return result;
        });
    }
    signIn(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            // if the legacy password request succeeded, attempt to sign in again
            const supabaseSignInResult = yield this.client.supabase.auth.signInWithPassword({
                email: payload.emailAddress,
                password: payload.password,
            });
            // return the result of the final sign in request
            return {
                success: !supabaseSignInResult.error,
                authError: supabaseSignInResult.error,
            };
        });
    }
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.supabase.auth.signOut();
        });
    }
    signOutEverywhere() {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint(['sign-out-everywhere']);
            const response = yield this.client.post(endpoint, {});
            const result = response.data;
            if (!result.success)
                return result;
            const localSignOutResult = yield this.client.supabase.auth.signOut();
            result.success = !localSignOutResult.error;
            return result;
        });
    }
    sendEmailVerificationEmail(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                userId,
                'send-email-verification-email',
            ]);
            const response = yield this.client.post(endpoint, {});
            return response.data;
        });
    }
    requestResetPasswordLink(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint(['request-reset-password-link']);
            const response = this.client.post(endpoint, payload);
            return (yield response).data;
        });
    }
    resetPassword(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint(['reset-password']);
            const response = this.client.post(endpoint, payload);
            return (yield response).data;
        });
    }
    validateResetPasswordRequest(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint(['validate-reset-password-request']);
            const response = this.client.post(endpoint, payload);
            return (yield response).data;
        });
    }
}
exports.AuthModule = AuthModule;
