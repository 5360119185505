import { Route, Navigate } from "react-router-dom"
import { AdminOrganizationsRoute } from "../../../../../../routes/app-route/admin-route/subroutes/admin-organizations-route/admin-organizations-route"
import { AppRoute } from "../../../../../../routes/app-route/AppRoute"
import { PreferencesRoute } from "../../../../../../routes/preferences-route/preferences-route"
import { EnterpriseRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/EnterpriseRoute"
import { ChooseOrganizationRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/choose-organization-route/ChooseOrganizationRoute"
import { OrganizationPreferencesRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/OrganizationPreferencesRoute"
import { BillingPreferencesRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/billing-preferences-route/BillingPreferencesRoute"
import { OrganizationMembersAndTeamsRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/organization-members-and-teams-route/OrganizationMembersAndTeamsRoute"
import { OrganizationMembersRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/organization-members-and-teams-route/subroutes/organization-members-route/OrganizationMembersRoute"
import { OrganizationMemberRouteEmptyState, OrganizationMemberRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/organization-members-and-teams-route/subroutes/organization-members-route/subroutes/organization-member-route/OrganizationMemberRoute"
import { OrganizationTeamsRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/organization-members-and-teams-route/subroutes/organization-teams-route/OrganizationTeamsRoute"
import { OrganizationSettingsRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/organization-settings-route/OrganizationSettingsRoute"
import { NotificationPreferencesRoute } from "../../../../../../routes/preferences-route/subroutes/notification-preferences-route/NotificationPreferencesRoute"
import { UserAccountPreferencesRoute } from "../../../../../../routes/preferences-route/subroutes/user-account-preferences-route/UserAccountPreferencesRoute"
import { UserProfilePreferencesRoute } from "../../../../../../routes/preferences-route/subroutes/user-profile-preferences-route/UserProfilePreferencesRoute"
import { AdminOrganizationsRouteName } from "../../../../../admin/common/admin-organizations-route-name/admin-organizations-route-name"
import { InvoicesRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/billing-preferences-route/subroutes/invoices-route/invoices-route"
import { PaymentMethodsRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/billing-preferences-route/subroutes/payment-methods-route/payment-methods-route"
import { SubscriptionsRoute } from "../../../../../../routes/preferences-route/subroutes/enterprise-route/subroutes/organization-preferences/subroutes/billing-preferences-route/subroutes/subscriptions-route/subscriptions-route"
import { AdminToolsRoute } from "../../../../../../routes/app-route/admin-route/subroutes/admin-tools-route/admin-tools-route"
import { OrganizationRoutes } from "../prototype/organization-routes"
import { AuthenticatedRoute } from "../../../../../../routes/authenticated-route/authenticated-route"
import RootRedirect from "../../../root-redirect/RootRedirect"

export const AuthenticatedRoutes = (
    <Route path="*" element={<AuthenticatedRoute />}>
        <Route path="*" element={<AppRoute />}>
            {/* organizations */}
            <Route path="organizations">
                {OrganizationRoutes}

                <Route index element={<RootRedirect />} />
            </Route>

            {/* admin */}
            <Route path="admin">
                <Route
                    path="organizations"
                    element={<AdminOrganizationsRoute />}
                    handle={{ RouteNameComponent: AdminOrganizationsRouteName }}
                />

                <Route
                    path="organizations"
                    handle={{ RouteNameComponent: AdminOrganizationsRouteName }}
                >
                    {OrganizationRoutes}
                </Route>

                <Route path="tools" element={<AdminToolsRoute />} />

                <Route index element={<Navigate to="organizations" replace />} />
            </Route>

            <Route index element={<Navigate to="/organizations" replace />} />
        </Route>

        <Route path="preferences" element={<PreferencesRoute />}>
            <Route index element={<Navigate to="account" replace />} />

            {/* personal */}
            <Route path="account" element={<UserAccountPreferencesRoute />} />
            <Route path="profile" element={<UserProfilePreferencesRoute />} />
            <Route path="notifications" element={<NotificationPreferencesRoute />} />

            {/* enterprise */}
            <Route path="enterprise" element={<EnterpriseRoute />}>
                <Route index element={<Navigate to="settings" replace />} />

                <Route path="settings" element={<ChooseOrganizationRoute />} />
                <Route path="users-and-teams" element={<ChooseOrganizationRoute />} />
                <Route path="billing" element={<ChooseOrganizationRoute />} />

                <Route path=":organization_id" element={<OrganizationPreferencesRoute />}>
                    <Route index element={<Navigate to="settings" replace />} />
                    <Route path="settings" element={<OrganizationSettingsRoute />} />
                    <Route path="users-and-teams" element={<OrganizationMembersAndTeamsRoute />}>
                        <Route index element={<Navigate to="users" replace />} />

                        <Route path="users" element={<OrganizationMembersRoute />}>
                            <Route index element={<OrganizationMemberRouteEmptyState />} />
                            <Route path=":organization_member_id" element={<OrganizationMemberRoute />} />
                        </Route>
                        <Route path="teams" element={<OrganizationTeamsRoute />} />
                    </Route>
                    <Route path="billing" element={<BillingPreferencesRoute />}>
                        <Route index element={<Navigate to="invoices" replace />} />

                        <Route path="invoices" element={<InvoicesRoute />} />
                        <Route path="payment-methods" element={<PaymentMethodsRoute />} />
                        <Route path="subscriptions" element={<SubscriptionsRoute />} />
                    </Route>
                </Route>
            </Route>
        </Route>
    </Route>
)