import styled from "@emotion/styled";

export const FormFieldLinkContainer = styled.div({
    display: 'grid',
    justifyItems: 'end',
})

export const StyledLink = styled.link({
    // text
    color: 'white',
    fontSize: 12,

    // opacity
    opacity: 0.5,

    // hover
    ':hover': {
        color: 'white',
        opacity: 0.75,
    }
})