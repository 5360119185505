/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type EditGroupModal_GroupFragment = { id: any, name: string, icon: string | null, color: string | null };

export const EditGroupModal_GroupFragmentDoc = `
    fragment EditGroupModal_Group on Groups {
  id
  name
  icon
  color
}
    `;