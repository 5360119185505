import { UpdateNotificationResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class NotificationCoordinatorService {
    async dismissNotification(notificationId: string): Promise<UpdateNotificationResult> {
        let result: UpdateNotificationResult | undefined = undefined;

        try {
            result = await api.notifications.updateNotification(
                notificationId,
                { read: true, dismissed: true, },
            )
        } catch {}

        if (!result) {
            result = new UpdateNotificationResult();
            result.success = false;
        }

        return result;
    }
}

export const service = new NotificationCoordinatorService();