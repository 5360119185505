import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import message from 'antd/es/message';
import React, { FC, useCallback, useContext, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../common/colors/colors';
import { Gap } from '../../../../common/constants/constants';
import { queryInvalidationHelper } from '../../../../common/react-query/query-invalidation.helper';
import { AppEventContext } from '../../../app/providers/app-event-provider/AppEventProvider';
import { service } from './invited-to-organization-notification-actions.service';
import { InvitedToOrganizationNotificationAction_NotificationFragment } from './invited-to-organization-notification-actions.generated';

type InvitedToOrganizationNotificationActionsProps = {
	notification: InvitedToOrganizationNotificationAction_NotificationFragment
}

export const InvitedToOrganizationNotificationActions: FC<InvitedToOrganizationNotificationActionsProps> = ({
	notification,
}) => {

	// react query
	const queryClient = useQueryClient()

	// styles
	const styles = useStyles()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// state
	const [acceptLoading, setAcceptLoading] = useState<boolean>(false)
	const [declineLoading, setDeclineLoading] = useState<boolean>(false)

	// event handlers
	const handleAcceptInvitationClicked = useCallback(async () => {
		/* start loading state */
		setAcceptLoading(true)

		/* accept the invitation */
		const result = await service.acceptInvitation(notification.organizationMemberInvitationId)

		/* handle any errors that occurred */
		if (!result.success || !result.organizationMember || !result.organizationMemberInvitation) {
			message.error('Something went wrong. Please try again.')
			setAcceptLoading(false)
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ organizationId: result.organizationMemberInvitation.invitedToOrganizationId },
				{ userId: result.organizationMemberInvitation.invitedUserId },
			])
		)
		
		/* dismiss the notification */
		await service.dismissNotification(notification.id)

		/* log an app event */
		await logAppEvent('accepted_organization_member_invitation', {
			organizationMemberInvitationId: result.organizationMemberInvitation.id,
		})

		/* inform the user that the request succeeded */
		message.success("Successfully accepted invitation")

		/* reset the loading state */
		setAcceptLoading(false)
	}, [notification.organizationMemberInvitationId, notification.id, queryClient, logAppEvent])

	const handleDeclineInvitationClicked = useCallback(async () => {
		/* start loading state */
		setDeclineLoading(true)

		/* accept the invitation */
		const result = await service.declineInvitation(notification.organizationMemberInvitationId)

		/* handle any errors that occurred */
		if (!result.success || !result.organizationMemberInvitation) {
			message.error('Something went wrong. Please try again.')
			setDeclineLoading(false)
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ organizationId: result.organizationMemberInvitation.invitedToOrganizationId },
				{ userId: result.organizationMemberInvitation.invitedUserId },
			])
		)
		
		/* dismiss the notification */
		await service.dismissNotification(notification.id)

		/* log an app event */
		await logAppEvent('accepted_organization_member_invitation', {
			organizationMemberInvitationId: result.organizationMemberInvitation.id,
		})

		/* inform the user that the request succeeded */
		message.success("Successfully declined invitation")

		/* reset the loading state */
		setDeclineLoading(false)
	}, [notification.organizationMemberInvitationId, notification.id, queryClient, logAppEvent])

	return (
		<div className={styles.invitedToOrganizationNotificationActions}>
			<Button
				type='primary'
				onClick={handleAcceptInvitationClicked}
				loading={acceptLoading}
			>
				Accept Invitation
			</Button>
			<Button
				style={{ backgroundColor: '#7b8295' }}
				onClick={handleDeclineInvitationClicked}
				loading={declineLoading}
			>
				Decline
			</Button>
		</div>
	)
}

const useStyles = createUseStyles({
	invitedToOrganizationNotificationActions: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: Gap.default,
		gridTemplateColumns: 'max-content',
		gridAutoColumns: 'max-content',

		'& button': {
			'&:hover': {
				color: Colors.text,
			},
		},
	},
})