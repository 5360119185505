import styled from "@emotion/styled";

export const InputContainer = styled.div({
    // layout
    display: 'grid',

    // position
    position: 'relative',
})

export const StyledInput = styled.input<{
    hasIcon: boolean
}>(({
    hasIcon,
}) => ({
    // background
    backgroundColor: 'transparent',

    // size
    boxSizing: 'border-box',
    
    // padding
    paddingLeft: hasIcon ? 32 : 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,

    // border
    border: '1px solid rgb(67, 67, 67)',
    borderRadius: 6,

    // text
    color: 'white',
    fontSize: 12,

    // animation
    transition: 'background-color 100ms ease',

    // focus
    ':focus': {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid rgb(67, 67, 67) !important',
        boxSizing: 'border-box',
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
    },

    // placeholder
    '::placeholder': {
        
    },
}))