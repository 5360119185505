import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledIcon } from '../../styled-icon/StyledIcon'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'

type StyledEnterpriseIconProps = {}

export const StyledEnterpriseIcon: FC<StyledEnterpriseIconProps> = () => {
	return (
		<StyledIcon color={blue[6]} icon={<FontAwesomeIcon icon={faBuilding} />} />
	)
}