import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { BillingPreferencesView } from "../../../../../../../../modules/preferences/views/billing-preferences-view/BillingPreferencesView";

export const BillingPreferencesRoute: FC = () => {

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string
    }

    return (
        <BillingPreferencesView
            organizationId={organizationId}
        >
            <Outlet />
        </BillingPreferencesView>
    )
}