import { FC } from "react";
import { PaymentMethodsView } from "../../../../../../../../../../modules/preferences/views/billing-preferences-view/subviews/payment-methods-view/payment-methods-view";
import { useParams } from "react-router-dom";

export const PaymentMethodsRoute: FC = () => {

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string
    }

    return (
        <PaymentMethodsView
            organizationId={organizationId}
        />
    )
}