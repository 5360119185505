import React, { FC, useMemo } from 'react'
import { MosaicNode } from 'react-mosaic-component'
import { useOrganizationMembersView_OrganizationQuery } from './OrganizationMembersView.generated'
import { AddOrganizationMemberButton } from './subcomponents/add-organization-member-button/AddOrganizationMemberButton'
import { OrganizationMembersList } from './subcomponents/organization-members-list/OrganizationMembersList'
import { Panel } from '../../../common/view-panels/ViewPanels'
import { Loading } from '../../../common/loading/Loading'
import { PanelsMosaic } from '../../../common/panels-mosaic/PanelsMosaic'

type OrganizationMembersViewProps = {
	organizationId: string
	children: React.ReactNode
}

export const OrganizationMembersView: FC<OrganizationMembersViewProps> = ({
	children,
	organizationId,
}) => {

	// data
	const { data } = useOrganizationMembersView_OrganizationQuery({ organizationId })

	// data abstraction
	const organization = useMemo(() => (
		data?.organizationsCollection?.edges[0]?.node
	), [data?.organizationsCollection?.edges])

	// constants
	const usersListPanel = useMemo(() => ({
		id: 'users-list',
		label: 'All Users',
		size: 'compact',
		component: OrganizationMembersList,
		props: {
			organization,
		},
		actions: organization && (
			<AddOrganizationMemberButton
				organization={organization}
			/>
		)
	} as Panel<any>), [organization])

	const userDetailsPanel = useMemo(() => ({
		id: 'user-details',
		label: 'User Settings',
		size: 'large',
		component: () => (<>{children}</>),
		props: {},
	}) as Panel<any>, [children])

	const panels = useMemo(() => ([
		usersListPanel,
		userDetailsPanel,
	]), [userDetailsPanel, usersListPanel])

	const defaultLayout: MosaicNode<any> = useMemo(() => ({
		direction: 'row',
		first: 'users-list',
		second: 'user-details',
		splitPercentage: 30,
	}), [])

	if (!organization) return (
		<Loading />
	)

	return (
		<PanelsMosaic
			panels={panels}
			layoutId='enterpriseUsersView_mosaicLayout'
			defaultLayout={defaultLayout}
		/>
	)
}