import { grey } from "@ant-design/colors"
import SettingOutlined from "@ant-design/icons/SettingOutlined"
import UserAddOutlined from "@ant-design/icons/UserAddOutlined"
import UserOutlined from "@ant-design/icons/UserOutlined"
import React, { FC, useContext } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { ContextMenuContext } from "../../../../../common/context-menu-provider/context-menu-provider"
import { useIsMobile } from "../../../../../common/constants/breakpoints/breakpoints"

export const AppMenuSettings: FC = () => {

    // hooks
    const styles = useStyles()

    // responsive hooks
    const isMobile = useIsMobile()

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id?: string
    }

    // constants
    const preferencesRootUrl = organizationId ? `/preferences/enterprise/${organizationId}` : '/preferences/enterprise'

    return (
        <div className={styles.container}>
            {!isMobile && (
                <>
                    <MenuItem to={`${preferencesRootUrl}/settings`} title="Organization settings" subtitle="Usage, billing, teams" Icon={SettingOutlined} />
                    <MenuItem to={`${preferencesRootUrl}/users-and-teams`} title="Add members" subtitle="Add / manage members & teams" Icon={UserAddOutlined} />
                </>
            )}
            <MenuItem to="/preferences/account" title="Personal settings" subtitle="Email, profile, notifications" Icon={UserOutlined} />
        </div>
    )
}

type MenuItemProps = {
    Icon: FC<{className?: string}>
    title: string
    subtitle: string
    to: string
}

const MenuItem: FC<MenuItemProps> = ({
    Icon,
    title,
    subtitle,
    to,
}) => {

    // hooks
    const styles = useStyles()
    const { onMenuClose } = useContext(ContextMenuContext)

    return (
        <Link to={to} className={styles.noLink}>
            <div className={styles.menuItem} onClick={onMenuClose}>
                <Icon className={styles.menuItemIcon} />
                <div className={styles.menuItemTitle}>
                    {title}
                </div>
                <div className={styles.menuItemSubtitle}>
                    {subtitle}
                </div>
            </div>
        </Link>
    )
}

const useStyles = createUseStyles({
    container: {
        display: 'grid',
        padding: 8,
        gridAutoFlow: 'row',
        borderBottom: `1px solid lightgray`,
    },
    menuItem: {
        display: 'grid',
        gridTemplateColumns: 'min-content auto',
        gridTemplateRows: 'auto auto',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 6,
        borderRadius: 4,
        '&:hover': {
            backgroundColor: 'rgb(227, 230, 236)',
        }
    },
    menuItemIcon: {
        color: grey[5],
        marginRight: 8,
    },
    menuItemTitle: {
    },
    menuItemSubtitle: {
        marginTop: -1,
        gridColumnStart: 2,
        gridColumnEnd: 3,
        fontSize: 12,
        color: grey[5],
    },
    noLink: {
        color: 'unset',
        '&:hover': {
            color: 'unset',
        },
    },
})