"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogAppEventResult = exports.LogAppEventPayload = void 0;
const class_validator_1 = require("class-validator");
const base_create_result_dto_1 = require("../../../../common/dtos/base-create-result.dto");
class LogAppEventPayload {
}
__decorate([
    (0, class_validator_1.IsUUID)()
], LogAppEventPayload.prototype, "appEventTypeId", void 0);
__decorate([
    (0, class_validator_1.IsISO8601)()
], LogAppEventPayload.prototype, "timestamp", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateIf)((object) => object.pageViewId !== null)
], LogAppEventPayload.prototype, "pageViewId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "actionId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "constellationId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "eventId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "groupId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "groupMemberId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "groupMemberPermissionsId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "notificationId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "novaId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "organizationId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "organizationMemberId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "organizationMemberInvitationId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "organizationMemberRoleId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "organizationTypeId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "scheduleId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "scheduleTaskId", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "userId", void 0);
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventPayload.prototype, "data", void 0);
exports.LogAppEventPayload = LogAppEventPayload;
class LogAppEventResult extends base_create_result_dto_1.BaseCreateResult {
}
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsOptional)()
], LogAppEventResult.prototype, "appEvent", void 0);
exports.LogAppEventResult = LogAppEventResult;
