import { FC } from "react";
import { RouteNameProps } from "../../../common/route-name/route-name.types";

export const AdminOrganizationsRouteName: FC<RouteNameProps> = ({
    data,
}) => {
    return (
        <span>
            All Organizations
        </span>
    )
}