/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type OrganizationMemberRemoveMemberForm_OrganizationMemberFragment = { id: any, userId: any | null, organizationId: any | null };

export const OrganizationMemberRemoveMemberForm_OrganizationMemberFragmentDoc = `
    fragment OrganizationMemberRemoveMemberForm_OrganizationMember on OrganizationMembers {
  id
  userId
  organizationId
}
    `;