/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../common/graphql/fetcher';
export type EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragment = { id: any, userGroupId: any, invitedToOrganizationId: any | null };

export type EditOrganizationMemberInvitationModal_UserGroupsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type EditOrganizationMemberInvitationModal_UserGroupsQuery = { userGroupsCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };

export const EditOrganizationMemberInvitationModal_OrganizationMemberInvitationFragmentDoc = `
    fragment EditOrganizationMemberInvitationModal_OrganizationMemberInvitation on OrganizationMemberInvitations {
  id
  userGroupId
  invitedToOrganizationId
}
    `;
export const EditOrganizationMemberInvitationModal_UserGroupsDocument = `
    query EditOrganizationMemberInvitationModal_UserGroups($organizationId: UUID!) {
  userGroupsCollection(
    filter: {organizationId: {eq: $organizationId}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const useEditOrganizationMemberInvitationModal_UserGroupsQuery = <
      TData = EditOrganizationMemberInvitationModal_UserGroupsQuery,
      TError = unknown
    >(
      variables: EditOrganizationMemberInvitationModal_UserGroupsQueryVariables,
      options?: UseQueryOptions<EditOrganizationMemberInvitationModal_UserGroupsQuery, TError, TData>
    ) =>
    useQuery<EditOrganizationMemberInvitationModal_UserGroupsQuery, TError, TData>(
      ['EditOrganizationMemberInvitationModal_UserGroups', variables],
      fetcher<EditOrganizationMemberInvitationModal_UserGroupsQuery, EditOrganizationMemberInvitationModal_UserGroupsQueryVariables>(EditOrganizationMemberInvitationModal_UserGroupsDocument, variables),
      options
    );

useEditOrganizationMemberInvitationModal_UserGroupsQuery.getKey = (variables: EditOrganizationMemberInvitationModal_UserGroupsQueryVariables) => ['EditOrganizationMemberInvitationModal_UserGroups', variables];
;

export const useInfiniteEditOrganizationMemberInvitationModal_UserGroupsQuery = <
      TData = EditOrganizationMemberInvitationModal_UserGroupsQuery,
      TError = unknown
    >(
      pageParamKey: keyof EditOrganizationMemberInvitationModal_UserGroupsQueryVariables,
      variables: EditOrganizationMemberInvitationModal_UserGroupsQueryVariables,
      options?: UseInfiniteQueryOptions<EditOrganizationMemberInvitationModal_UserGroupsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<EditOrganizationMemberInvitationModal_UserGroupsQuery, TError, TData>(
      ['EditOrganizationMemberInvitationModal_UserGroups.infinite', variables],
      (metaData) => fetcher<EditOrganizationMemberInvitationModal_UserGroupsQuery, EditOrganizationMemberInvitationModal_UserGroupsQueryVariables>(EditOrganizationMemberInvitationModal_UserGroupsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteEditOrganizationMemberInvitationModal_UserGroupsQuery.getKey = (variables: EditOrganizationMemberInvitationModal_UserGroupsQueryVariables) => ['EditOrganizationMemberInvitationModal_UserGroups.infinite', variables];
;

useEditOrganizationMemberInvitationModal_UserGroupsQuery.fetcher = (variables: EditOrganizationMemberInvitationModal_UserGroupsQueryVariables, options?: RequestInit['headers']) => fetcher<EditOrganizationMemberInvitationModal_UserGroupsQuery, EditOrganizationMemberInvitationModal_UserGroupsQueryVariables>(EditOrganizationMemberInvitationModal_UserGroupsDocument, variables, options);