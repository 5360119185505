/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../../../../common/graphql/generated/types';

import { NotificationsMenu_NotificationFragmentDoc } from '../../../../../../../../notifications/notifications-menu/notifications-menu.generated';
import { NotificationCoordinator_NotificationFragmentDoc } from '../../../../../../../../notifications/notification-coodinator/notification-coodinator.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../../../../../../common/graphql/fetcher';
export type UseNotifications_NotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UseNotifications_NotificationsQuery = { notificationsCollection: { edges: Array<{ node: { id: any, read: boolean | null, timestamp: any | null, organizationMemberInvitationId: any | null, notificationType: { id: any, name: string, code: string, icon: string | null, iconColor: string | null, objectName: string | null } | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedByUser: { id: any, firstName: string | null, lastName: string | null } | null, invitedUser: { id: any, firstName: string | null, lastName: string | null } | null } | null } }> } | null };


export const UseNotifications_NotificationsDocument = `
    query UseNotifications_Notifications($userId: UUID!) {
  notificationsCollection(
    filter: {userId: {eq: $userId}, dismissed: {eq: false}, isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        ...NotificationsMenu_Notification
        ...NotificationCoordinator_Notification
      }
    }
  }
}
    ${NotificationsMenu_NotificationFragmentDoc}
${NotificationCoordinator_NotificationFragmentDoc}`;
export const useUseNotifications_NotificationsQuery = <
      TData = UseNotifications_NotificationsQuery,
      TError = unknown
    >(
      variables: UseNotifications_NotificationsQueryVariables,
      options?: UseQueryOptions<UseNotifications_NotificationsQuery, TError, TData>
    ) =>
    useQuery<UseNotifications_NotificationsQuery, TError, TData>(
      ['UseNotifications_Notifications', variables],
      fetcher<UseNotifications_NotificationsQuery, UseNotifications_NotificationsQueryVariables>(UseNotifications_NotificationsDocument, variables),
      options
    );

useUseNotifications_NotificationsQuery.getKey = (variables: UseNotifications_NotificationsQueryVariables) => ['UseNotifications_Notifications', variables];
;

export const useInfiniteUseNotifications_NotificationsQuery = <
      TData = UseNotifications_NotificationsQuery,
      TError = unknown
    >(
      pageParamKey: keyof UseNotifications_NotificationsQueryVariables,
      variables: UseNotifications_NotificationsQueryVariables,
      options?: UseInfiniteQueryOptions<UseNotifications_NotificationsQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<UseNotifications_NotificationsQuery, TError, TData>(
      ['UseNotifications_Notifications.infinite', variables],
      (metaData) => fetcher<UseNotifications_NotificationsQuery, UseNotifications_NotificationsQueryVariables>(UseNotifications_NotificationsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteUseNotifications_NotificationsQuery.getKey = (variables: UseNotifications_NotificationsQueryVariables) => ['UseNotifications_Notifications.infinite', variables];
;

useUseNotifications_NotificationsQuery.fetcher = (variables: UseNotifications_NotificationsQueryVariables, options?: RequestInit['headers']) => fetcher<UseNotifications_NotificationsQuery, UseNotifications_NotificationsQueryVariables>(UseNotifications_NotificationsDocument, variables, options);