import { purple } from '@ant-design/colors'
import { DateTime } from 'luxon'
import React, { FC, ReactNode, MouseEvent, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/colors/colors'
import { BorderRadius, FontSize, Gap, Padding } from '../../../common/constants/constants'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'

type NotificationProps = {
	iconColor: string | undefined
	icon: ReactNode
	title: ReactNode
	actions?: ReactNode
	timestamp: Date
	object: string
	onClick?: (ev: MouseEvent<HTMLDivElement>) => void
	onDismiss?: () => void
}

export const Notification: FC<NotificationProps> = ({
	iconColor,
	icon,
	title,
	actions,
	timestamp,
	object,
	onClick,
	onDismiss,
}) => {

	// hooks
	const styles = useStyles()
	const dateTime = useMemo(() => DateTime.fromJSDate(timestamp), [timestamp])
	const relative = dateTime.toRelative()

	return (
		<div className={styles.notification}>
			<div className={styles.iconContainer} style={{ backgroundColor: iconColor }}>
				{icon}
			</div>
			<div className={styles.detailsContainer}>
				<div className={styles.titleContainer}>
					{title}
				</div>
				<div className={styles.metadataContainer}>
					{relative}&nbsp;•&nbsp;{object}
				</div>
			</div>
			<div className={styles.dismissContainer} onClick={onDismiss}>
				<FontAwesomeIcon icon={faCircle} />
			</div>
			{actions && (
				<div className={styles.actionsContainer}>
					{actions}
				</div>
			)}
		</div>
	)
}

const useStyles = createUseStyles({
	notification: {
		display: 'grid',
		gridTemplateColumns: 'max-content 1fr max-content',
		gridTemplateRows: 'max-content',
		gridAutoRows: 'max-content',
		gap: Gap.most,
		gridAutoFlow: 'column',
		borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,

		'&:last-child': {
			borderBottom: 'none',
		},
	},
	iconContainer: {
		width: 36,
		height: 36,
		borderRadius: BorderRadius.least,
		color: Colors.text,
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
	},
	detailsContainer: {
		display: 'grid',
		alignContent: 'center',
		gridTemplateRows: 'max-content max-content',
		paddingRight: Padding.default * 2,
		gridRowStart: 1,
	},
	titleContainer: {
		fontSize: FontSize.bigger,
	},
	metadataContainer: {
		fontSize: FontSize.default,
		color: 'rgba(0, 0, 0, 0.5) !important',
	},
	actionsContainer: {
		display: 'grid',
		gridColumnStart: 2,
		gridColumnEnd: 4,
		gridRowStart: 2,
	},
	dismissContainer: {
		color: purple.primary,
		fontSize: 18,
		padding: Padding.default,
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
})