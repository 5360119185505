"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersModule = void 0;
const common_1 = require("../../../common");
class UsersModule extends common_1.APIModule {
    constructor(client) {
        super(client, '/users');
    }
}
exports.UsersModule = UsersModule;
