import { red } from '@ant-design/colors'
import Button from 'antd/es/button'
import Popover from 'antd/es/popover'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Loading } from '../../../../../../../common/loading/Loading'
import { NotificationsMenu } from '../../../../../../../notifications/notifications-menu/notifications-menu'
import { AuthenticationContext } from '../../../../../../providers/authentication-provider/AuthenticationProvider'
import { useNotifications } from './hooks/UseNotifications'
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { useIsMobile } from '../../../../../../../common/constants/breakpoints/breakpoints'

type NotificationsButtonProps = {

}

export const NotificationsButton: FC<NotificationsButtonProps> = () => {

	// responsive hooks
	const isMobile = useIsMobile()

	// styles
	const styles = useStyles()

	// state
	const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

	// context
	const { session } = useContext(AuthenticationContext)
	const userId = session?.user?.id ?? null

	// data
	const { data } = useNotifications(userId)

	// memoized values and constants
	const notifications = useMemo(() => {
		if (!data?.notificationsCollection?.edges) return null
		return data?.notificationsCollection?.edges.map(edge => edge?.node as NonNullable<typeof edge.node>).filter(notification => !!notification)
	}, [data])

	const unreadNotifications = useMemo(() => (
		notifications?.filter(notification => !notification.read)
	), [notifications])

	// event handlers
	const handleNotificationButtonClick = useCallback(() => {
		setPopoverOpen(oldPopoverOpen => !oldPopoverOpen)
	}, [])

	if (!notifications || !unreadNotifications) return <Loading />

	return (
		<Popover open={popoverOpen} placement="bottomLeft" color="white" content={(
			<NotificationsMenu
				open={popoverOpen}
				notifications={notifications}
			/>
		)}>
			<div className={styles.buttonContainer}>
				<Button type="default" icon={<FontAwesomeIcon icon={faBell} />} onClick={handleNotificationButtonClick}>
					{isMobile ? '' : 'Notifications'}
				</Button>
				{(unreadNotifications.length > 0) && (
					<div className={styles.newNotificationsIndicator} />
				)}
			</div>
		</Popover>
	)
}

const useStyles = createUseStyles({
	notificationsButton: {
		display: 'grid'
	},
	buttonContainer: {
		position: 'relative',
	},
	newNotificationsIndicator: {
		position: 'absolute',
		top: 0,
		right: 0,
		width: 12,
		height: 12,
		backgroundColor: red.primary,
		borderRadius: 6,
		boxShadow: 'rgb(0 0 0 / 50%) 0px 5px 20px 0px !important',
		pointerEvents: 'none',
	},
})