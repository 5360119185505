import React, { FC, useMemo } from 'react'
import { useMatches } from 'react-router-dom'
import { BreadcrumbsContainer } from './Breadcrumbs.styled'
import { MatchRenderer } from './subcomponents/match-renderer/match-renderer'
import { RouterMatch } from '../route-name/route-name.types'

type BreadcrumbsProps = {}

export const Breadcrumbs: FC<BreadcrumbsProps> = () => {

	// router hooks
	const matches = useMatches() as RouterMatch[]
	const filteredMatches = useMemo(() => (
		matches.filter(match => !!match.handle?.RouteNameComponent)
	), [matches])

	return (
		<BreadcrumbsContainer>
			{filteredMatches.map((match, index) => (
				<MatchRenderer
					key={match.id}
					match={match}
					isLast={index === (filteredMatches.length - 1)}
				/>
			))}
		</BreadcrumbsContainer>
	)
}