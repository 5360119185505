import { Content } from 'antd/lib/layout/layout'
import React, { FC, useContext, useMemo } from 'react'
import { UserAccountView } from './subviews/user-account-view/UserAccountView'
import { useUserAccountPreferencesView_UserQuery } from './UserAccountPreferencesView.generated'
import { SelfContext } from '../../../app/providers/self-provider/SelfProvider'
import { StyledCredentialsIcon } from '../../../common/icons/styled-icons/styled-credentials-icon/StyledCredentialsIcon'
import { Loading } from '../../../common/loading/Loading'
import { ViewBar } from '../../../common/view-bar/view-bar'
import { ViewContainer } from '../../../common/view-container/ViewContainer'

type UserAccountPreferencesViewProps = {}

export const UserAccountPreferencesView: FC<UserAccountPreferencesViewProps> = () => {

	// hooks
	const { selfUser } = useContext(SelfContext)

	// data
	const { data } = useUserAccountPreferencesView_UserQuery({ userId: selfUser?.id }, { enabled: !!selfUser?.id })

	// data abstraction
	const user = useMemo(() => (
		data?.userAccountPreferencesView_user_usersCollection?.edges[0]?.node
	), [data?.userAccountPreferencesView_user_usersCollection?.edges])

	if (!user) return <Loading />

	return (
		<ViewContainer>
			<ViewBar
				icon={<StyledCredentialsIcon />}
				viewName='Sign In & Security'
			/>
			<Content>
				<UserAccountView
					user={user}
				/>
			</Content>
		</ViewContainer>
	)
}