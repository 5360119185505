import { FC } from "react";
import { InvoicesView } from "../../../../../../../../../../modules/preferences/views/billing-preferences-view/subviews/invoices-view/invoices-view";
import { useParams } from "react-router-dom";

export const InvoicesRoute: FC = () => {

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string
    }

    return (
        <InvoicesView
            organizationId={organizationId}
        />
    )
}