import { FC, Suspense, lazy } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Loading } from '../../../../../modules/common/loading/Loading'

const ConstellationView = lazy(() => import('../../../../../modules/constellations/views/constellation-view/constellation-view'))

export const ConstellationRoute: FC = () => {

	// params
	const { constellation_id } = useParams() as { constellation_id: string }

	return (
		<Suspense fallback={<Loading />}>
			<ConstellationView constellationId={constellation_id} >
				<Outlet />
			</ConstellationView>
		</Suspense>
	)
}