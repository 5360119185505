import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledIcon } from '../../styled-icon/StyledIcon'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'

type StyledCredentialsIconProps = {}

export const StyledCredentialsIcon: FC<StyledCredentialsIconProps> = () => {
	return (
		<StyledIcon color={blue[6]} icon={<FontAwesomeIcon icon={faKey} />} />
	)
}