import { createContext, FC, useMemo } from "react";
import { useAppSettingsProvider_AppSettingsQuery } from "./AppSettingsProvider.generated";

type AppSettingsProviderProps = {
    children: React.ReactNode
}

export const AppSettingsProvider: FC<AppSettingsProviderProps> = ({
    children,
}) => {

    // data
    const { data } = useAppSettingsProvider_AppSettingsQuery()

    // data abstraction
    const appSettings = useMemo(() => (
        data?.appSettingsCollection?.edges
            .map(edge => edge?.node as NonNullable<typeof edge.node>)
            .filter(node => !!node)
    ), [data?.appSettingsCollection?.edges])

    const novaLinkTemplate = appSettings?.find(setting => setting.code === 'nova_link_template')?.value ?? null
    const redirectorUrl = appSettings?.find(setting => setting.code === 'redirector_url')?.value ?? null

    if (!novaLinkTemplate) return null;
    
    return (
        <AppSettingsContext.Provider value={{
            novaLinkTemplate,
            redirectorUrl,
        }}>
            {children}
        </AppSettingsContext.Provider>
    )
}

type AppSettingsContextType = {
    novaLinkTemplate: string | null,
    redirectorUrl: string | null,
}

export const AppSettingsContext = createContext<AppSettingsContextType>({
    novaLinkTemplate: null,
    redirectorUrl: null,
})