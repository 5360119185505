import { FC, useContext } from "react"
import { createUseStyles } from "react-jss"
import Avatar from "antd/es/avatar";
import { blue, grey } from "@ant-design/colors";
import { SelfContext } from "../../../../providers/self-provider/SelfProvider";

export const AppMenuUserProfile: FC = () => {

    // context
    const { selfUser } = useContext(SelfContext)

    // styles
    const styles = useStyles()

    // constants
    const initials = (selfUser?.firstName?.[0] ?? '') + (selfUser?.lastName?.[0] ?? '')
    const fullName = `${selfUser?.firstName ?? ''} ${selfUser?.lastName ?? ''}`

    if (!selfUser) return null

    return (
        <div className={styles.container}>
            <div className={styles.userProfileContainer}>
                <Avatar style={{ backgroundColor: blue[6], verticalAlign: 'middle' }} size="large" className={styles.avatar}>
                    {initials}
                </Avatar>
                <span>
                    {fullName}
                </span>
                <span className={styles.email}>
                    {selfUser.emailAddress}
                </span>
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        display: 'grid',
        padding: 8,
        gap: 14,
        gridAutoFlow: 'row',
        borderBottom: `1px solid lightgray`,
    },
    userProfileContainer: {
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: 'min-content auto',
        alignItems: 'center',
        paddingLeft: 6,
        paddingRight: 6,
    },
    avatar: {
        gridRowStart: '1',
        gridRowEnd: '3',
        marginRight: 12,
    },
    email: {
        fontSize: 12,
        color: grey[5],
    }
})