import { FC, Suspense, lazy } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Loading } from "../../../modules/common/loading/Loading";

const OrganizationView = lazy(() => import("../../../modules/organizations/views/organization-view/organization-view"))

export const OrganizationRoute: FC = () => {

    // router hooks
    const { organization_id: organizationId } = useParams() as { organization_id: string }

    return (
        <Suspense fallback={<Loading />}>
            <OrganizationView organizationId={organizationId}>
                <Outlet />
            </OrganizationView>
        </Suspense>
    )
}