import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AuthenticateShell } from "../../modules/authentication/authenticate-shell/authenticate-shell";

export const AuthenticationRoute: FC = () => {
    return (
        <AuthenticateShell>
            <Outlet />
        </AuthenticateShell>
    )
}