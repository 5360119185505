import { RemoveConstellationFromGroupResult, RemoveGroupParentResult } from "constellation-sdk";
import { api } from "../../../../common/api/api";

class ClearGroupParentDragReceiverContainerDragReceiver {
    public async removeGroupParent(groupId: string): Promise<RemoveGroupParentResult> {
        let result: RemoveGroupParentResult | undefined = undefined;

        try {
            result = await api.groups.removeGroupParent(groupId);
        } catch {}

        if (!result) {
            result = new RemoveGroupParentResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred';
        }

        return result
    }

    public async removeConstellationFromGroup(constellationId: string, groupId: string): Promise<RemoveConstellationFromGroupResult> {
        let result: RemoveConstellationFromGroupResult | undefined = undefined;

        try {
            result = await api.groups.removeConstellation(groupId, constellationId);
        } catch {}

        if (!result) {
            result = new RemoveConstellationFromGroupResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred';
        }

        return result
    }
}

export const service = new ClearGroupParentDragReceiverContainerDragReceiver();