import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type NotificationPreferencesViewProps = {}

export const NotificationPreferencesView: FC<NotificationPreferencesViewProps> = () => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.notificationPreferencesView}>
			NotificationPreferencesView works!
		</div>
	)
}

const useStyles = createUseStyles({
	notificationPreferencesView: {
		display: 'grid'
	}
})