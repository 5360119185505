import { FC } from "react";
import { ViewContainer } from "../../../common/view-container/ViewContainer";
import { AdminToolsViewBar } from "./subcomponents/admin-tools-view-bar/admin-tools-view-bar";
import { Content } from "../../../common/content/content";
import { AdminToolsGrid } from "./subcomponents/admin-tools-grid/admin-tools-grid";

export const AdminToolsView: FC = () => {
    return (
        <ViewContainer>
            <AdminToolsViewBar />
            <Content>
                <div style={{ paddingTop: 12, paddingBottom: 12 }}>
                    <AdminToolsGrid />
                </div>
            </Content>
        </ViewContainer>
    )
}