import { FC, useCallback, useState } from "react";
import { FooterActions, OrganizationNamePanelContainer } from "./organization-name-panel.styled";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import message from "antd/es/message";
import { OrganizationNamePanel_OrganizationFragment } from "./organization-name-panel.generated";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/forms/input/input";
import { FormItem } from "../../../common/forms/form-item/form-item";
import { service } from "./organization-name-panel.service";
import { useQueryClient } from "@tanstack/react-query";
import { queryInvalidationHelper } from "../../../../common/react-query/query-invalidation.helper";

type OrganizationNamePanelProps = {
    organization: OrganizationNamePanel_OrganizationFragment
}

export const OrganizationNamePanel: FC<OrganizationNamePanelProps> = ({
    organization,
}) => {

    // query client
    const queryClient = useQueryClient()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form hooks
    const { register, handleSubmit, getValues, formState, reset } = useForm({
        defaultValues: {
            organizationName: organization.name,
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        // start the loading state
        setIsLoading(true)

        // retrieve form values
        const { organizationName } = getValues();

        // update the organization
        const result = await service.updateOrganization(organization.id, {
            name: organizationName,
        })

        // handle any errors that occurred
        if (!result.success) {
            setIsLoading(false)
            message.error('Something went wrong. Please try again.')
            return;
        }

        queryClient.invalidateQueries(queryInvalidationHelper([
            { organizationId: organization.id },
        ]))

        setIsLoading(false)
        message.success(`Successfully updated organization name to ${result.organization?.name}`)

        reset({
            organizationName: result.organization?.name,
        })
    }, [getValues, organization.id, queryClient, reset])

    return (
        <Spin spinning={isLoading}>
            <OrganizationNamePanelContainer onSubmit={handleSubmit(handleFormSubmit)}>
                <FormItem label="Organization Name">
                    <Input
                        {...register('organizationName', { required: true })}
                        placeholder="Organization Name"
                    />
                </FormItem>
                <FooterActions>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={!formState.isDirty || !formState.isValid}
                    >
                        Save Changes
                    </Button>
                </FooterActions>
            </OrganizationNamePanelContainer>
        </Spin>
    )
}