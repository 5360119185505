import { useMediaQuery } from "react-responsive";

export const DESKTOP_BREAKPOINT_VALUE = 1440;
export const TABLET_BREAKPOINT_VALUE = 768;
export const MOBILE_BREAKPOINT_VALUE = 628;

export const useIsDesktop = () => useMediaQuery({ minWidth: DESKTOP_BREAKPOINT_VALUE });
export const useIsTablet = () => useMediaQuery({ maxWidth: TABLET_BREAKPOINT_VALUE });
export const useIsMobile = () => useMediaQuery({ maxWidth: MOBILE_BREAKPOINT_VALUE });

export const DESKTOP_BREAKPOINT = `@media (max-width: ${DESKTOP_BREAKPOINT_VALUE}px)`;
export const TABLET_BREAKPOINT = `@media (max-width: ${TABLET_BREAKPOINT_VALUE}px)`;
export const MOBILE_BREAKPOINT = `@media (max-width: ${MOBILE_BREAKPOINT_VALUE}px)`;

export const CUSTOM_BREAKPOINT = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;