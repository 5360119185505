import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../../common/colors/colors'
import { Panel } from '../../ViewPanels'

type ViewPanelHeaderProps = {
	panel: Panel<any>
}

export const ViewPanelHeader: FC<ViewPanelHeaderProps> = ({
	panel,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.viewPanelHeader}>
			<div className={styles.panelTitleContainer}>
				<h2 className={styles.panelTitle}>
					{panel.label}
				</h2>
			</div>
			{panel.actions && (
				<div className={styles.panelActions}>
				{panel.actions}
			</div>
		)}
	</div>
	)
}

const useStyles = createUseStyles({
	viewPanelHeader: {
		display: 'grid',
		gridTemplateColumns: '1fr max-content',
		borderBottom: `1px solid ${Colors.border}`,
		paddingTop: 6,
		paddingBottom: 6,
		minHeight: 45,
		gap: 8,
	},
	panelActions: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: 12,
	},
	panelTitleContainer: {
		overflow: 'hidden',
	},
	panelTitle: {
		maxWidth: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
})