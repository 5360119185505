import { QueryClientProvider } from "@tanstack/react-query";
import { FC, ReactNode } from "react";
import { reactQueryClient } from "../../../../common/react-query/react-query-client";

type ReactQueryProviderProps = {
    children: ReactNode
}

export const ReactQueryProvider: FC<ReactQueryProviderProps> = ({ children }) => {
    return (
        <QueryClientProvider client={reactQueryClient}>
            {children}
        </QueryClientProvider>
    )
}