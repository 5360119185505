import { FC } from "react";
import { useParams } from "react-router-dom";
import { OrganizationSettingsView } from "../../../../../../../../modules/preferences/views/organization-settings-view/OrganizationSettingsView";

export const OrganizationSettingsRoute: FC = () => {

    // router hooks
    const { organization_id } = useParams() as { organization_id: string }

    return (
        <OrganizationSettingsView
            organizationId={organization_id}
        />
    )
}