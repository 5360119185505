import { useQueryClient } from '@tanstack/react-query'
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { FontSize, Gap, Padding } from '../../../common/constants/constants'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { NotificationCoordinator } from '../notification-coodinator/notification-coodinator'
import { service } from './notifications-menu.service'
import message from 'antd/es/message'
import { NotificationsMenu_NotificationFragment } from './notifications-menu.generated'

type NotificationsMenuProps = {
	open: boolean
	notifications: NotificationsMenu_NotificationFragment[]
}

export const NotificationsMenu: FC<NotificationsMenuProps> = ({
open,
	notifications,
}) => {

	// query client
	const queryClient = useQueryClient()

	// styles
	const styles = useStyles()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// constants
	const unreadNotifications = useMemo(() => (
		notifications?.filter(notification => !notification.read)
	), [notifications])

	// event handlers
	const handleNotificationsPanelOpened = useCallback(async () => {
		if (!unreadNotifications || !unreadNotifications.length) return;

		/* mark the notifications as read */
		const result = await service.markNotificationsAsRead(
			unreadNotifications.map(notification => (
				notification.id
			))
		)

		/* handle any errors that occurred */
		if (!result.success || !result.notifications) {
			message.error('Something went wrong. Please try again.')
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				'UseNotifications_Notifications',
			])
		)

		/* log an app event */
		await logAppEvent('read_notifications', {})
	}, [unreadNotifications, queryClient, logAppEvent])

	const handleDismissAllClicked = useCallback(async () => {
		if (!notifications || !notifications.length) return;

		/* mark the notifications as read */
		const result = await service.dismissNotifications(
			notifications.map(notification => (
				notification.id
			))
		)

		/* handle any errors that occurred */
		if (!result.success || !result.notifications) {
			message.error('Something went wrong. Please try again.')
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				'UseNotifications_Notifications',
			])
		)

		/* log an app event */
		await logAppEvent('dismissed_notifications', {})
	}, [notifications, queryClient, logAppEvent])

	// side effects
	useEffect(() => {
		if (open) {
			handleNotificationsPanelOpened()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	if (!notifications) return null

	return (
		<div className={styles.notificationsMenu}>
			<div className={styles.header}>
				<h2>Notifications</h2>
				{notifications.length > 0 && (
					<span onClick={handleDismissAllClicked} className={styles.link}>Dismiss all</span>
				)}
			</div>
			<div className={styles.body}>
				{notifications.length > 0 ? (
					notifications.map(notification => (
						<NotificationCoordinator
							key={notification.id}
							notification={notification}
						/>
					))
				) : (
					<span>You're all caught up 🎉</span>
				)}
			</div>
		</div>
	)
}

const useStyles = createUseStyles({
	notificationsMenu: {
		display: 'grid',
		color: 'rgba(0, 0, 0, 0.85)',
		minWidth: 400,
		maxWidth: 'calc(100vw - 100px)',

		'& h1,h2,h3,h4,h5,h6': {
			color: 'inherit',
		},
	},
	header: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridAutoColumns: 'max-content',
		gridAutoFlow: 'column',
		gap: Gap.default,
		padding: Padding.default,
		borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
		alignItems: 'end',
	},
	body: {
		display: 'grid',
		fontSize: FontSize.default,
		padding: Padding.default,
		gridTemplateRows: 'max-content',
		gridAutoRows: 'max-content',
		gap: Gap.default,
	},
	link: {
		fontSize: FontSize.default,
		fontStyle: 'italic',
		color: `rgba(255, 255, 255, 0.5)`,
		cursor: 'pointer',

		"&:hover": {
			textDecoration: 'underline',
			color: `rgba(255, 255, 255, 0.85)`,
		},
	}
})