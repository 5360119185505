import styled from "@emotion/styled";
import { Colors } from "../../../../../../../../common/colors/colors";
import { FontSize, Gap, Padding } from "../../../../../../../../common/constants/constants";

export const NavSectionContainer = styled.div({
    /* layout */
    display: 'grid',
    gridAutoRows: 'max-content',
    gap: 8,

    /* padding */
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
    paddingBottom: 12,

    /* border */
    borderTop: `1px solid ${Colors.border}`,
})

export const LabelContainer = styled.div<{
    sidebarCollapsed: boolean
    hasLabel: boolean
}>(({
    sidebarCollapsed,
    hasLabel,
}) => ({
    /* layout */
    display: !sidebarCollapsed && hasLabel ? 'flex' : 'none',
    flexDirection: 'row',
    gap: Gap.less,

    /* padding */
    paddingLeft: Padding.default,
}))

export const SectionLabel = styled.span({
    /* text */
    color: 'rgba(255, 255, 255, 0.65)',
    fontWeight: 600,
    fontSize: FontSize.default,
})

export const ChildrenContainer = styled.div({
    /* layout */
    display: 'grid',
    gridAutoRows: 'max-content',
    gap: 2,
})

export const LabelIconContainer = styled.div({
    display: 'flex',
    paddingTop: 2,
})