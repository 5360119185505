import { apiClient } from "../api/api-client";

export const fetcher = <
  TData,
  TVariables extends Record<string, unknown> | undefined
>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
) => {
  return async (): Promise<TData> => {
    const result = await apiClient.graphql.query<TData>({ query, variables });

    if (result.errors || !result.data) {
      const { message } = result.errors ? result.errors[0] : { message: 'result.data was undefined' };

      throw new Error(message);
    }

    return result.data;
  }
}