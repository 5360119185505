import React, { FC, ReactElement } from 'react'
import { createUseStyles } from 'react-jss'

type StyledIconProps = {
    color: string
    icon: ReactElement
}

export const StyledIcon: FC<StyledIconProps> = ({
    color,
    icon,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={styles.container} style={{ backgroundColor: color }}>
            <div className={styles.iconContainer}>
                {icon}
            </div>
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        borderRadius: 6,
        paddingBottom: '100%',
    },
    iconContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
    },
})