import { UpdateOrganizationMemberInvitationPayload, UpdateOrganizationMemberInvitationResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class EditOrganizationMemberInvitationModalService {
    async updateInvitation(
        organizationMemberInvitationId: string,
        payload: UpdateOrganizationMemberInvitationPayload,
    ): Promise<UpdateOrganizationMemberInvitationResult> {
        let result: UpdateOrganizationMemberInvitationResult | undefined;

        try {
            result = await api.organizationMemberInvitations.updateInvitation(
                organizationMemberInvitationId,
                payload,
            )
        } catch {}

        if (!result) {
            result = new UpdateOrganizationMemberInvitationResult();
            result.success = false;
        }

        return result
    }
}

export const service = new EditOrganizationMemberInvitationModalService();