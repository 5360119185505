import { useContext } from "react";
import { useLocation, UNSAFE_NavigationContext, UNSAFE_RouteContext } from "react-router-dom";
import { UnsafeRouterContext } from "./use-unsafe-router-context.types";

export const useUnsafeRouterContext = (): UnsafeRouterContext => {
    // router hooks
    const location = useLocation()
    const navigationContext = useContext(UNSAFE_NavigationContext);
    const routeContext = useContext(UNSAFE_RouteContext)

    return {
        location,
        navigationContext,
        routeContext,
    }
}