"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpResult = exports.SignUpPayload = void 0;
const class_validator_1 = require("class-validator");
const base_create_result_dto_1 = require("../../../../common/dtos/base-create-result.dto");
class SignUpPayload {
}
__decorate([
    (0, class_validator_1.IsEmail)()
], SignUpPayload.prototype, "emailAddress", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], SignUpPayload.prototype, "password", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], SignUpPayload.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], SignUpPayload.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], SignUpPayload.prototype, "organizationName", void 0);
exports.SignUpPayload = SignUpPayload;
class SignUpResult extends base_create_result_dto_1.BaseCreateResult {
}
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsOptional)()
], SignUpResult.prototype, "authError", void 0);
exports.SignUpResult = SignUpResult;
