import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PreferencesShell } from "../../modules/preferences/common/preferences-shell/preferences-shell";

export const PreferencesRoute: FC = () => {

    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string | undefined
    }

    return (
        <PreferencesShell organizationId={organizationId}>
            <Outlet />
        </PreferencesShell>
    )
}