import { useCallback, useEffect, useState } from "react";
import { DataHook } from "./create-use-data.types";

export const createUseData =
    <D, A extends unknown[]>(
        dataGetter: (...args: A) => Promise<D>,
    ): DataHook<D, A> => (
        (...args: A) => {

            // state
            const [isLoading, setIsLoading] = useState(false)
            const [didError, setDidError] = useState(false)
            const [data, setData] = useState<D | null>(null)

            // imperative logic
            const retrieveData = useCallback(async () => {
                setIsLoading(true)
                setDidError(false)

                let result: D | undefined = undefined;
                let error: unknown;
                try {
                    result = await dataGetter(...args)
                } catch (err) {
                    error = err;
                }

                if (error || !result) {
                    setDidError(true)
                    setData(null)
                    setIsLoading(false)
                    return
                }

                setData(result)
                setIsLoading(false)
            }, [args])

            // side effects
            useEffect(() => {
                retrieveData()
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [...args])

            // event handlers
            const handleRefreshData = useCallback(() => {
                retrieveData()
            }, [retrieveData])

            const handleReset = useCallback(() => {
                setIsLoading(false)
                setDidError(false)
                setData(null)
            }, [])

            return {
                data,
                isLoading,
                didError,
                refreshData: handleRefreshData,
                reset: handleReset,
            }
        }
    )