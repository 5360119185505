import styled from "@emotion/styled";

export const BreadcrumbsContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 6,
    rowGap: 0,

    // text
    color: 'rgba(255, 255, 255, 0.5)',
})