/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

export type UserAvatar_UserFragment = { firstName: string | null, lastName: string | null };

export const UserAvatar_UserFragmentDoc = `
    fragment UserAvatar_User on Users {
  firstName
  lastName
}
    `;