import styled from "@emotion/styled";

export const HeaderContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 0,

    // text
    textAlign: 'center',
})

export const HeaderTitle = styled.span({
    fontWeight: 'bold',
    fontSize: 26,
})

export const HeaderSubtitle = styled.span({
    fontWeight: 'normal',
    fontSize: 22,
})