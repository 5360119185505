import styled from "@emotion/styled";

export const MobileTopBarContainer = styled.div({
    /* layout */
    display: 'grid',
    alignItems: 'center',
    gap: 6,
    gridTemplateColumns: 'max-content 1fr',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',

    /* padding */
    padding: 10,
    paddingTop: 4,
})