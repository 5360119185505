import { orange } from '@ant-design/colors'
import Select from 'antd/es/select'
import Tooltip from 'antd/es/tooltip'
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SelfContext } from '../../../../../app/providers/self-provider/SelfProvider'
import { SelectorLabel, SelectorContainer, SelectorContainerInner } from './preferences-organization-selector.styled'
import { useInfinitePreferencesOrganizationSelector_OrganizationsQuery } from './preferences-organization-selector.generated'
import { useFetchAllPages } from '../../../../../app/providers/types-provider/TypesProvider.hooks'

type PreferencesOrganizationSelectorProps = {

}

export const PreferencesOrganizationSelector: FC<PreferencesOrganizationSelectorProps> = () => {

	// context
	const { selfUser } = useContext(SelfContext)

	// data
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfinitePreferencesOrganizationSelector_OrganizationsQuery('cursor', {
		cursor: null,
	}, {
		enabled: !!selfUser?.id,
		getNextPageParam: (lastPage) => {
			if (!lastPage.organizationsCollection?.pageInfo.hasNextPage) return undefined;
			return {
				cursor: lastPage.organizationsCollection.pageInfo.endCursor,
			}
		}
	})

	useFetchAllPages({
		fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
	})

	// constants
	const organizations = useMemo(() => (
		data?.pages
			.flatMap(page => page.organizationsCollection?.edges)
			.map(edge => edge as NonNullable<typeof edge>)
			.map(edge => edge?.node)
	), [data?.pages])

	// state
	const [selectDropdownOpen, setSelectDropdownOpen] = useState<boolean>(false)

	// router hooks
	const navigate = useNavigate()
	const location = useLocation()
	const { organization_id: organizationId } = useParams() as { organization_id: string | undefined }

	// event handlers
	const handleOrganizationIdChange = useCallback((selectedOrganizationId: string) => {
		if (organizationId) {
			navigate(location.pathname.replace(organizationId, selectedOrganizationId))
		} else {
			navigate(location.pathname.replace(`/preferences/enterprise/`, `/preferences/enterprise/${selectedOrganizationId}/`))
		}
	}, [navigate, organizationId, location.pathname])

	// side effects
	useEffect(() => {
		if (!organizations) return;
		if (organizations.length === 1) {
			handleOrganizationIdChange(organizations[0]?.id)
		}
	}, [handleOrganizationIdChange, organizations])

	if (!organizations || !location.pathname.includes('/enterprise/')) return <div />

	return (
		<SelectorContainer>
			<SelectorLabel>
				Organization:
			</SelectorLabel>
			<SelectorContainerInner>
				<Tooltip overlay="Choose an organization to continue" open={!!organizations && !organizationId && !selectDropdownOpen} color={orange.primary}>
					<Select
						value={organizationId}
						onChange={handleOrganizationIdChange}
						placeholder="Select an organization..."
						open={selectDropdownOpen}
						onDropdownVisibleChange={setSelectDropdownOpen}
						status={organizationId ? undefined : 'warning'}
						optionFilterProp='children'
						showSearch
						filterOption={(input, option) => {
							return ((option?.children ?? '') as string).toLowerCase().includes(input.toLowerCase())
						}}
					>
						{organizations?.map(organization => (
							<Select.Option
								key={organization.id}
								value={organization.id}
							>
								{organization.name}
							</Select.Option>
						))}
					</Select>
				</Tooltip>
			</SelectorContainerInner>
		</SelectorContainer>
	)
}