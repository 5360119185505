import { FC, ReactNode } from "react";
import * as Sentry from "@sentry/react";

type ErrorBoundaryProps = {
    children: ReactNode
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
    children,
}) => {
    return (
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            {children}
        </Sentry.ErrorBoundary>
    )
}