import { useRouterIsLinkActive } from "../../../../../common/hooks/use-router-is-link-active/use-router-is-link-active"
import { TabBarLink } from "../../tab-bar.types"
import { ActiveTabBarButtonLinkStyles, TabBarButtonLinkStyles } from "./tab-bar-button.styled"

export const useTabBarStyles = (link: TabBarLink) => {

    // router hooks
    const isActive = useRouterIsLinkActive(link.to)

    const styles = [TabBarButtonLinkStyles]
    if (isActive) styles.push(ActiveTabBarButtonLinkStyles)
    
    return styles
}