import { FC } from "react";
import { AdminToolsGridContainer } from "./admin-tools-grid.styled";
import { MergeAccountsGridItem } from "./subcomponents/merge-accounts-grid-item/merge-accounts-grid-item";
import { RedashGridItem } from "./subcomponents/redash-grid-item/redash-grid-item";

export const AdminToolsGrid: FC = () => {
    return (
        <AdminToolsGridContainer>
            <MergeAccountsGridItem />
            <RedashGridItem />
        </AdminToolsGridContainer>
    )
}