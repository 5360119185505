import { FC, useMemo } from "react";
import { RouteNameProps } from "../../common/route-name/route-name.types";
import { GroupDragReceiver } from "../common/group-drag-receiver/group-drag-receiver";
import { useGroupRouteName_GroupQuery } from "./group-route-name.generated";

export const GroupRouteName: FC<RouteNameProps> = ({
    data,
}) => {

    // params
    const groupId = data.pathname.split('/').pop()

    // data hooks
    const { data: groups } = useGroupRouteName_GroupQuery({ groupId })

    // data abstraction
    const group = useMemo(() => (
        groups?.groupsCollection?.edges[0]?.node
    ), [groups?.groupsCollection?.edges])

    if (!group) return null

    return (
        <GroupDragReceiver group={group}>
            <span>
                {group.name}
            </span>
        </GroupDragReceiver>
    )
}