import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledIcon } from '../../styled-icon/StyledIcon'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'

type StyledUserProfileIconProps = {}

export const StyledUserProfileIcon: FC<StyledUserProfileIconProps> = () => {
	return (
		<StyledIcon color={blue[6]} icon={<FontAwesomeIcon icon={faUser} />} />
	)
}