import { FC, Suspense, lazy } from "react";
import { Loading } from "../../../../../modules/common/loading/Loading";

const AdminOrganizationsView = lazy(() => import("../../../../../modules/admin/views/admin-organizations-view/admin-organizations-view"))

export const AdminOrganizationsRoute: FC = () => {
    return (
        <Suspense fallback={<Loading />}>
            <AdminOrganizationsView />
        </Suspense>
    )
}