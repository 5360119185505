import { FC, useMemo } from "react";
import { useConstellationRouteName_ConstellationQuery } from "./constellation-route-name.generated";
import { RouteNameProps } from "../../../common/route-name/route-name.types";

export const ConstellationRouteName: FC<RouteNameProps> = ({
    data,
}) => {

    // params
    const { constellation_id: constellationId } = data.params as { constellation_id: string }

    // data hooks
    const { data: constellations } = useConstellationRouteName_ConstellationQuery({ constellationId })

    // data abstraction
    const constellation = useMemo(() => (
        constellations?.constellationsCollection?.edges[0]?.node
    ), [constellations?.constellationsCollection?.edges])

    if (!constellation) return null

    return (
        <span>
            {constellation.name}
        </span>
    )
}