import { FC, ReactNode, useEffect } from "react";
import { useIsMobile } from "../../../common/constants/breakpoints/breakpoints";
import message from 'antd/es/message';

type MessageConfigProviderProps = {
    children: ReactNode
}

export const MessageConfigProvider: FC<MessageConfigProviderProps> = ({
    children,
}) => {

    // responsive hooks
    const isMobile = useIsMobile()

    // side effects
    useEffect(() => {
        message.config({
            top: isMobile ? 36 : 24,
        })
    }, [isMobile])

    return (
        <>
            {children}    
        </>
    )
}