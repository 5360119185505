/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { GroupUtils_GroupFragmentDoc } from '../utils/group.utils.generated';
export type GroupDragReceiver_GroupFragment = { id: any, parentGroupId: any | null, organizationId: any | null };

export const GroupDragReceiver_GroupFragmentDoc = `
    fragment GroupDragReceiver_Group on Groups {
  id
  ...GroupUtils_Group
}
    ${GroupUtils_GroupFragmentDoc}`;