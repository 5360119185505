import { FC } from "react";
import { NotificationTitleCoordinator_NotificationFragment } from "./NotificationTitleCoordinator.generated";

type NotificationTitleCoordinatorProps = {
    notification: NotificationTitleCoordinator_NotificationFragment
}

export const NotificationTitleCoordinator: FC<NotificationTitleCoordinatorProps> = ({
    notification,
}) => {
    if (notification.notificationType?.code === 'organization_member_invitation') {
        return (
            <>
                <strong>{notification.organizationMemberInvitation?.invitedByUser?.firstName} {notification.organizationMemberInvitation?.invitedByUser?.lastName}</strong>
                <span>&nbsp;invited you to join the organization&nbsp;</span>
                <strong>{notification.organizationMemberInvitation?.invitedToOrganization?.name}</strong>
            </>
        )
    }

    return (
        <span>Can't show this notification</span>
    )
}