/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { PaginationFragmentFragmentDoc } from '../../app/providers/types-provider/TypesProvider.generated';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../common/graphql/fetcher';
export type MergeAccountsDialog_UsersQueryVariables = Types.Exact<{
  cursor: Types.InputMaybe<Types.Scalars['Cursor']>;
}>;


export type MergeAccountsDialog_UsersQuery = { usersCollection: { edges: Array<{ node: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null };


export const MergeAccountsDialog_UsersDocument = `
    query MergeAccountsDialog_Users($cursor: Cursor) {
  usersCollection(
    orderBy: {firstName: AscNullsLast, lastName: AscNullsLast}
    after: $cursor
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        firstName
        lastName
        emailAddress
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragmentFragmentDoc}`;
export const useMergeAccountsDialog_UsersQuery = <
      TData = MergeAccountsDialog_UsersQuery,
      TError = unknown
    >(
      variables?: MergeAccountsDialog_UsersQueryVariables,
      options?: UseQueryOptions<MergeAccountsDialog_UsersQuery, TError, TData>
    ) =>
    useQuery<MergeAccountsDialog_UsersQuery, TError, TData>(
      variables === undefined ? ['MergeAccountsDialog_Users'] : ['MergeAccountsDialog_Users', variables],
      fetcher<MergeAccountsDialog_UsersQuery, MergeAccountsDialog_UsersQueryVariables>(MergeAccountsDialog_UsersDocument, variables),
      options
    );

useMergeAccountsDialog_UsersQuery.getKey = (variables?: MergeAccountsDialog_UsersQueryVariables) => variables === undefined ? ['MergeAccountsDialog_Users'] : ['MergeAccountsDialog_Users', variables];
;

export const useInfiniteMergeAccountsDialog_UsersQuery = <
      TData = MergeAccountsDialog_UsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof MergeAccountsDialog_UsersQueryVariables,
      variables?: MergeAccountsDialog_UsersQueryVariables,
      options?: UseInfiniteQueryOptions<MergeAccountsDialog_UsersQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<MergeAccountsDialog_UsersQuery, TError, TData>(
      variables === undefined ? ['MergeAccountsDialog_Users.infinite'] : ['MergeAccountsDialog_Users.infinite', variables],
      (metaData) => fetcher<MergeAccountsDialog_UsersQuery, MergeAccountsDialog_UsersQueryVariables>(MergeAccountsDialog_UsersDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteMergeAccountsDialog_UsersQuery.getKey = (variables?: MergeAccountsDialog_UsersQueryVariables) => variables === undefined ? ['MergeAccountsDialog_Users.infinite'] : ['MergeAccountsDialog_Users.infinite', variables];
;

useMergeAccountsDialog_UsersQuery.fetcher = (variables?: MergeAccountsDialog_UsersQueryVariables, options?: RequestInit['headers']) => fetcher<MergeAccountsDialog_UsersQuery, MergeAccountsDialog_UsersQueryVariables>(MergeAccountsDialog_UsersDocument, variables, options);