import { UpdateSelfUserPayload, UpdateSelfUserResult } from "constellation-sdk";
import { api } from "../../../common/api/api";

class UpdateUserProfileFormService {
    async updateUserProfile(payload: UpdateSelfUserPayload): Promise<UpdateSelfUserResult> {
        let result: UpdateSelfUserResult | undefined = undefined;

        try {
            result = await api.self.updateSelfUser(payload)
        } catch {}

        if (!result) {
            result = new UpdateSelfUserResult();
            result.success = false
        }

        return result
    }
}

export const service = new UpdateUserProfileFormService();