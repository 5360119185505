import React, { FC, ReactNode, useContext } from 'react'
import { AppShellContext } from '../../../../app-shell-context'
import { ChildrenContainer, LabelContainer, LabelIconContainer, NavSectionContainer, SectionLabel } from './nav-section.styled'
import { NavSectionProvider } from './nav-section.context'

type NavSectionProps = {
    label?: string
    icon?: ReactNode
    children: ReactNode
    style?: React.CSSProperties
}

export const NavSection: FC<NavSectionProps> = ({
    label,
    icon,
    children,
    style,
}) => {

    // context
    const { sidebarCollapsed } = useContext(AppShellContext)

    return (
        <NavSectionProvider hasLabel={!!label}>
            <NavSectionContainer style={style}>
                <LabelContainer
                    sidebarCollapsed={sidebarCollapsed}
                    hasLabel={!!label}
                >
                    {icon && (
                        <LabelIconContainer>
                            {icon}
                        </LabelIconContainer>
                    )}
                    <SectionLabel>
                        {label}
                    </SectionLabel>
                </LabelContainer>
                <ChildrenContainer>
                    {children}
                </ChildrenContainer>
            </NavSectionContainer>
        </NavSectionProvider>
    )
}