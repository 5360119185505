import { DeleteOrganizationMemberInvitationResult } from "constellation-sdk";
import { api } from "../../../../../common/api/api";

class CancelOrganizationMemberInvitationMenuItemService {
    async cancelInvitation(
        organizationMemberInvitationId: string
    ): Promise<DeleteOrganizationMemberInvitationResult> {
        let result: DeleteOrganizationMemberInvitationResult | undefined = undefined;

        try {
            result = await api.organizationMemberInvitations.deleteInvitation(
                organizationMemberInvitationId,
            )
        } catch {}

        if (!result) {
            result = new DeleteOrganizationMemberInvitationResult();
            result.success = false;
        }

        return result
    }
}

export const service = new CancelOrganizationMemberInvitationMenuItemService()