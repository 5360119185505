import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { BorderRadius, Padding } from '../../../../../../common/constants/constants'

type PreferencesViewIconProps = {
	color: string | undefined
	children: React.ReactNode
}

export const PreferencesViewIcon: FC<PreferencesViewIconProps> = ({
	color,
	children,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.preferencesViewIcon} style={{ backgroundColor: color }}>
			{children}
		</div>
	)
}

const useStyles = createUseStyles({
	preferencesViewIcon: {
		fontSize: 24,
		width: 52,
		height: 52,
		display: 'grid',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: BorderRadius.default,
		marginBottom: Padding.default,
	}
})