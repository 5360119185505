/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { RemoveGroupMemberMenuItem_GroupMemberFragmentDoc } from './contents/remove-group-member-menu-item/remove-group-member-menu-item.generated';
export type GroupMemberContextMenu_GroupMemberFragment = { id: any, groupId: any | null, userId: any | null, user: { id: any } | null };

export const GroupMemberContextMenu_GroupMemberFragmentDoc = `
    fragment GroupMemberContextMenu_GroupMember on GroupMembers {
  id
  user {
    id
  }
  ...RemoveGroupMemberMenuItem_GroupMember
}
    ${RemoveGroupMemberMenuItem_GroupMemberFragmentDoc}`;