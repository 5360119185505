import { To, RelativeRoutingType, RouteMatch, Path, parsePath } from "react-router-dom";
import { UnsafeRouterContext } from "../../hooks/use-unsafe-router-context/use-unsafe-router-context.types";

/* this is a synthetic method that imitates useResolvePath */
export const getResolvedPath = (
    to: To,
    unsafeRouterContext: UnsafeRouterContext,
    { relative }: { relative?: RelativeRoutingType } = {}
) => {
    // context
    const { matches } = unsafeRouterContext.routeContext;
    const { pathname: locationPathname } = unsafeRouterContext.location;

    // abstraction
    const routePathnamesJson = JSON.stringify(
        getPathContributingMatches(matches).map((match: RouteMatch) => match.pathnameBase)
    );


    return resolveTo(
        to,
        JSON.parse(routePathnamesJson),
        locationPathname,
        relative === "path"
    )
}

/* 
    IMPORTANT:
    All of the methods below this line are copy / pasted directly from react-router's
    library. (They're not exposed by the library).

    You probably don't need to worry about them at all.
*/

const getPathContributingMatches = (matches: RouteMatch[]) => {
    return matches.filter(
        (match, index) =>
            index === 0 || (match.route.path && match.route.path.length > 0)
    );
}

function resolveTo(
    toArg: To,
    routePathnames: string[],
    locationPathname: string,
    isPathRelative = false
): Path {
    let to: Partial<Path>;
    if (typeof toArg === "string") {
        to = parsePath(toArg);
    } else {
        to = { ...toArg };

        invariant(
            !to.pathname || !to.pathname.includes("?"),
            getInvalidPathError("?", "pathname", "search", to)
        );
        invariant(
            !to.pathname || !to.pathname.includes("#"),
            getInvalidPathError("#", "pathname", "hash", to)
        );
        invariant(
            !to.search || !to.search.includes("#"),
            getInvalidPathError("#", "search", "hash", to)
        );
    }

    let isEmptyPath = toArg === "" || to.pathname === "";
    let toPathname = isEmptyPath ? "/" : to.pathname;

    let from: string;

    // Routing is relative to the current pathname if explicitly requested.
    //
    // If a pathname is explicitly provided in `to`, it should be relative to the
    // route context. This is explained in `Note on `<Link to>` values` in our
    // migration guide from v5 as a means of disambiguation between `to` values
    // that begin with `/` and those that do not. However, this is problematic for
    // `to` values that do not provide a pathname. `to` can simply be a search or
    // hash string, in which case we should assume that the navigation is relative
    // to the current location's pathname and *not* the route pathname.
    if (isPathRelative || toPathname == null) {
        from = locationPathname;
    } else {
        let routePathnameIndex = routePathnames.length - 1;

        if (toPathname.startsWith("..")) {
            let toSegments = toPathname.split("/");

            // Each leading .. segment means "go up one route" instead of "go up one
            // URL segment".  This is a key difference from how <a href> works and a
            // major reason we call this a "to" value instead of a "href".
            while (toSegments[0] === "..") {
                toSegments.shift();
                routePathnameIndex -= 1;
            }

            to.pathname = toSegments.join("/");
        }

        // If there are more ".." segments than parent routes, resolve relative to
        // the root / URL.
        from = routePathnameIndex >= 0 ? routePathnames[routePathnameIndex] : "/";
    }

    let path = resolvePath(to, from);

    // Ensure the pathname has a trailing slash if the original "to" had one
    let hasExplicitTrailingSlash =
        toPathname && toPathname !== "/" && toPathname.endsWith("/");
    // Or if this was a link to the current path which has a trailing slash
    let hasCurrentTrailingSlash =
        (isEmptyPath || toPathname === ".") && locationPathname.endsWith("/");
    if (
        !path.pathname.endsWith("/") &&
        (hasExplicitTrailingSlash || hasCurrentTrailingSlash)
    ) {
        path.pathname += "/";
    }

    return path;
}

function invariant(value: boolean, message?: string): asserts value;
function invariant<T>(
    value: T | null | undefined,
    message?: string
): asserts value is T;
function invariant(value: any, message?: string) {
    if (value === false || value === null || typeof value === "undefined") {
        throw new Error(message);
    }
}

function getInvalidPathError(
    char: string,
    field: string,
    dest: string,
    path: Partial<Path>
) {
    return (
        `Cannot include a '${char}' character in a manually specified ` +
        `\`to.${field}\` field [${JSON.stringify(
            path
        )}].  Please separate it out to the ` +
        `\`to.${dest}\` field. Alternatively you may provide the full path as ` +
        `a string in <Link to="..."> and the router will parse it for you.`
    );
}

function resolvePath(to: To, fromPathname = "/"): Path {
    let {
        pathname: toPathname,
        search = "",
        hash = "",
    } = typeof to === "string" ? parsePath(to) : to;

    let pathname = toPathname
        ? toPathname.startsWith("/")
            ? toPathname
            : resolvePathname(toPathname, fromPathname)
        : fromPathname;

    return {
        pathname,
        search: normalizeSearch(search),
        hash: normalizeHash(hash),
    };
}

function resolvePathname(relativePath: string, fromPathname: string): string {
    let segments = fromPathname.replace(/\/+$/, "").split("/");
    let relativeSegments = relativePath.split("/");

    relativeSegments.forEach((segment) => {
        if (segment === "..") {
            // Keep the root "" segment so the pathname starts at /
            if (segments.length > 1) segments.pop();
        } else if (segment !== ".") {
            segments.push(segment);
        }
    });

    return segments.length > 1 ? segments.join("/") : "/";
}

const normalizeSearch = (search: string): string =>
    !search || search === "?"
        ? ""
        : search.startsWith("?")
            ? search
            : "?" + search;

const normalizeHash = (hash: string): string =>
    !hash || hash === "#" ? "" : hash.startsWith("#") ? hash : "#" + hash;