import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { NavButton } from "../../../../../../../app/components/app-shell/subcomponents/app-side-bar/subcomponents/nav-button/nav-button";
import { NavSection } from "../../../../../../../app/components/app-shell/subcomponents/app-side-bar/subcomponents/nav-section/nav-section";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../../../../../../../common/constants/breakpoints/breakpoints";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons/faFileInvoice";
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'

export const PreferencesNavSections: FC = () => {

    // router hooks
    const { organization_id: organizationId } = useParams() as { organization_id: string | undefined }

    // responsive hooks
    const isMobile = useIsMobile()

    return (
        <>
            <NavSection>
                <NavButton
                    label='Return to Constellation QR'
                    to="/"
                    icon={<FontAwesomeIcon icon={faHome} />} />
            </NavSection>
            {!isMobile && (
                <NavSection label='Enterprise Preferences'>
                    <NavButton
                        label="Organization Settings"
                        to={organizationId ? (
                            `/preferences/enterprise/${organizationId}/settings`
                        ) : (
                            `/preferences/enterprise/settings`
                        )}
                        icon={<FontAwesomeIcon icon={faBuilding} />}
                        end />
                    <NavButton
                        label="Users & Teams"
                        to={organizationId ? (
                            `/preferences/enterprise/${organizationId}/users-and-teams`
                        ) : (
                            `/preferences/enterprise/users-and-teams`
                        )}
                        icon={<FontAwesomeIcon icon={faUsers} />}
                        customMatch={({ pathname }) => pathname.startsWith('/preferences/enterprise') && pathname.includes('/users-and-teams')}
                        end />
                    <NavButton
                        label="Billing & Subscriptions"
                        to={organizationId ? (
                            `/preferences/enterprise/${organizationId}/billing`
                        ) : (
                            `/preferences/enterprise/billing`
                        )}
                        icon={<FontAwesomeIcon icon={faFileInvoice} />}
                        customMatch={({ pathname }) => pathname.startsWith('/preferences/enterprise') && pathname.includes('/billing')}
                        end />
                </NavSection>
            )}
            <NavSection label='Personal Preferences'>
                <NavButton
                    label="Sign In & Security"
                    to="/preferences/account"
                    icon={<FontAwesomeIcon icon={faKey} />}
                    end />
                <NavButton
                    label="Personal Info"
                    to="/preferences/profile"
                    icon={<FontAwesomeIcon icon={faUser} />}
                    end />
            </NavSection>
        </>
    )
}