/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

export type RemoveFromGroupMenuItem_GroupFragment = { id: any, name: string, parentGroup: { id: any, name: string } | null };

export const RemoveFromGroupMenuItem_GroupFragmentDoc = `
    fragment RemoveFromGroupMenuItem_Group on Groups {
  id
  name
  parentGroup {
    id
    name
  }
}
    `;