/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

export type OrganizationNamePanel_OrganizationFragment = { id: any, name: string };

export const OrganizationNamePanel_OrganizationFragmentDoc = `
    fragment OrganizationNamePanel_Organization on Organizations {
  id
  name
}
    `;