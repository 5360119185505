/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../common/graphql/fetcher';
export type ScheduleRouteName_ScheduleQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars['UUID'];
}>;


export type ScheduleRouteName_ScheduleQuery = { schedulesCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };


export const ScheduleRouteName_ScheduleDocument = `
    query ScheduleRouteName_Schedule($scheduleId: UUID!) {
  schedulesCollection(filter: {id: {eq: $scheduleId}}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const useScheduleRouteName_ScheduleQuery = <
      TData = ScheduleRouteName_ScheduleQuery,
      TError = unknown
    >(
      variables: ScheduleRouteName_ScheduleQueryVariables,
      options?: UseQueryOptions<ScheduleRouteName_ScheduleQuery, TError, TData>
    ) =>
    useQuery<ScheduleRouteName_ScheduleQuery, TError, TData>(
      ['ScheduleRouteName_Schedule', variables],
      fetcher<ScheduleRouteName_ScheduleQuery, ScheduleRouteName_ScheduleQueryVariables>(ScheduleRouteName_ScheduleDocument, variables),
      options
    );

useScheduleRouteName_ScheduleQuery.getKey = (variables: ScheduleRouteName_ScheduleQueryVariables) => ['ScheduleRouteName_Schedule', variables];
;

export const useInfiniteScheduleRouteName_ScheduleQuery = <
      TData = ScheduleRouteName_ScheduleQuery,
      TError = unknown
    >(
      pageParamKey: keyof ScheduleRouteName_ScheduleQueryVariables,
      variables: ScheduleRouteName_ScheduleQueryVariables,
      options?: UseInfiniteQueryOptions<ScheduleRouteName_ScheduleQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ScheduleRouteName_ScheduleQuery, TError, TData>(
      ['ScheduleRouteName_Schedule.infinite', variables],
      (metaData) => fetcher<ScheduleRouteName_ScheduleQuery, ScheduleRouteName_ScheduleQueryVariables>(ScheduleRouteName_ScheduleDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteScheduleRouteName_ScheduleQuery.getKey = (variables: ScheduleRouteName_ScheduleQueryVariables) => ['ScheduleRouteName_Schedule.infinite', variables];
;

useScheduleRouteName_ScheduleQuery.fetcher = (variables: ScheduleRouteName_ScheduleQueryVariables, options?: RequestInit['headers']) => fetcher<ScheduleRouteName_ScheduleQuery, ScheduleRouteName_ScheduleQueryVariables>(ScheduleRouteName_ScheduleDocument, variables, options);