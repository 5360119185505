import isEqual from "lodash/isEqual"
import { useEffect, useState } from "react"

export const useMemoizedObject = <T extends any>(object: T): T => {

    // hooks
    const [memoizedObject, setMemoizedObject] = useState<T>(object)
    
    // side effects
    useEffect(() => {
        setMemoizedObject(oldObject => {
            if (!isEqual(oldObject, object)) return object
            return oldObject
        })
    }, [object])

    return memoizedObject
}