"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateActionResult = exports.UpdateActionPayload = void 0;
const class_validator_1 = require("class-validator");
const base_update_result_dto_1 = require("../../../../common/dtos/base-update-result.dto");
class UpdateActionPayload {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], UpdateActionPayload.prototype, "name", void 0);
exports.UpdateActionPayload = UpdateActionPayload;
class UpdateActionResult extends base_update_result_dto_1.BaseUpdateResult {
}
__decorate([
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.ValidateNested)()
], UpdateActionResult.prototype, "action", void 0);
exports.UpdateActionResult = UpdateActionResult;
