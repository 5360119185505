import { FC, MouseEventHandler } from "react";
import { AdminToolGridItemContainer, AdminToolName, Descriptor, DetailsContainer } from "./admin-tool-grid-item.styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { StyledIcon } from "../../../../../../../common/icons/styled-icon/StyledIcon";
import { blue } from "@ant-design/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type AdminToolGridItemProps = {
    title: string
    subtitle: string
    icon: IconProp
    iconColor?: string
    onClick: MouseEventHandler
}

export const AdminToolGridItem: FC<AdminToolGridItemProps> = ({
    title,
    subtitle,
    icon,
    iconColor,
    onClick,
}) => {
    return (
        <AdminToolGridItemContainer onClick={onClick} outlineColor={iconColor}>
            <StyledIcon color={iconColor ?? blue[6]} icon={<FontAwesomeIcon icon={icon} />} />
            <DetailsContainer>
                <AdminToolName>
                    {title}
                </AdminToolName>
                <Descriptor>
                    {subtitle}
                </Descriptor>
            </DetailsContainer>
        </AdminToolGridItemContainer>
    )
}