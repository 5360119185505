import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledIcon } from '../../styled-icon/StyledIcon'
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons/faFileInvoice'

type StyledBillingIconProps = {}

export const StyledBillingIcon: FC<StyledBillingIconProps> = () => {
	return (
		<StyledIcon
			color={blue.primary!}
			icon={<FontAwesomeIcon icon={faFileInvoice} />}
		/>
	)
}