import { createUseData } from "../../../../../../../../common/hooks/create-use-data/create-use-data";
import { service } from "./subscription-button.service";
import { CheckPermissionsPayload } from "constellation-sdk";
import { useMemoizedObject } from "../../../../../../../../common/utils/use-memoized-object";

export const useCheckPermissions = (payload: CheckPermissionsPayload) => {
    const memoizedPayload = useMemoizedObject(payload);
    return createUseData(() => {
        return service.checkPermissions(memoizedPayload)
    })
}