import styled from "@emotion/styled";
import { Gap } from "../../../../common/constants/constants";

export const OrganizationNamePanelContainer = styled.form({
    /* layout */
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.most,
})

export const FooterActions = styled.div({
    /* layout */
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
})