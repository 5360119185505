import styled from "@emotion/styled";
import { TABLET_BREAKPOINT } from "../../../constants/breakpoints/breakpoints";

export const DialogHeroPanelContainer = styled.div({
    /* layout */
    display: 'grid',
    gridRowStart: 1,
    gridRowEnd: 3,

    /* border */
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,

    // RE-ENABLE FOR DIALOG PANELS ON TABLET BREAKPOINT
    // [TABLET_BREAKPOINT]: {
    //     display: 'block',
    //     gridRowStart: 'unset',
    //     gridRowEnd: 'unset',
    // },

    [TABLET_BREAKPOINT]: {
        /* layout */
        display: 'none',

        /* layout */
        gridRowStart: 'unset',
        gridRowEnd: 'unset',

        /* padding */
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,

        /* border */
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    }
})

export const DialogHeroPanelCard = styled.div({
    /* layout */
    display: 'block',

    /* padding */
    paddingTop: 112,
    paddingLeft: 12,
    paddingRight: 12,

    /* background */
    backgroundImage: `url(/images/hero-background.png)`,
    backgroundPosition: 'bottom center',
    backgroundSize: 'cover',

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        /* padding */
        padding: 12,

        /* margin */
        margin: 8,

        /* border */
        borderRadius: 12,
    }
})

export const InnerContainer = styled.div({
    /* layout */
    display: 'grid',
    gridAutoRows: 'max-content',

    /* text */
    textAlign: 'center',

    /* size */
    width: '100%',
    maxWidth: 400,

    /* margin */
    marginLeft: 'auto',
    marginRight: 'auto',

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        /* layout */
        gridTemplateColumns: 'max-content 1fr',
        alignItems: 'center',
        gap: 8,

        /* size */
        width: 'unset',
        maxWidth: 'unset',

        /* text */
        textAlign: 'left',
    }
})

export const IconContainer = styled.div({
    /* layout */
    display: 'grid',
    gridAutoRows: 'max-content',
    justifyContent: 'center',

    /* padding */
    paddingBottom: 12,

    /* size */
    fontSize: 52,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 24,
    }
})

export const HeroTextContainer = styled.div({
    /* layout */
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [TABLET_BREAKPOINT]: {
        gap: 0,
    }
})

export const HeroDescription = styled.span({
    fontSize: 16,
    lineHeight: '1.3rem',
    paddingTop: 8,

    [TABLET_BREAKPOINT]: {
        paddingTop: 0,
    },
})

export const PlatformLogoImage = styled.img({
    width: 108,

    [TABLET_BREAKPOINT]: {
        width: 72,
    }
})