import { createContext, FC } from "react";

type ContextMenuProviderProps = {
    onMenuClose: () => void
    children: React.ReactNode
}

export const ContextMenuProvider: FC<ContextMenuProviderProps> = ({
    onMenuClose,
    children,
}) => {
    return (
        <ContextMenuContext.Provider value={{
            onMenuClose,
        }}>
            {children}
        </ContextMenuContext.Provider>
    )
}

type ContextMenuContextType = {
    onMenuClose: () => void
}

export const ContextMenuContext = createContext<ContextMenuContextType>({
    onMenuClose: () => {},
})