import Spin from 'antd/es/spin'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

type LoadingProps = {
	tip?: string
}

export const Loading: FC<LoadingProps> = ({
	tip,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.loading}>
			<Spin tip={tip} />
		</div>
	)
}

const useStyles = createUseStyles({
	loading: {
		display: 'grid',
		width: '100%',
		height: '100%',
	}
})