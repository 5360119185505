import { FC } from "react";
import { BillboardContainer, BillboardContentContainer, BillboardText, IconImage } from "./billboard.styled";

export const Billboard: FC = () => {
    return (
        <BillboardContainer style={{
            backgroundImage: 'url(/images/space-01.jpg)',
        }}>
            <BillboardContentContainer>
                <IconImage
                    src="/images/logo/cqr23_icons_Dark BG.svg"
                />
                <BillboardText>
                    <strong>Dynamic QR Codes</strong> that you can deploy, monitor, and automate <strong>in seconds</strong>.
                </BillboardText>
            </BillboardContentContainer>
        </BillboardContainer>
    )
}