import { FC, useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "../../../common/loading/Loading";
import { SelfContext } from "../../providers/self-provider/SelfProvider";
import { useRootRedirect_OrganizationQuery } from "./RootRedirect.generated";

export const RootRedirect: FC = () => {

    // context
    const { selfUser } = useContext(SelfContext)

    // data
    const { data } = useRootRedirect_OrganizationQuery({
        userId: selfUser?.id,
    }, { enabled: !!selfUser?.id })

    const defaultOrganization = useMemo(() => (
        data?.organizationsCollection?.edges[0]?.node
    ), [data?.organizationsCollection?.edges])

    if (!defaultOrganization) {
        return <Loading />
    }

    return (
        <Navigate to={defaultOrganization.id} />
    )
}

export default RootRedirect;