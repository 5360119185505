import { library } from '@fortawesome/fontawesome-svg-core'
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons/faIdBadge'
import { faRocketLaunch } from '@fortawesome/pro-regular-svg-icons/faRocketLaunch'
import { faCrown } from '@fortawesome/pro-regular-svg-icons/faCrown'
import { faGift } from '@fortawesome/pro-regular-svg-icons/faGift'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons/faLightbulb'
import { faQuestion } from '@fortawesome/pro-regular-svg-icons/faQuestion'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faComments } from '@fortawesome/pro-regular-svg-icons/faComments'

library.add(
    faIdBadge,
    faRocketLaunch,
    faCrown,
    faGift,
    faLock,
    faBuilding,
    faExclamationTriangle,
    faLightbulb,
    faQuestion,
    faEllipsisH,
    faLink,
    faComments,
)