import { Portal } from '@blueprintjs/core/lib/cjs/components/portal/portal'
import React, { FC, useCallback } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CloseButton, DialogBackground, DialogContainer, DialogPortalInnerContainer } from './dialog.styled'
import { useRegisterDialog, useShowDialogBackground, useRenderDialog, useWindowHeight, useShowDialogContainer, usePortalClassName } from './dialog.hooks'
import { ClassNames } from '@emotion/react'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

export type DialogProps = {
	open: boolean
	hideClose?: boolean
	onClose?: () => void
	children?: React.ReactNode
	columns?: 'one' | 'two'
}

export const Dialog: FC<DialogProps> = ({
	open: isOpen,
	hideClose,
	onClose,
	children,
	columns = 'two',
}) => {

	// custom hooks
	const { dialogId } = useRegisterDialog(isOpen)
	const { renderDialog } = useRenderDialog(isOpen)
	const { showDialogBackground } = useShowDialogBackground(dialogId, isOpen)
	const { showDialogContainer } = useShowDialogContainer(dialogId, isOpen)
	const { windowHeight } = useWindowHeight()
	const { portalStyles } = usePortalClassName(isOpen)

	// memoized values and
	const allowClose = !!onClose;

	// event handlers
	const handleDialogClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
	}, [])

	if (!renderDialog) {
		return null
	}

	return (
		<ClassNames>
			{({ css }) => (
				/* @ts-ignore */
				<Portal
					container={document.body}
					className={css(portalStyles)}
				>
					<DialogPortalInnerContainer onClick={onClose}>
						<DialogContainer
							show={showDialogContainer}
							windowHeight={windowHeight}
							onClick={handleDialogClick}
							columns={columns}
						>
							{children}
							<CloseButton
								show={!hideClose}
								onClick={onClose}
							>
								<FontAwesomeIcon icon={faTimes} />
							</CloseButton>
						</DialogContainer>
					</DialogPortalInnerContainer>
					<DialogBackground
						show={showDialogBackground}
						allowClose={allowClose}
						onClick={onClose}
					/>
				</Portal>
			)}
		</ClassNames>
	)
}