import { BillingPortalSessionFlowType, CreateBillingPortalSessionResult, GetSubscriptionDetailsResult } from "constellation-sdk";
import { api } from "../../../../common/api/api";

class OrganizationProviderService {
  public async getSubscriptionDetails(
    organizationId: string
  ): Promise<GetSubscriptionDetailsResult> {
    let result: GetSubscriptionDetailsResult | undefined = undefined;

    try {
      result = await api.organizations.getSubscriptionDetails(organizationId);
    } catch {}

    if (!result) {
      result = new GetSubscriptionDetailsResult();
      result.success = false;
      result.errorCode = "unknown";
    }

    return result;
  }

  public async createBillingPortalSession(organizationId: string, flowType: BillingPortalSessionFlowType): Promise<CreateBillingPortalSessionResult> {
    let result: CreateBillingPortalSessionResult | undefined = undefined;

    try {
      result = await api.organizations.createBillingPortalSession(organizationId, { flowType })
    } catch {}

    if (!result) {
      result = new CreateBillingPortalSessionResult();
      result.success = false;
      result.errorCode = 'unknown';
    }

    return result;
  }
}

export const service = new OrganizationProviderService();
