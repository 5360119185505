import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { OrganizationMembersView } from "../../../../../../../../../../modules/preferences/views/organization-members-view/OrganizationMembersView";

export const OrganizationMembersRoute: FC = () => {

    // router props
    const { organization_id } = useParams() as { organization_id: string }

    return (
        <OrganizationMembersView
            organizationId={organization_id}
        >
            <Outlet />
        </OrganizationMembersView>
    )
}