
import styled from "@emotion/styled";
import { Padding } from "../../../../common/constants/constants";
import { TABLET_BREAKPOINT } from "../../../common/constants/breakpoints/breakpoints";

export const PreferencesShellContainer = styled.div<{
    isSidebarCollapsed: boolean;
}>(({
    isSidebarCollapsed,
}) => ({
    /* layout */
    display: 'grid',
    gridTemplateColumns: `${isSidebarCollapsed ? 'max-content' : '220px'} 1fr`,

    /* size */
    height: '100vh',
    width: '100vw',

    /* overflow */
    overflow: 'hidden',

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
    },
}))

export const MainContentContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    /* padding */
    paddingTop: Padding.default,
})