import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { TabBarLink } from "./tab-bar.types"
import useWindowDimensions from "../../../common/hooks/use-window-dimensions"
import { useUnsafeRouterContext } from "../../../common/hooks/use-unsafe-router-context/use-unsafe-router-context"
import { routerIsLinkActive } from "../../../common/utils/router-is-link-active/router-is-link-active"

export const useFilteredLinks = (links: TabBarLink[]) => {
    const { width: windowWidth } = useWindowDimensions()

    return useMemo<TabBarLink[]>(() => {
		let newFilteredLinks = links.filter(link => {
			if (link.responsiveDisplay?.max && windowWidth > link.responsiveDisplay.max) return false
			if (link.responsiveDisplay?.min && windowWidth < link.responsiveDisplay.min) return false
			return true
		})
		return newFilteredLinks
	}, [links, windowWidth])
}

export const useActiveLink = (links: TabBarLink[]): TabBarLink | null => {
    // router hooks
    const unsafeRouterContext = useUnsafeRouterContext()

    return useMemo(() => (
        links.find(link => routerIsLinkActive(link.to, unsafeRouterContext)) ?? null
    ), [links, unsafeRouterContext])
}

export const useTooltipOpenDelayed = (delay: number): [boolean, Dispatch<SetStateAction<boolean>>] => {

    // state
    const [desiredOpen, setDesiredOpen] = useState<boolean>(false)
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

    // side effects
    useEffect(() => {
        if (!desiredOpen) {
            setTooltipOpen(false)
            return;
        }

        const timeout = setTimeout(() => {
            setTooltipOpen(true)
        }, delay);

        return () => {
            clearTimeout(timeout)
        }
    }, [delay, desiredOpen])

    return [
        tooltipOpen,
        setDesiredOpen,
    ]
}