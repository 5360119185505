/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

export type AddGroupMemberForm_GroupFragment = { addGroupMemberForm_group_groupMembersCollection: { edges: Array<{ node: { id: any, userId: any | null } }> } | null, organization: { id: any, addGroupMemberForm_group_organizationMembersCollection: { edges: Array<{ node: { user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null } }> } | null } | null };

export const AddGroupMemberForm_GroupFragmentDoc = `
    fragment AddGroupMemberForm_Group on Groups {
  addGroupMemberForm_group_groupMembersCollection: groupMembersCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        userId
      }
    }
  }
  organization {
    id
    addGroupMemberForm_group_organizationMembersCollection: organizationMembersCollection(
      filter: {isDeleted: {eq: false}}
    ) {
      edges {
        node {
          user {
            id
            firstName
            lastName
            emailAddress
          }
        }
      }
    }
  }
}
    `;