/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

export type AddUserToOrganizationDialog_OrganizationFragment = { id: any, name: string, addUserToOrganizationDialog_organization_organizationMembersCollection: { edges: Array<{ node: { user: { emailAddress: string | null } | null } }> } | null, addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection: { edges: Array<{ node: { invitedUserEmailAddress: string | null } }> } | null, AddUserToOrganizationDialog_Organization_UserGroupsCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };

export const AddUserToOrganizationDialog_OrganizationFragmentDoc = `
    fragment AddUserToOrganizationDialog_Organization on Organizations {
  id
  name
  addUserToOrganizationDialog_organization_organizationMembersCollection: organizationMembersCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        user {
          emailAddress
        }
      }
    }
  }
  addUserToOrganizationDialog_organization_organizationMemberInvitationsCollection: organizationMemberInvitationsCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        invitedUserEmailAddress
      }
    }
  }
  AddUserToOrganizationDialog_Organization_UserGroupsCollection: userGroupsCollection(
    filter: {isDeleted: {eq: false}}
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;