import { FC, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../../../../../../../modules/common/loading/Loading";

const ConstellationDashboardView = lazy(() => import("../../../../../../../modules/constellations/views/constellation-view/subcomponents/constellation-dashboard-view/ConstellationDashboardView"))

export const ConstellationDashboardRoute: FC = () => {

    // router hooks
    const { constellation_id: constellationId } = useParams() as { constellation_id: string };

    return (
        <Suspense fallback={<Loading />}>
            <ConstellationDashboardView
                constellationId={constellationId}
            />
        </Suspense>
    )
}