import { blue } from "@ant-design/colors";
import styled from "@emotion/styled";
import { Colors } from "../../../../../../../../common/colors/colors";
import { BorderRadius, Padding, Gap, FontSize } from "../../../../../../../../common/constants/constants";

export const AdminToolGridItemContainer = styled.div<{
    outlineColor?: string
}>(({
    outlineColor,
}) => ({
    backgroundColor: Colors.cardBackground,
    borderRadius: BorderRadius.more,
    padding: Padding.default,
    display: 'grid',
    gridAutoFlow: 'column',
    cursor: 'pointer',
    position: 'relative',
    border: '2px solid transparent',
    transition: 'border 100ms ease',
    gridTemplateColumns: '38px 1fr auto',
    alignItems: 'center',
    gap: Gap.default,

    '&:hover': {
        borderColor: outlineColor ?? blue.primary,
    },
}))

export const DetailsContainer = styled.div({
    display: 'grid',
    gridTemplateRows: 'auto auto'
})

export const AdminToolName = styled.h3({
    /* text */
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    /* size */
    maxWidth: '100%',

    /* overflow */
    overflow: 'hidden',
})

export const Descriptor = styled.span({
    fontStyle: 'italic',
    fontSize: FontSize.default,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
})