import { AddConstellationToGroupResult, RemoveConstellationFromGroupResult, UpdateGroupParentPayload, UpdateGroupParentResult } from "constellation-sdk";
import { api } from "../../../../common/api/api";

class GroupDragReceiverService {
    public async updateGroupParent(groupId: string, payload: UpdateGroupParentPayload): Promise<UpdateGroupParentResult> {
        let result: UpdateGroupParentResult | undefined = undefined;

        try {
            result = await api.groups.updateGroupParent(groupId, payload);
        } catch {}

        if (!result) {
            result = new UpdateGroupParentResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred';
        }

        return result;
    }

    public async addConstellationToGroup(groupId: string, constellationId: string): Promise<AddConstellationToGroupResult> {
        let result: AddConstellationToGroupResult | undefined = undefined;

        try {
            result = await api.groups.addConstellation(groupId, constellationId);
        } catch {}

        if (!result) {
            result = new AddConstellationToGroupResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred';
        }

        return result;
    }

    public async removeConstellationFromGroup(groupId: string, constellationId: string): Promise<RemoveConstellationFromGroupResult> {
        let result: RemoveConstellationFromGroupResult | undefined = undefined;

        try {
            result = await api.groups.removeConstellation(groupId, constellationId);
        } catch {}

        if (!result) {
            result = new RemoveConstellationFromGroupResult();
            result.success = false;
            result.errorCode = 'unknown';
            result.errorMessage = 'An unknown error occurred';
        }

        return result;
    }
}

export const service = new GroupDragReceiverService();