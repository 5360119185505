import { Content } from 'antd/lib/layout/layout'
import React, { FC, useContext, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { UserProfileView } from './subviews/user-profile-view/UserProfileView'
import { useUserProfilePreferencesView_UserQuery } from './UserProfilePreferencesView.generated'
import { SelfContext } from '../../../app/providers/self-provider/SelfProvider'
import { StyledUserProfileIcon } from '../../../common/icons/styled-icons/styled-user-profile-icon/StyledUserProfileIcon'
import { Loading } from '../../../common/loading/Loading'
import { ViewBar } from '../../../common/view-bar/view-bar'

type UserProfilePreferencesViewProps = {}

export const UserProfilePreferencesView: FC<UserProfilePreferencesViewProps> = () => {

	// styles
	const styles = useStyles()

	// context
	const { selfUser } = useContext(SelfContext)

	// data
	const { data } = useUserProfilePreferencesView_UserQuery({ userId: selfUser?.id }, { enabled: !!selfUser?.id })

	// data abstraction
	const user = useMemo(() => (
		data?.userProfilePreferencesView_user_usersCollection?.edges[0]?.node
	), [data?.userProfilePreferencesView_user_usersCollection?.edges])

	if (!user) {
		return (
			<Loading />
		)
	}

	return (
		<div className={styles.container}>
			<ViewBar
				icon={<StyledUserProfileIcon />}
				viewName='Personal Info'
			/>
			<Content>
				<UserProfileView
					user={user}
				/>
			</Content>
		</div>
	)
}

const useStyles = createUseStyles({
	container: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr',
		height: '100%',
	}
})