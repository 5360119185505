import { FC } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../common/api/constants";

export const RedirectorRoute: FC = () => {

    // router hooks
    const { alias } = useParams() as { alias: string }

    // redirect to the api
    window.location.replace(`${API_URL}/qr/${alias}`)

    return null
}