import { FC } from "react";
import { Outlet } from "react-router-dom";
import { OrganizationMembersAndTeamsView } from "../../../../../../../../modules/preferences/views/organization-members-and-teams-view/OrganizationMembersAndTeamsView";

export const OrganizationMembersAndTeamsRoute: FC = () => {
    return (
        <OrganizationMembersAndTeamsView>
            <Outlet />
        </OrganizationMembersAndTeamsView>
    )
}