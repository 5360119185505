import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import Button from 'antd/es/button'
import React, { FC, MouseEvent } from 'react'
import { OrganizationMemberContextMenu } from '../organization-member-context-menu/OrganizationMemberContextMenu'
import { OrganizationMemberGridItem_OrganizationMemberFragment } from './organization-member-grid-item.generated'
import { DescriptorContainer, DetailsContainer, Email, IconContainer, OrganizationMemberGridItemContainer } from './organization-member-grid-item.styled'

type OrganizationMemberGridItemProps = {
    organizationMember: OrganizationMemberGridItem_OrganizationMemberFragment
    isActive?: boolean
    onClick: (event: MouseEvent<HTMLDivElement>) => void
}

export const OrganizationMemberGridItem: FC<OrganizationMemberGridItemProps> = ({
    organizationMember,
    isActive = false,
    onClick,
}) => {

    // hooks
    const initials = `${organizationMember.user?.firstName?.[0]?.toUpperCase() ?? ''}${organizationMember.user?.lastName?.[0]?.toUpperCase() ?? ''}`

    return (
        <OrganizationMemberGridItemContainer isActive={isActive} onClick={onClick}>
            <IconContainer>
                {initials}
            </IconContainer>
            <DetailsContainer>
                <h3>
                    {organizationMember.user?.firstName ?? ''} {organizationMember.user?.lastName ?? ''}
                </h3>
                <DescriptorContainer>
                    <Email>
                        {organizationMember.user?.emailAddress}
                    </Email>
                </DescriptorContainer>
            </DetailsContainer>
            <OrganizationMemberContextMenu organizationMember={organizationMember}>
                <Button icon={<EllipsisOutlined style={{ fontSize: 20 }} />} />
            </OrganizationMemberContextMenu>
        </OrganizationMemberGridItemContainer>
    )
}
