import Tooltip from 'antd/es/tooltip'
import { ReactNode, forwardRef, useContext } from 'react'
import { Link, Params, UIMatch, useLocation, useMatch, useMatches, useParams } from 'react-router-dom'
import { AppShellContext } from '../../../../app-shell-context'
import { useIsMobile } from '../../../../../../../common/constants/breakpoints/breakpoints'
import { IconContainer, buildLinkStyles } from './nav-button.styled'
import { ClassNames } from '@emotion/react'
import { Padding } from '../../../../../../../../common/constants/constants'
import { NavSectionContext } from '../nav-section/nav-section.context'

type NavButtonProps = {
    to: string
    label: string
    icon: ReactNode
    overflowMenuButton?: ReactNode
    end?: boolean
    useAnchor?: boolean
    level?: number
    customMatch?: (context: { pathname: string, matches: UIMatch<unknown, unknown>[], params: Readonly<Params<string>>}) => void
}

export const NavButton = forwardRef<HTMLAnchorElement, NavButtonProps>(({
    to,
    label,
    icon,
    overflowMenuButton,
    end,
    useAnchor,
    level = 0,
    customMatch,
}, ref) => {

    // context
    const { sidebarCollapsed } = useContext(AppShellContext)
    const { hasLabel: sectionHasLabel } = useContext(NavSectionContext)

    // styles
    const isMobile = useIsMobile()

    // matches calculation
    const matchesTo = useMatch({ path: to, end })
    const location = useLocation()
    const matches = useMatches()
    const params = useParams()

    // constants
    const isActive = matchesTo || (customMatch && customMatch({ pathname: location.pathname, matches, params }))
    const showFullButtonContents = isMobile ? true : !sidebarCollapsed
    const buttonContents = showFullButtonContents ? (
        <>
            {icon && (
                <IconContainer>
                    {icon}
                </IconContainer>
            )}
            <div style={{ flex: 1 }}>
                {label}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {overflowMenuButton}
            </div>
        </>
    ) : icon;

    const wrapTooltip = (children: ReactNode) => {
        if (showFullButtonContents) return children;
        return (
            <Tooltip
                title={label}
                placement='right'
                open={showFullButtonContents ? false : undefined}
            >
                {children}
            </Tooltip>
        )
    }

    return (
        <ClassNames>
            {({ css }) => (
                wrapTooltip(
                    useAnchor ? (
                        <a
                            ref={ref}
                            href={to}
                            target="_blank"
                            rel="noreferrer"
                            className={(
                                css(buildLinkStyles({
                                    isActive: !!isActive,
                                    isCollapsed: !showFullButtonContents
                                }))
                            )}
                            style={{
                                paddingLeft: Padding.default + (level * 8) + (sectionHasLabel ? 6 : 0),
                            }}
                        >
                            {buttonContents}
                        </a>
                    ) : (
                        <Link
                            ref={ref}
                            to={to}
                            className={
                                css(buildLinkStyles({
                                    isActive: !!isActive,
                                    isCollapsed: !showFullButtonContents
                                }))
                            }
                            style={{
                                paddingLeft: Padding.default + (level * 8) + (sectionHasLabel ? 6 : 0),
                            }}
                        >
                            {buttonContents}
                        </Link>
                    )
                )
            )}
        </ClassNames>
    )
})