import { FC } from "react";
import { InvitedToOrganizationNotificationActions } from "../../notification-actions/invited-to-organization-notification-actions/invited-to-organization-notification-actions";
import { NotificationActionCoordinator_NotificationFragment } from "./NotificationActionCoordinator.generated";

type NotificationActionCoordinatorProps = {
    notification: NotificationActionCoordinator_NotificationFragment
}

export const NotificationActionCoordinator: FC<NotificationActionCoordinatorProps> = ({
    notification,
}) => {
    switch (notification.notificationType?.code) {
        case 'organization_member_invitation':
            return <InvitedToOrganizationNotificationActions notification={notification} />
        default:
            return null
    }
}