import React, { FC, Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../modules/common/loading/Loading'

const GroupView = lazy(() => import('../../../modules/groups/views/group-view/GroupView'))

export const GroupRoute: FC = () => {

	// params
	const { group_id } = useParams() as { group_id: string }

	return (
		<Suspense fallback={<Loading />}>
			<GroupView groupId={group_id} />
		</Suspense>
	)
}