import { useMatches } from "react-router-dom";
import { RouterMatch } from "../../../../../../../../../common/route-name/route-name.types";

export const useCurrentMatch = (): RouterMatch | undefined => {
    // router hooks
    const matches = useMatches() as RouterMatch[]

    // constants
    const filteredMatches = matches.filter(match => !!match.handle?.RouteNameComponent)
    const currentMatch = filteredMatches.length > 0 ? filteredMatches[filteredMatches.length - 1] : undefined;

    return currentMatch;
}