import { useQueryClient } from '@tanstack/react-query'
import Button from 'antd/es/button'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import message from 'antd/es/message'
import React, { FC, useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Gap } from '../../../common/constants/constants'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { service } from './update-user-profile-form.service'
import { UpdateUserProfileForm_UserFragment } from './update-user-profile-form.generated'

type UpdateUserProfileFormProps = {
	user: UpdateUserProfileForm_UserFragment
}

export const UpdateUserProfileForm: FC<UpdateUserProfileFormProps> = ({
	user,
}) => {

	// styles
	const styles = useStyles()

	// context
	const { logAppEvent } = useContext(AppEventContext)

	// form state
	const { control, formState: { isValid: formValid, isDirty: formDirty }, getValues, handleSubmit } = useForm({
		defaultValues: {
			firstName: user.firstName ?? '',
			lastName: user.lastName ?? '',
		}
	})

	// query client
	const queryClient = useQueryClient()

	// event handlers
	const handleFormSubmit = useCallback(async () => {
		/* extract form values */
		const { firstName, lastName } = getValues()

		/* update the user */
		const result = await service.updateUserProfile({
			firstName,
			lastName,
		})

		/* handle any errors that occurred */
		if (!result.success || !result.user) {
			message.error('Something went wrong. Please try again.')
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ userId: result.user.id },
			])
		)

		/* log an app event */
		await logAppEvent('edited_user_profile', {
			userId: user.id,
		})

		/* inform the user that the request succeeded */
		message.success(`Successfully updated user profile`)
	}, [getValues, queryClient, logAppEvent, user.id])

	return (
		<Form onSubmitCapture={handleSubmit(handleFormSubmit)} layout="vertical">
			<div className={styles.formContainer}>
				<div className={styles.formBody}>
					<Form.Item label="First Name">
						<Controller
							name="firstName"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									placeholder='First name'
									{...field}
								/>
							)}
						/>
					</Form.Item>
					<Form.Item label="Last Name">
						<Controller
							name="lastName"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									placeholder='Last name'
									{...field}
								/>
							)}
						/>
					</Form.Item>
				</div>
				<div className={styles.formFooter}>
					<Button type="primary" disabled={!formValid || !formDirty} onClick={handleSubmit(handleFormSubmit)}>
						Save Changes
					</Button>
				</div>
			</div>
		</Form>
	)
}

const useStyles = createUseStyles({
	updateUserProfileForm: {
		display: 'grid'
	},
	formContainer: {
		display: 'grid',
		gap: Gap.most,
	},
	formBody: {
		display: 'grid',
	},
	formFooter: {
		display: 'grid',
		justifyContent: 'end',
		gap: Gap.default,
		gridAutoFlow: 'column',
	},
})