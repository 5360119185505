import { MutableRefObject, useEffect, useRef } from "react"

export const useShiftKeyRef = (): MutableRefObject<boolean> => {

    // ref
    const shiftKeyRef = useRef<boolean>(false)

    // side effects
    useEffect(() => {
        const onDocumentKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey) {
                shiftKeyRef.current = true
            };
        }

        const onDocumentKeyUp = (event: KeyboardEvent)  => {
            if (!event.shiftKey) {
                shiftKeyRef.current = false
            }
        }

        document.addEventListener('keydown', onDocumentKeyDown)
        document.addEventListener('keyup', onDocumentKeyUp)

        return () => {
            document.removeEventListener('keydown', onDocumentKeyDown)
            document.removeEventListener('keyup', onDocumentKeyUp)
        }
    }, [])

    return shiftKeyRef
}