import { FC, useCallback } from 'react'
import { TabBarLink } from './tab-bar.types'
import { TabBarButton } from './subcomponents/tab-bar-button/tab-bar-button'
import { useActiveLink, useFilteredLinks, useTooltipOpenDelayed } from './tab-bar.hooks'
import { useIsMobile } from '../constants/breakpoints/breakpoints'
import { TabBarContainer, TabBarContainerInner } from './tab-bar.styled'
import { TabBarSelector } from './subcomponents/tab-bar-selector/tab-bar-selector'

type TabBarProps = {
	links: TabBarLink[]
}

export const TabBar: FC<TabBarProps> = ({
	links,
}) => {

    // custom hooks
	const filteredLinks = useFilteredLinks(links)
    const [showTips, setShowTips] = useTooltipOpenDelayed(400)
	const activeLink = useActiveLink(links)

	// responsive hooks
	const isMobile = useIsMobile()

	// event handlers
	const handleMouseEnter = useCallback(() => {
		setShowTips(true)
	}, [setShowTips])

	const handleMouseLeave = useCallback(() => {
		setShowTips(false)
	}, [setShowTips])

	if (isMobile) {
		return (
			<TabBarSelector
				filteredLinks={filteredLinks}
			/>
		)
	}

	return (
		<TabBarContainer>
			<TabBarContainerInner
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{filteredLinks.map((link, index) => (
					<TabBarButton
						key={`${link.label}_${link.to}_${index}`}
						link={link}
						showTips={showTips}
					/>
				))}
			</TabBarContainerInner>
			{activeLink?.actions}
		</TabBarContainer>
	)
}