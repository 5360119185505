import { useQueryClient } from '@tanstack/react-query'
import Button from 'antd/es/button'
import Form from 'antd/es/form'
import message from 'antd/es/message'
import Spin from 'antd/es/spin'
import React, { FC, useCallback, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { Gap } from '../../../common/constants/constants'
import { queryInvalidationHelper } from '../../../common/react-query/query-invalidation.helper'
import { AppEventContext } from '../../app/providers/app-event-provider/AppEventProvider'
import { service } from './change-primary-email-form.service'
import { ChangePrimaryEmailForm_UserFragment } from './change-primary-email-form.generated'
import { Input } from '../../common/forms/input/input'

type ChangePrimaryEmailFormProps = {
	user: ChangePrimaryEmailForm_UserFragment
}

export const ChangePrimaryEmailForm: FC<ChangePrimaryEmailFormProps> = ({
	user,
}) => {

	// styles
	const styles = useStyles()

	// form state
	const { control, formState: { isValid: formValid, isDirty: formDirty }, getValues, handleSubmit, reset } = useForm({
		defaultValues: {
			email: '',
		}, mode: 'all'
	})
	const [loading, setLoading] = useState<boolean>(false)
	
	// context
	const { logAppEvent } = useContext(AppEventContext)

	// query client
	const queryClient = useQueryClient()

	// event handlers
	const handleFormSubmit = useCallback(async () => {
		/* start the loading state */
		setLoading(true)

		/* extract form values */
		const { email } = getValues()

		/* update the user */
		const result = await service.updateSelfUser({
			emailAddress: email,
		})

		/* handle any errors that occurred */
		if (!result.success || !result.user) {
			message.error('Something went wrong. Please try again.')
			setLoading(false)
			return;
		}

		/* invalidate relevant queries */
		queryClient.invalidateQueries(
			queryInvalidationHelper([
				{ userId: result.user.id },
			])
		)

		/* log an app event */
		await logAppEvent('changed_email_address', {
			userId: user.id,
		})

		/* inform the user that the request succeeded */
		message.success(`Successfully updated email address`)

		/* reset form values */
		reset({
			email: '',
		})
	}, [getValues, logAppEvent, queryClient, reset, user.id])

	const handleSaveButtonClick = useCallback(() => {
		handleSubmit(handleFormSubmit)()
	}, [handleSubmit, handleFormSubmit])

	return (
		<div className={styles.changePrimaryEmailForm}>
			<Spin spinning={loading}>
				<Form className={styles.form} layout='vertical' onSubmitCapture={handleSubmit(handleFormSubmit)}>
					<Form.Item label="New Email Address">
						<Controller
							control={control}
							name="email"
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									placeholder={`${user.firstName?.toLowerCase()}@example.com`}
									autoComplete='email'
									{...field}
								/>
							)}
						/>
					</Form.Item>
				</Form>
				<div className={styles.formFooter}>
					<Button type='primary' disabled={!formValid || !formDirty} onClick={handleSaveButtonClick}>
						Change Email Address
					</Button>
				</div>
			</Spin>
		</div>
	)
}

const useStyles = createUseStyles({
	changePrimaryEmailForm: {
		display: 'grid'
	},
	form: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: Gap.default,
	},
	formFooter: {
		display: 'grid',
		justifyContent: 'end',
	},
	emailVerificationFooter: {
		display: 'grid',
		gap: Gap.default,
	},
})