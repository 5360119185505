import Menu, { type MenuProps } from 'antd/es/menu'
import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { BorderRadius, FontSize, Padding } from '../../../common/constants/constants'

type OverflowMenuProps = {} & MenuProps

export const OverflowMenu: FC<OverflowMenuProps> = ({
	children,
	...menuProps
}) => {

	// hooks
	const styles = useStyles()

	return (
        <div className={styles.overflowMenu} onClick={ev => ev.stopPropagation()}>
            <Menu {...menuProps} selectable={false} theme="light" activeKey=''>
                {children}
            </Menu>
        </div>
	)
}

const useStyles = createUseStyles({
	overflowMenu: {
        backgroundColor: 'white',
        color: 'rgb(40, 40, 40)',
        borderRadius: BorderRadius.less,
        paddingTop: Padding.least,
        paddingBottom: Padding.least,
        //minWidth: '140px !important',

		'& .ant-dropdown-menu': {
			backgroundColor: 'transparent',	
			color: '#323745',
			boxShadow: 'none',
			paddingTop: 0,
			paddingBottom: 0,

			'& .ant-dropdown-menu-item': {
				color: 'inherit',
				paddingLeft: Padding.default,
				paddingRight: 24, // not using a standard value because of layout of antd
				marginLeft: Padding.least,
				marginRight: Padding.least,
				paddingTop: 0,
				paddingBottom: 0,
				borderRadius: BorderRadius.least,
				height: 36,

				'&:hover': {
					backgroundColor: '#e3e6ec !important',
				},

				'&.ant-dropdown-menu-item-danger': {
					'&:hover': {
						background: '#ffc7d2 !important',
						color: '#e0475f !important',
					},
				},
				'& .ant-dropdown-menu-title-content': {
					fontSize: FontSize.bigger,
					marginLeft: Padding.less,
				},
				'& .ant-dropdown-menu-item-icon': {
					marginRight: 0,
				},
			},

			'& .ant-dropdown-menu-submenu': {

				'& .ant-dropdown-menu-submenu-title': {
					color: 'inherit',
					borderRadius: BorderRadius.least,
					display: 'grid',
					alignItems: 'center',
					gridAutoFlow: 'column',
					gridAutoColumns: 'max-content',
					height: 36,
					paddingLeft: Padding.default,
					paddingRight: 36, // not using a standard value because of layout of antd
					marginLeft: Padding.least,
					marginRight: Padding.least,
					paddingTop: 0,
					paddingBottom: 0,

					'&:hover': {
						backgroundColor: '#e3e6ec !important',
					},

					'& .ant-dropdown-menu-submenu-expand-icon': {
						right: 18,

						'& svg': {
							fill: 'rgb(40, 40, 40)',
						},
					}
				}
			},
		},

		'& .ant-menu-item': {
			paddingLeft: Padding.default,
			paddingRight: 24, // not using a standard value because of layout of antd
			marginLeft: Padding.least,
			marginRight: Padding.least,
			paddingTop: 0,
			paddingBottom: 0,
			borderRadius: BorderRadius.least,
			backgroundColor: 'transparent',	

			display: 'grid',
			gridAutoFlow: 'column',
			gridTemplateColumns: '20px 1fr',
			alignItems: 'center',

			'& .ant-menu-title-content': {
				fontSize: FontSize.bigger,
				marginLeft: Padding.less,
			}
		},

		'& .ant-menu.ant-menu-root': {
			margin: 0,
			marginLeft: 0,
			marginRight: 0,
		},

		'& .ant-dropdown-menu-submenu': {
			paddingTop: 0,
			paddingBottom: 0,
		}
	}
})