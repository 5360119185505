import { grey, purple } from "@ant-design/colors"
import styled from "@emotion/styled"
import { BorderRadius } from "../../../../common/constants/constants"
import { opacify } from "../../../../common/utils/opacify"

export const ClearGroupParentDragReceiverContainer = styled.div({
    // position
    position: 'relative',
})

export const HoverIndicator = styled.div<{
    isOver: boolean,
    isOverNoOp?: boolean,
}>(({
    isOver,
    isOverNoOp,
}) => ({
    // position
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,

    // border
    border: `2px solid ${(
        isOver ? purple.primary
        : isOverNoOp ? grey.primary
        : 'transparent'
    )}`,
    borderRadius: BorderRadius.less,

    // background
    backgroundColor:
        isOver ? opacify(purple.primary!, 0.2)
        : isOverNoOp ? opacify(grey.primary!, 0.2)
        : 'transparent',

    // interaction
    pointerEvents: 'none',
}))