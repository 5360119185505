import { FC } from "react";
import { MobileLogoImage } from "./mobile-logo.styled";
import { Link } from "react-router-dom";

export const MobileLogo: FC = () => {
    return (
        <Link to="/">
            <MobileLogoImage
                src="/images/logo/cqr23_icons_Dark BG.svg"
            />
        </Link>
    )
}