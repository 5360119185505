import { FC, useMemo } from "react";
import { ActionsSection, WebsiteDemoWidgetContainer } from "./website-demo-widget-view.styled";
import { useWebsiteDemoWidgetViewQuery } from "./website-demo-widget-view.generated";
import { Loading } from "../../../common/loading/Loading";

export const WebsiteDemoWidgetView: FC = () => {

    // data
    const { data } = useWebsiteDemoWidgetViewQuery()

    // constants
    const constellations = useMemo(() => {
        return data?.constellationsCollection?.edges.map(edge => edge.node as NonNullable<typeof edge.node>)
    }, [data?.constellationsCollection?.edges])

    if (!constellations) return <Loading />

    return (
        <WebsiteDemoWidgetContainer>
            
            <ActionsSection>
                
            </ActionsSection>
        </WebsiteDemoWidgetContainer>
    )
}