"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupsModule = void 0;
const common_1 = require("../../../common");
class GroupsModule extends common_1.APIModule {
    constructor(client) {
        super(client, '/groups');
    }
    /* groups */
    createGroup(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([]);
            const response = yield this.client.post(endpoint, payload);
            return response.data;
        });
    }
    updateGroup(groupId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId]);
            const response = yield this.client.put(endpoint, payload);
            return response.data;
        });
    }
    deleteGroup(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId]);
            const response = yield this.client.delete(endpoint);
            return response.data;
        });
    }
    /* constellations */
    updateConstellationsInGroup(groupId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'constellations']);
            const response = yield this.client.put(endpoint, payload);
            return response.data;
        });
    }
    addConstellation(groupId, constellationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                groupId,
                'constellations',
                constellationId,
            ]);
            const response = yield this.client.post(endpoint, {});
            return response.data;
        });
    }
    removeConstellation(groupId, constellationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([
                groupId,
                'constellations',
                constellationId,
            ]);
            const response = yield this.client.delete(endpoint);
            return response.data;
        });
    }
    /* group members */
    addGroupMember(groupId, userId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'members', userId]);
            const response = yield this.client.post(endpoint, payload);
            return response.data;
        });
    }
    removeGroupMember(groupId, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'members', userId]);
            const response = yield this.client.delete(endpoint);
            return response.data;
        });
    }
    /* group parent */
    updateGroupParent(groupId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'parent']);
            const response = yield this.client.put(endpoint, payload);
            return response.data;
        });
    }
    removeGroupParent(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'parent']);
            const response = yield this.client.delete(endpoint);
            return response.data;
        });
    }
    /* archive */
    archiveGroup(groupId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'archive']);
            const response = yield this.client.put(endpoint, { params: payload });
            return response.data;
        });
    }
    unarchiveGroup(groupId, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = this.buildEndpoint([groupId, 'unarchive']);
            const response = yield this.client.put(endpoint, { params: payload });
            return response.data;
        });
    }
}
exports.GroupsModule = GroupsModule;
