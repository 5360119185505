/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { OrganizationMemberContextMenu_OrganizationMemberFragmentDoc } from '../organization-member-context-menu/OrganizationMemberContextMenu.generated';
export type OrganizationMemberGridItem_OrganizationMemberFragment = { id: any, userId: any | null, user: { firstName: string | null, lastName: string | null, emailAddress: string | null } | null };

export const OrganizationMemberGridItem_OrganizationMemberFragmentDoc = `
    fragment OrganizationMemberGridItem_OrganizationMember on OrganizationMembers {
  user {
    firstName
    lastName
    emailAddress
  }
  ...OrganizationMemberContextMenu_OrganizationMember
}
    ${OrganizationMemberContextMenu_OrganizationMemberFragmentDoc}`;