import { LogAppEventPayload } from "constellation-sdk/dist/client/modules/self/dtos/log-app-event.dto";
import { DateTime } from "luxon";
import { createContext, FC, useCallback, useContext } from "react";
import { AppEventType } from "../../../../common/database-types/types";
import { AppEventsInsertInput } from "../../../../common/graphql/generated/types";
import { PageViewContext } from "../page-view-provider/PageViewProvider";
import { TypesContext } from "../types-provider/TypesProvider";
import { service } from "./AppEventProvider.service";

type AppEventProviderProps = {
    children: React.ReactNode
}

export const AppEventProvider: FC<AppEventProviderProps> = ({ children }) => {

    // context
    const { appEventTypes } = useContext(TypesContext)
    const { pageViewId } = useContext(PageViewContext)

    // event handlers
    const handleLogAppEvent = useCallback(async (appEventTypeCode: AppEventType, payload: Omit<LogAppEventPayload, 'appEventTypeId' | 'pageViewId' | 'timestamp'>) => {
        const appEventTypeId = appEventTypes?.find(appEventType => appEventType.code === appEventTypeCode)?.id

        await service.logAppEvent({
            appEventTypeId,
            pageViewId,
            timestamp: DateTime.now().toISO(),
            ...payload,
        })
    }, [appEventTypes, pageViewId])

    return (
        <AppEventContext.Provider value={{
            logAppEvent: handleLogAppEvent,
        }}>
            {children}
        </AppEventContext.Provider>
    )
}

export type AppEventContextType = {
    logAppEvent: (appEventTypeCode: AppEventType, payload: Omit<AppEventsInsertInput, 'appEventTypeId'>) => Promise<void>
}

export const AppEventContext = createContext<AppEventContextType>({
    logAppEvent: () => new Promise(resolve => resolve()),
})