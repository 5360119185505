/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../../common/graphql/generated/types';

import { UserAvatar_UserFragmentDoc } from '../../../../../users/user-avatar/UserAvatar.generated';
export type GroupUserView_GroupMemberFragment = { id: any, user: { id: any, firstName: string | null, lastName: string | null } | null };

export const GroupUserView_GroupMemberFragmentDoc = `
    fragment GroupUserView_GroupMember on GroupMembers {
  id
  user {
    id
    firstName
    lastName
    ...UserAvatar_User
  }
}
    ${UserAvatar_UserFragmentDoc}`;