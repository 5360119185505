import React, { ComponentType, FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../common/colors/colors'
import { BorderRadius, FontSize } from '../../../common/constants/constants'
import { AccordionHeader } from './subcomponents/accordion-header/AccordionHeader'
import { FormFieldsAccordionPanel } from './subcomponents/form-fields-accordion-panel/FormFieldsAccordionPanel'

export type FormFieldAccordionPanelConfig<PropsType extends {}> = {
	id: string
	label: string
	icon: ReactNode
	description: string | ReactNode
	component: ComponentType<PropsType>
	props: PropsType
	disabled?: boolean
}

type FormFieldsAccordionProps = {
	title?: string
	description?: string | ReactNode
	fieldPanels: FormFieldAccordionPanelConfig<any>[]
}

export const FormFieldsAccordion: FC<FormFieldsAccordionProps> = ({
	title,
	description,
	fieldPanels,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.formFieldsAccordion}>
			{title && (
				<AccordionHeader
					title={title}
					description={description}
				/>
			)}
			{fieldPanels.map(fieldPanelConfig => (
				<FormFieldsAccordionPanel key={fieldPanelConfig.id} fieldPanelConfig={fieldPanelConfig} />
			))}
		</div>
	)
}

const useStyles = createUseStyles({
	formFieldsAccordion: {
		display: 'grid',
		backgroundColor: Colors.cardBackground,
		borderRadius: BorderRadius.default,
		border: `0.5px solid ${Colors.cardBorder}`,
		overflow: 'hidden',
		fontSize: FontSize.default,

		'& input': {
			borderColor: Colors.cardBorder,
			'&:hover': {
				borderColor: Colors.text,
			},
			'&:active': {
				borderColor: Colors.text,
			},
		}
	}
})