import { blue, green, orange } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserAccountView_UserFragment } from './UserAccountView.generated'
import { FormFieldsAccordion } from '../../../../../common/form-fields-accordion/FormFieldsAccordion'
import { ChangePasswordForm } from '../../../../../users/change-password-form/ChangePasswordForm'
import { ChangePrimaryEmailForm } from '../../../../../users/change-primary-email-form/change-primary-email-form'
import { LogoutEverywhereForm } from '../../../../../users/logout-everywhere-form/LogoutEverywhereForm'
import { VerifyEmailForm } from '../../../../../users/verify-email-form/VerifyEmailForm'
import { PreferencesGeneralLayout } from '../../../../common/preferences-general-layout/PreferencesGeneralLayout'
import { PreferencesViewIcon } from '../../../../common/preferences-general-layout/subcomponents/preferences-view-icon/PreferencesViewIcon'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut'

type UserAccountViewProps = {
	user: UserAccountView_UserFragment
}

export const UserAccountView: FC<UserAccountViewProps> = ({
	user,
}) => {

	// constants
	const emailVerified = true // TODO: fix me

	return (
		<PreferencesGeneralLayout
			title='Sign In & Security'
			subtitle='Update the way you sign in to your Constellation products.'
			icon={(
				<PreferencesViewIcon color={blue.primary}>
					<FontAwesomeIcon icon={faKey} />
				</PreferencesViewIcon>
			)}
		>
			<FormFieldsAccordion
				title="Sign in info"
				description="We'll use this info to help make sure only you can sign in to your account."
				fieldPanels={[
					{
						id: 'primary-email',
						icon: <FontAwesomeIcon icon={faEnvelope} />,
						label: 'Primary Email Address',
						description: user.emailAddress,
						component: ChangePrimaryEmailForm,
						props: { user },
					},
					{
						id: 'email-verification',
						icon: <FontAwesomeIcon icon={faCheck} />,
						label: 'Email Verification',
						description: (
							<>
								<span>Your email address is <strong style={{ color: emailVerified ? green.primary : orange.primary }}>{emailVerified ? 'verified' : 'not verified'}</strong>.</span>
							</>
						),
						component: VerifyEmailForm,
						props: {},
					},
					{
						id: 'password',
						icon: <FontAwesomeIcon icon={faKey} />,
						label: 'Password',
						description: '•••••••••••',
						component: ChangePasswordForm,
						props: { user },
					},
					{
						id: 'logout-everywhere',
						icon: <FontAwesomeIcon icon={faSignOut} />,
						label: 'Master Sign Out',
						description: <>Sign out of all active sessions.</>,
						component: LogoutEverywhereForm,
						props: { user },
					},
				]}
			/>
		</PreferencesGeneralLayout>
	)
}