import Button from "antd/es/button";
import message from "antd/es/message";
import { FC, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../common/api/api";
import { FormItem } from "../../common/forms/form-item/form-item";
import { FormItemsContainer } from "../../common/forms/form-items-container/form-items-container";
import { Input } from "../../common/forms/input/input";
import { AuthenticationFormFooter } from "../common/authentication-form-footer/authentication-form-footer";
import { AuthenticationFormHeader } from "../common/authentication-form-header/authentication-form-header";
import { AuthenticationFormHint } from "../common/authentication-form-hint/authentication-form-hint";
import { AuthenticationForm } from "../common/authentication-form/authentication-form";
import { validate as validateEmail } from 'email-validator'
import { AuthenticationContext } from "../../app/providers/authentication-provider/AuthenticationProvider";

export const SignUp: FC = () => {

    // context
    const { capturedUrl } = useContext(AuthenticationContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { getValues, handleSubmit, register, formState } = useForm({
        defaultValues: {
            organizationName: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            password: '',
        }
    })

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        try {
            const { firstName, lastName, emailAddress, password, organizationName } = getValues();

            const result = await api.auth.signUp({
                emailAddress,
                password,
                firstName,
                lastName,
                organizationName,
            })

            // handle any errors that occurred
            if (!result.success) {
                message.error(result.authError?.message ?? `Something went wrong. Please try again later.`)
                setIsLoading(false)
                return
            }

            if (!!result.success) {
                navigate(capturedUrl ?? '/')
            }

            message.success('Welcome to Constellation!')
            setIsLoading(false)
        } catch (err) {
            message.error('Something went wrong. Please try again.')
            setIsLoading(false)
        }
    }, [capturedUrl, getValues, navigate])

    return (
        <AuthenticationForm onSubmit={handleSubmit(handleFormSubmit)}>
            <AuthenticationFormHeader
                title="Create an Account"
                subtitle="Sign up to get started"
            />
            <FormItemsContainer>
                <FormItem label="Organization Name">
                    <Input
                        {...register('organizationName', {
                            required: true,
                        })}
                        placeholder="Organization Name"
                        type="text"
                        autoComplete="organization"
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="First Name">
                    <Input
                        {...register('firstName', {
                            required: true,
                        })}
                        placeholder="First Name"
                        type="text"
                        autoComplete="given-name"
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="Last Name">
                    <Input
                        {...register('lastName', {
                            required: true,
                        })}
                        placeholder="Last Name"
                        type="text"
                        autoComplete="family-name"
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="Email Address">
                    <Input
                        {...register('emailAddress', {
                            required: true,
                            validate: validateEmail,
                        })}
                        placeholder="Email Address"
                        type="email"
                        autoComplete="email"
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="Password">
                    <Input
                        {...register('password', {
                            required: true,
                            min: 8,
                        })}
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        disabled={isLoading}
                    />
                </FormItem>
            </FormItemsContainer>
            <AuthenticationFormFooter>
                <Button
                    type="primary"
                    loading={isLoading}
                    disabled={!formState.isValid}
                    htmlType="submit"
                >
                    Create Account
                </Button>
                <AuthenticationFormHint>
                    Already have an account? <Link to="/sign-in">Click here to sign in</Link>
                </AuthenticationFormHint>
            </AuthenticationFormFooter>
        </AuthenticationForm>
    )
}