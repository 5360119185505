import { DateTime } from 'luxon'
import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 } from 'uuid'
import { AuthenticationContext } from '../authentication-provider/AuthenticationProvider'
import { service } from './PageViewProvider.service'

type PageViewProviderProps = {
	children: React.ReactNode
}

export const PageViewProvider: FC<PageViewProviderProps> = ({
	children,
}) => {

	// context
	const { session } = useContext(AuthenticationContext)
	const userId = session?.user?.id

	// router hooks
	const location = useLocation()
	const pathname = location.pathname

	// refs
	const debounceIdRef = useRef(v4())

	// state
	const [pageViewId, setPageViewId] = useState<string | null>(null)

	// imperative logic
	const logPageView = useCallback(async (debounceId: string, userId: string, pathname: string) => {
		try {
			const result = await service.logPageView({
				url: pathname,
				timestamp: DateTime.now().toISO(),
			})

			/* if this isn't the most recent request, return */
			if (
				debounceId !== debounceIdRef.current
				|| !result.success
				|| !result.pageView
			) return;

			/* store the new page view id in state */
			setPageViewId(result.pageView.id ?? null)
		} catch {
			// do nothing
		}
	}, [])

	// side effects
	useEffect(() => {
		if (!userId) return
		const newDebounceId = v4()
		debounceIdRef.current = newDebounceId
		logPageView(newDebounceId, userId, pathname)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, pathname])

	return (
		<PageViewContext.Provider value={{ pageViewId }}>
			{children}
		</PageViewContext.Provider>
	)
}

type PageViewContextType = {
	pageViewId: string | null
}

export const PageViewContext = createContext<PageViewContextType>({
	pageViewId: null,
})