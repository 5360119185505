/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type OrganizationRouteName_OrganizationQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['UUID'];
}>;


export type OrganizationRouteName_OrganizationQuery = { organizationsCollection: { edges: Array<{ node: { id: any, name: string } }> } | null };


export const OrganizationRouteName_OrganizationDocument = `
    query OrganizationRouteName_Organization($organizationId: UUID!) {
  organizationsCollection(filter: {id: {eq: $organizationId}}) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const useOrganizationRouteName_OrganizationQuery = <
      TData = OrganizationRouteName_OrganizationQuery,
      TError = unknown
    >(
      variables: OrganizationRouteName_OrganizationQueryVariables,
      options?: UseQueryOptions<OrganizationRouteName_OrganizationQuery, TError, TData>
    ) =>
    useQuery<OrganizationRouteName_OrganizationQuery, TError, TData>(
      ['OrganizationRouteName_Organization', variables],
      fetcher<OrganizationRouteName_OrganizationQuery, OrganizationRouteName_OrganizationQueryVariables>(OrganizationRouteName_OrganizationDocument, variables),
      options
    );

useOrganizationRouteName_OrganizationQuery.getKey = (variables: OrganizationRouteName_OrganizationQueryVariables) => ['OrganizationRouteName_Organization', variables];
;

export const useInfiniteOrganizationRouteName_OrganizationQuery = <
      TData = OrganizationRouteName_OrganizationQuery,
      TError = unknown
    >(
      pageParamKey: keyof OrganizationRouteName_OrganizationQueryVariables,
      variables: OrganizationRouteName_OrganizationQueryVariables,
      options?: UseInfiniteQueryOptions<OrganizationRouteName_OrganizationQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<OrganizationRouteName_OrganizationQuery, TError, TData>(
      ['OrganizationRouteName_Organization.infinite', variables],
      (metaData) => fetcher<OrganizationRouteName_OrganizationQuery, OrganizationRouteName_OrganizationQueryVariables>(OrganizationRouteName_OrganizationDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteOrganizationRouteName_OrganizationQuery.getKey = (variables: OrganizationRouteName_OrganizationQueryVariables) => ['OrganizationRouteName_Organization.infinite', variables];
;

useOrganizationRouteName_OrganizationQuery.fetcher = (variables: OrganizationRouteName_OrganizationQueryVariables, options?: RequestInit['headers']) => fetcher<OrganizationRouteName_OrganizationQuery, OrganizationRouteName_OrganizationQueryVariables>(OrganizationRouteName_OrganizationDocument, variables, options);