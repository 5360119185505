import styled from "@emotion/styled";

export const ContentContainer = styled.div({
    /* position */
    position: 'relative',

    /* after */
    ':after': {
        /* position */
        position: 'absolute',
        top: '-100vh !important',
        left: '0px !important',
        width: '100vw !important',
        height: '100vh !important',
        // zIndex: 4,

        /* content */
        content: "''",

        /* appearance */
        boxShadow: 'rgb(0 0 0 / 50%) 0px 5px 20px 0px !important',
        clipPath: 'inset(0px 0px -24px)',

        /* interaction */
        pointerEvents: 'none',
    }
})

export const ContentInnerContainer = styled.div<{
    maxHeight: number | undefined
}>(({
    maxHeight,
}) => ({
    /* overflow */
    overflowY: 'auto',

    /* padding */
    paddingLeft: 16,
    paddingRight: 16,

    /* size */
    width: '100%',
    height: '100%',
    maxHeight,
}))