import { FC, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons/faExternalLinkAlt";
import Button from "antd/es/button";
import Empty from "antd/es/empty";
import { OrganizationContext } from "../../../../../organizations/common/organization-provider/organization-provider";
import { BillingPortalSessionFlowType } from "constellation-sdk";
import { EmptyContainer, ButtonContainer } from "./payment-methods-view.styled";

type PaymentMethodsViewProps = {
    organizationId: string
}

export const PaymentMethodsView: FC<PaymentMethodsViewProps> = ({
    organizationId,
}) => {

	// context
	const { openCustomerBillingPortal } = useContext(OrganizationContext)

	// event handlers
	const handleOpenPortalClick = useCallback(() => {
		openCustomerBillingPortal(BillingPortalSessionFlowType.payment_method_update)
	}, [openCustomerBillingPortal])

    return (
		<EmptyContainer>
			<Empty
				description="You can manage your payment methods in the Customer Billing Portal"
			>
				<ButtonContainer>
					<Button
						type="primary"
						icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
						onClick={handleOpenPortalClick}
					>
						Open Customer Billing Portal
					</Button>
				</ButtonContainer>
			</Empty>
		</EmptyContainer>
    )
}