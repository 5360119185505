import styled from "@emotion/styled";
import { Gap } from "../../../../../../common/constants/constants";
import { TABLET_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";

export const HeaderContainer = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',
    gap: Gap.default,

    /* padding */
    padding: 16,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
        
        paddingTop: 0,
        paddingBottom: 0,
    }
})

export const ActionsContainer = styled.div({
    /* layout */
    display: 'flex',
    flexDirection: 'row',
    gap: Gap.default,

    /* breakpoints */
    [TABLET_BREAKPOINT]: {
        display: 'none',
    }
})