/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { GroupMemberContextMenu_GroupMemberFragmentDoc } from '../group-member-context-menu/GroupMemberContextMenu.generated';
export type GroupMemberGridItem_GroupMemberFragment = { id: any, groupId: any | null, userId: any | null, user: { id: any, firstName: string | null, lastName: string | null, emailAddress: string | null } | null };

export const GroupMemberGridItem_GroupMemberFragmentDoc = `
    fragment GroupMemberGridItem_GroupMember on GroupMembers {
  id
  user {
    id
    firstName
    lastName
    emailAddress
  }
  ...GroupMemberContextMenu_GroupMember
}
    ${GroupMemberContextMenu_GroupMemberFragmentDoc}`;