/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../common/graphql/generated/types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../../../../common/graphql/fetcher';
export type TypesProvider_TypesQueryVariables = Types.Exact<{
  novaTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
  appEventTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
  actionTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
  aliasTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
  groupMemberTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
  scheduleTypesCursor: Types.InputMaybe<Types.Scalars['Cursor']>;
}>;


export type TypesProvider_TypesQuery = { novaTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string, verb: string | null, embeddedLinkTemplate: string | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null, appEventTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null, actionTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string, icon: string, color: string, description: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null, aliasTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string, isUserEditable: boolean | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null, groupMemberTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null, scheduleTypesCollection: { edges: Array<{ node: { id: any, name: string, code: string, enabled: boolean | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null } } | null };

export type PaginationFragmentFragment = { hasNextPage: boolean, hasPreviousPage: boolean, startCursor: string | null, endCursor: string | null };

export const PaginationFragmentFragmentDoc = `
    fragment PaginationFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const TypesProvider_TypesDocument = `
    query TypesProvider_Types($novaTypesCursor: Cursor, $appEventTypesCursor: Cursor, $actionTypesCursor: Cursor, $aliasTypesCursor: Cursor, $groupMemberTypesCursor: Cursor, $scheduleTypesCursor: Cursor) {
  novaTypesCollection(filter: {isDeleted: {eq: false}}, after: $novaTypesCursor) {
    edges {
      node {
        id
        name
        code
        verb
        embeddedLinkTemplate
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
  appEventTypesCollection(
    filter: {isDeleted: {eq: false}}
    after: $appEventTypesCursor
  ) {
    edges {
      node {
        id
        name
        code
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
  actionTypesCollection(
    filter: {isDeleted: {eq: false}}
    after: $actionTypesCursor
  ) {
    edges {
      node {
        id
        name
        code
        icon
        color
        description
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
  aliasTypesCollection(filter: {isDeleted: {eq: false}}, after: $aliasTypesCursor) {
    edges {
      node {
        id
        name
        code
        isUserEditable
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
  groupMemberTypesCollection(
    filter: {isDeleted: {eq: false}}
    after: $groupMemberTypesCursor
  ) {
    edges {
      node {
        id
        name
        code
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
  scheduleTypesCollection(
    filter: {isDeleted: {eq: false}}
    after: $scheduleTypesCursor
  ) {
    edges {
      node {
        id
        name
        code
        enabled
      }
    }
    pageInfo {
      ...PaginationFragment
    }
  }
}
    ${PaginationFragmentFragmentDoc}`;
export const useTypesProvider_TypesQuery = <
      TData = TypesProvider_TypesQuery,
      TError = unknown
    >(
      variables?: TypesProvider_TypesQueryVariables,
      options?: UseQueryOptions<TypesProvider_TypesQuery, TError, TData>
    ) =>
    useQuery<TypesProvider_TypesQuery, TError, TData>(
      variables === undefined ? ['TypesProvider_Types'] : ['TypesProvider_Types', variables],
      fetcher<TypesProvider_TypesQuery, TypesProvider_TypesQueryVariables>(TypesProvider_TypesDocument, variables),
      options
    );

useTypesProvider_TypesQuery.getKey = (variables?: TypesProvider_TypesQueryVariables) => variables === undefined ? ['TypesProvider_Types'] : ['TypesProvider_Types', variables];
;

export const useInfiniteTypesProvider_TypesQuery = <
      TData = TypesProvider_TypesQuery,
      TError = unknown
    >(
      pageParamKey: keyof TypesProvider_TypesQueryVariables,
      variables?: TypesProvider_TypesQueryVariables,
      options?: UseInfiniteQueryOptions<TypesProvider_TypesQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<TypesProvider_TypesQuery, TError, TData>(
      variables === undefined ? ['TypesProvider_Types.infinite'] : ['TypesProvider_Types.infinite', variables],
      (metaData) => fetcher<TypesProvider_TypesQuery, TypesProvider_TypesQueryVariables>(TypesProvider_TypesDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};


useInfiniteTypesProvider_TypesQuery.getKey = (variables?: TypesProvider_TypesQueryVariables) => variables === undefined ? ['TypesProvider_Types.infinite'] : ['TypesProvider_Types.infinite', variables];
;

useTypesProvider_TypesQuery.fetcher = (variables?: TypesProvider_TypesQueryVariables, options?: RequestInit['headers']) => fetcher<TypesProvider_TypesQuery, TypesProvider_TypesQueryVariables>(TypesProvider_TypesDocument, variables, options);