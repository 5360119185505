import { FC, MouseEvent, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { supabase } from "../../../../../../common/supabase/supabase";

export const AppMenuActions: FC = () => {

    // styles
    const styles = useStyles()

    // event handlers
    const signOut = useCallback(async () => {
        await supabase.auth.signOut()
    }, [])

    return (
        <div className={styles.container}>
            <MenuItem destructive onClick={signOut}>
                Log Out
            </MenuItem>
        </div>
    )
}

type MenuItemProps = {
    destructive?: boolean
    onClick?: (event: MouseEvent<HTMLDivElement>) => void
    children: React.ReactNode
}

const MenuItem: FC<MenuItemProps> = ({
    children,
    destructive,
    onClick,
}) => {

    // hooks
    const styles = useStyles()

    return (
        <div className={[
            styles.menuItem,
            destructive && styles.destructive,
        ].join(' ')} onClick={onClick}>
            {children}
        </div>
    )
}

const useStyles = createUseStyles({
    container: {
        display: 'grid',
        padding: 8,
        gridAutoFlow: 'row',
    },
    menuItem: {
        display: 'grid',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 6,
        borderRadius: 4,
        '&:hover': {
            backgroundColor: 'rgb(227, 230, 236)',
        }
    },
    destructive: {
        '&:hover': {
            backgroundColor: 'rgb(255, 199, 210)',
            color: 'rgb(224, 71, 95)'
        }
    }
})