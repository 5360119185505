import { GetAppSettingByCodeResult } from "constellation-sdk";
import { api } from "../../../../../../../../common/api/api";

class RedashGridItemService {
    public async getRedashUrl(): Promise<GetAppSettingByCodeResult> {
        let result: GetAppSettingByCodeResult | undefined = undefined;

        try {
            result = await api.appSettings.getAppSettingByCode('redash_url')
        } catch {}

        if (!result) {
            result = new GetAppSettingByCodeResult()
            result.success = false
            result.errorCode = 'unknown'
        }

        return result;
    }
}

export const service = new RedashGridItemService();