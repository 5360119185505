import { ClassNames } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbStyles } from "./breadcrumb.styled";
import { RouterMatch } from "../route-name/route-name.types";

type BreadcrumbProps = {
    children?: React.ReactNode
    data: RouterMatch
}

export const Breadcrumb: FC<BreadcrumbProps> = ({
    data,
    children,
}) => {
    return (
        <ClassNames>
            {({ css }) => (
                <Link
                    to={data.pathname}
                    className={css(BreadcrumbStyles)}
                >
                    {children}
                </Link>
            )}
        </ClassNames>
    )
}