import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../../../../common/colors/colors'
import { Gap, Padding } from '../../../../../common/constants/constants'

type AccordionHeaderProps = {
	title: string
	description: string | ReactNode
}

export const AccordionHeader: FC<AccordionHeaderProps> = ({
	title,
	description,
}) => {

	// hooks
	const styles = useStyles()

	return (
		<div className={styles.accordionHeader}>
			<h1>{title}</h1>
			<span>{description}</span>
		</div>
	)
}

const useStyles = createUseStyles({
	accordionHeader: {
		display: 'grid',
		borderBottom: `0.5px solid ${Colors.cardBorder}`,
		gap: Gap.least,
		padding: Padding.most * 1.5,
		paddingBottom: Padding.most * 2,
	},
})