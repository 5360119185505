import styled from "@emotion/styled";

export const TabBarOptionContents = styled.div({
    /* layout */
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    gap: 12,

    /* size */
    height: '100%',
})

export const TabBarOptionLabel = styled.div({})

export const TabBarSelectorTip = styled.div({
    /* text */
    fontStyle: 'italic',
    fontWeight: 400,

    /* appearance */
    opacity: 0.5,

    /* size */
    maxWidth: 178,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})