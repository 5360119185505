import React, { FC, useMemo } from 'react'
import { useOrganizationSettingsView_OrganizationQuery } from './OrganizationSettingsView.generated'
import { Content } from 'antd/lib/layout/layout'
import { StyledEnterpriseIcon } from '../../../common/icons/styled-icons/styled-enterprise-icon/StyledEnterpriseIcon'
import { Loading } from '../../../common/loading/Loading'
import { ViewBar } from '../../../common/view-bar/view-bar'
import { ViewContainer } from '../../../common/view-container/ViewContainer'
import { PreferencesGeneralLayout } from '../../common/preferences-general-layout/PreferencesGeneralLayout'
import { PreferencesViewIcon } from '../../common/preferences-general-layout/subcomponents/preferences-view-icon/PreferencesViewIcon'
import { blue } from '@ant-design/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormFieldsAccordion } from '../../../common/form-fields-accordion/FormFieldsAccordion'
import { OrganizationNamePanel } from '../../panels/organization-name-panel/organization-name-panel'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'

type OrganizationSettingsViewProps = {
	organizationId: string
}

export const OrganizationSettingsView: FC<OrganizationSettingsViewProps> = ({
	organizationId,
}) => {

	// data
	const { data } = useOrganizationSettingsView_OrganizationQuery({ organizationId })

	// data abstraction
	const organization = useMemo(() => (
		data?.organizationsCollection?.edges[0]?.node
	), [data?.organizationsCollection?.edges])

	if (!organization) {
		return (
			<Loading />
		)
	}

	return (
		<ViewContainer>
			<ViewBar
				icon={<StyledEnterpriseIcon />}
				viewName='Organization Settings'
			/>
			<Content>
				<PreferencesGeneralLayout
					title="Organization Settings"
					subtitle="Manage information related to your organization"
					icon={(
						<PreferencesViewIcon color={blue.primary}>
							<FontAwesomeIcon icon={faBuilding} />
						</PreferencesViewIcon>
					)}
				>
					<FormFieldsAccordion
						title="Organization settings"
						description="Manage information related to your organization"
						fieldPanels={[
							{
								id: 'name',
								icon: <FontAwesomeIcon icon={faEnvelope} />,
								label: 'Organization Name',
								description: organization.name,
								component: OrganizationNamePanel,
								props: {
									organization,
								},
							},
						]}
					/>
			</PreferencesGeneralLayout>
			</Content>
		</ViewContainer>
	)
}