import styled from "@emotion/styled";

export const InputIconContainer = styled.div({
    // layout
    display: 'grid',
    alignContent: 'center',

    // padding
    paddingLeft: 12,

    // position
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,

    // text
    color: 'rgb(129, 139, 156)',
})