import { FC, Suspense, lazy } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Loading } from "../../modules/common/loading/Loading";

const AppShell = lazy(() => import("../../modules/app/components/app-shell/app-shell"))

export const AppRoute: FC = () => {

    // router hooks
    const {
        organization_id: organizationId,
    } = useParams() as {
        organization_id: string | undefined
    }

    return (
        <Suspense fallback={<Loading />}>
            <AppShell organizationId={organizationId}>
                <Outlet />
            </AppShell>
        </Suspense>
    )
}