import Button from "antd/es/button";
import message from "antd/es/message";
import { FC, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../common/api/api";
import { FormFieldLink } from "../../common/forms/form-field-link/form-field-link";
import { FormItem } from "../../common/forms/form-item/form-item";
import { FormItemsContainer } from "../../common/forms/form-items-container/form-items-container";
import { Input } from "../../common/forms/input/input";
import { AuthenticationFormFooter } from "../common/authentication-form-footer/authentication-form-footer";
import { AuthenticationFormHeader } from "../common/authentication-form-header/authentication-form-header";
import { AuthenticationFormHint } from "../common/authentication-form-hint/authentication-form-hint";
import { AuthenticationForm } from "../common/authentication-form/authentication-form";
import { AuthenticationContext } from "../../app/providers/authentication-provider/AuthenticationProvider";

export const SignIn: FC = () => {

    // context
    const { capturedUrl } = useContext(AuthenticationContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { getValues, handleSubmit, register, formState } = useForm({
        defaultValues: {
            emailAddress: '',
            password: '',
        }
    })

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        const { emailAddress, password } = getValues()

        const result = await api.auth.signIn({
            emailAddress,
            password,
        })

        if (!result.success) {
            message.error(result.authError?.message ?? `Something went wrong. Please try again.`)
            setIsLoading(false)
            return;
        }

        if (!!result.success) {
            if (capturedUrl?.startsWith('/reset-password')) { navigate('/') }
            navigate(capturedUrl ?? '/')
        }

        setIsLoading(false)
    }, [capturedUrl, getValues, navigate])

    return (
        <AuthenticationForm onSubmit={handleSubmit(handleFormSubmit)}>
            <AuthenticationFormHeader
                title="Welcome"
                subtitle="It's great to see you."
            />
            <FormItemsContainer>
                <FormItem label="Email Address">
                    <Input
                        {...register('emailAddress', { required: true })}
                        placeholder="Email Address"
                        type="email"
                        autoComplete="email"
                        disabled={isLoading}
                    />
                </FormItem>
                <FormItem label="Password">
                    <Input
                        {...register('password', { required: true })}
                        placeholder="Password"
                        type="password"
                        autoComplete="current-password"
                        disabled={isLoading}
                    />
                    <FormFieldLink to="/forgot-password">
                        Forgot Password?
                    </FormFieldLink>
                </FormItem>
                <AuthenticationFormFooter>
                    <Button
                        type="primary"
                        disabled={!formState.isValid}
                        htmlType="submit"
                        loading={isLoading}
                    >
                        Sign In
                    </Button>
                    <AuthenticationFormHint>
                        Don't have an account? <Link to="/sign-up">Sign up for free</Link>
                    </AuthenticationFormHint>
                </AuthenticationFormFooter>
            </FormItemsContainer>
        </AuthenticationForm>
    )
}