import { faSparkles } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from 'antd/es/button';
import { FC, useContext } from "react";
import { OrganizationContext } from "../../../../../../../../../organizations/common/organization-provider/organization-provider";

export const YouArePremiumButton: FC = () => {

    // context
    const { activeEntitlementCodes } = useContext(OrganizationContext)

    // constants
    if (!activeEntitlementCodes?.includes('qr_codes.manage')) return null;

    return (
        <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faSparkles} />}
            style={{ pointerEvents: 'none', opacity: 0.5 }}
        >
            You are Premium
        </Button>
    )
}