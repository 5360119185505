/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../../../common/graphql/generated/types';

export type RemoveGroupMemberMenuItem_GroupMemberFragment = { groupId: any | null, userId: any | null };

export const RemoveGroupMemberMenuItem_GroupMemberFragmentDoc = `
    fragment RemoveGroupMemberMenuItem_GroupMember on GroupMembers {
  groupId
  userId
}
    `;