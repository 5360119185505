/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../../common/graphql/generated/types';

import { NotificationCoordinator_NotificationFragmentDoc } from '../notification-coodinator/notification-coodinator.generated';
export type NotificationsMenu_NotificationFragment = { id: any, read: boolean | null, timestamp: any | null, organizationMemberInvitationId: any | null, notificationType: { id: any, name: string, code: string, icon: string | null, iconColor: string | null, objectName: string | null } | null, organizationMemberInvitation: { invitedToOrganization: { id: any, name: string } | null, invitedByUser: { id: any, firstName: string | null, lastName: string | null } | null, invitedUser: { id: any, firstName: string | null, lastName: string | null } | null } | null };

export const NotificationsMenu_NotificationFragmentDoc = `
    fragment NotificationsMenu_Notification on Notifications {
  id
  read
  ...NotificationCoordinator_Notification
}
    ${NotificationCoordinator_NotificationFragmentDoc}`;