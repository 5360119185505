import { blue } from '@ant-design/colors'
import React, { FC } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StyledIcon } from '../../styled-icon/StyledIcon'
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers'

type StyledUsersIconProps = {}

export const StyledUsersIcon: FC<StyledUsersIconProps> = () => {
	return (
		<StyledIcon
			color={blue[6]}
			icon={<FontAwesomeIcon icon={faUsers} />}
		/>
	)
}