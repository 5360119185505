import React, { FC, Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../../../modules/common/loading/Loading'

const OrganizationArchivedConstellationsView = lazy(() => import('../../../../../modules/organizations/views/organization-archived-constellations-view/organization-archived-constellations-view'))

export const OrganizationArchivedConstellationsRoute: FC = () => {

	// params
	const { organization_id } = useParams() as { organization_id: string }

	return (
		<Suspense fallback={<Loading />}>
			<OrganizationArchivedConstellationsView organizationId={organization_id} />
		</Suspense>
	)
}